//
//import React, { useState } from 'react';
//import { useNavigate } from 'react-router-dom';
//import InputBox from '../../components/InputBox';
//import PagenameSearchBox from '../../components/PagenameSearchBox';
//import axios from 'axios';
//
//const EditStaff = () => {
//    const navigate = useNavigate();
//
//    const handleBlog = () => {
//        navigate('/admin/ourStaff');
//    };
//
//    const [staffName, setStaffName] = useState('');
//    const [staffEmail, setStaffEmail] = useState('');
//    const [mobileNumber, setMobileNumber] = useState('');
//    const [role, setRole] = useState('');
//    const [password, setPassword] = useState('');
//    const [confirmPassword, setConfirmPassword] = useState('');
//    const [status, setStatus] = useState('active'); // Default status
//
//    const [permissions, setPermissions] = useState([
//        { title: 'Dashboard', read: false, write: false, create: false },
//        { title: 'Users', read: false, write: false, create: false },
//        { title: 'Our Staff', read: false, write: false, create: false },
//        { title: 'Invoice', read: false, write: false, create: false },
//        { title: 'Blogs', read: false, write: false, create: false },
//        { title: 'Help Desk', read: false, write: false, create: false },
//        { title: 'FAQs', read: false, write: false, create: false },
//        { title: 'Settings', read: false, write: false, create: false },
//    ]);
//
//    const handleCheckboxChange = (index, permission) => {
//        const updatedPermissions = [...permissions];
//        updatedPermissions[index][permission] = !updatedPermissions[index][permission];
//        setPermissions(updatedPermissions);
//    };
//
//    const handleSelectAll = (selectAll) => {
//        const updatedPermissions = permissions.map((item) => ({
//            ...item,
//            read: selectAll,
//            write: selectAll,
//            create: selectAll,
//        }));
//        setPermissions(updatedPermissions);
//    };
//
//const handleAddUser = async () => {
//    if (password !== confirmPassword) {
//        alert('Passwords do not match');
//        return;
//    }
//
//    // Construct the accessPermissions object as a single dictionary
//    const accessPermissions = [{
//    dashboard: getPermissionString(permissions.find(p => p.title === 'Dashboard')),
//    users: getPermissionString(permissions.find(p => p.title === 'Users')),
//    our_staff: getPermissionString(permissions.find(p => p.title === 'Our Staff')),
//    invoice: getPermissionString(permissions.find(p => p.title === 'Invoice')),
//    blogs: getPermissionString(permissions.find(p => p.title === 'Blogs')),
//    help_desk: getPermissionString(permissions.find(p => p.title === 'Help Desk')),
//    faqs: getPermissionString(permissions.find(p => p.title === 'FAQs')),
//    settings: getPermissionString(permissions.find(p => p.title === 'Settings')),
//}];
//
//
//
//// Helper function to build the permission string
//function getPermissionString(permission) {
//    const perms = [];
//    if (permission.read) perms.push('read');
//    if (permission.write) perms.push('write');
//    if (permission.create) perms.push('create');
//
//    if (perms.length === 0) return null;            // No permissions selected
//    if (perms.length === 3) return 'ALL';           // All permissions selected
//    return perms.join('+');                         // Combine permissions with '+'
//}
//const { staffId } = useParams(); // Get the blogId from the URL
//
//
//    const staffData = {
//        name: staffName,
//        email: staffEmail,
//        mobile_number: mobileNumber,
//        role,
//        password,
//        access_permissions: accessPermissions, // Send as a list of one dictionary
//        status: false,  // Ensure status is a boolean
//    };
//
//    try {
//        console.log(staffData);
//        const response = await axios.post('https://api2.eromantic.ai/eromantic_api/edit/${staffId}', staffData);
//        if (response.status === 201) {
//            alert('Staff member Updated successfully');
//            navigate('/admin/ourStaff');
//        }
//    } catch (error) {
//        console.error('There was an error adding the staff member:', error.response?.data || error.message);
//        alert(`Failed to add staff member: ${JSON.stringify(error.response?.data || error.message)}`);
//    }
//};
//
//
//    return (
//        <div className='bg-white px-7 pb-20 font-josefin-sans'>
//            <PagenameSearchBox title='Edit Staff Details' btnname='Back to staff list' btnfunction={handleBlog} />
//            <InputBox title='Staff Name' placeholderName='Full Name' value={staffName} onChange={(e) => setStaffName(e.target.value)} />
//            <InputBox title='Staff Email' placeholderName='Email' value={staffEmail} onChange={(e) => setStaffEmail(e.target.value)} />
//            <InputBox title='Mobile Number' placeholderName='Mobile Number' value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
//            <InputBox title='Role Name' placeholderName='Role' value={role} onChange={(e) => setRole(e.target.value)} />
//            <InputBox title='Password' placeholderName='Password' type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
//            <InputBox title='Confirm Password' placeholderName='Confirm Password' type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
//
//            <div className="relative overflow-x-auto sm:rounded-lg mt-10">
//                <table className="w-full text-left text-gray-500 border border-stone-300">
//                    <thead className="text-xl text-black bg-stone-300">
//                        <tr>
//                            <th scope="col" className="px-6 py-3">Access Permissions</th>
//                            <th scope="col" className="px-6 py-3">Read</th>
//                            <th scope="col" className="px-6 py-3">Write</th>
//                            <th scope="col" className="px-6 py-3">Create</th>
//                            <th scope="col" className="px-6 py-3">
//                                <div className="flex items-center gap-5 justify-center">
//                                    <input
//                                        id="checkbox-select-all"
//                                        type="checkbox"
//                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
//                                        onChange={(e) => handleSelectAll(e.target.checked)}
//                                    />
//                                    <label htmlFor="checkbox-select-all" className="sr-only">Select All</label>
//                                </div>
//                            </th>
//                        </tr>
//                    </thead>
//                    <tbody>
//                        {permissions.map((item, index) => (
//                            <tr key={index} className="bg-white border-b text-xl font-normal text-zinc-600 border-stone-300">
//                                <td className="px-6 py-4 whitespace-nowrap">{item.title}</td>
//                                <td className="px-6 py-4 whitespace-nowrap">
//                                    <input
//                                        type="checkbox"
//                                        checked={item.read}
//                                        onChange={() => handleCheckboxChange(index, 'read')}
//                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
//                                    />
//                                </td>
//                                <td className="px-6 py-4 whitespace-nowrap">
//                                    <input
//                                        type="checkbox"
//                                        checked={item.write}
//                                        onChange={() => handleCheckboxChange(index, 'write')}
//                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
//                                    />
//                                </td>
//                                <td className="px-6 py-4 whitespace-nowrap">
//                                    <input
//                                        type="checkbox"
//                                        checked={item.create}
//                                        onChange={() => handleCheckboxChange(index, 'create')}
//                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
//                                    />
//                                </td>
//                                <td className="px-6 py-4"></td>
//                            </tr>
//                        ))}
//                    </tbody>
//                </table>
//            </div>
//
//            <button
//                onClick={handleAddUser}
//                className='rounded-lg w-full mt-6 bg-red-800 hover:bg-red-700 font-semibold text-white text-lg p-4'>
//                Add User
//            </button>
//        </div>
//    );
//};
//
//export default EditStaff;


import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import InputBox from '../../components/InputBox';
import PagenameSearchBox from '../../components/PagenameSearchBox';
import axios from 'axios';

const EditStaff = () => {
    const navigate = useNavigate();
    const { staffId } = useParams(); // Get the staffId from the URL
    const handleBlog = () => {
        navigate('/admin/ourStaff');
    };

    // State variables
    const [staffName, setStaffName] = useState('');
    const [staffEmail, setStaffEmail] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [role, setRole] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [status, setStatus] = useState(''); // Default status
    const [permissions, setPermissions] = useState([
        { title: 'Dashboard', read: false, write: false, create: false },
        { title: 'Users', read: false, write: false, create: false },
        { title: 'Our Staff', read: false, write: false, create: false },
        { title: 'Invoice', read: false, write: false, create: false },
        { title: 'Blogs', read: false, write: false, create: false },
        { title: 'Help Desk', read: false, write: false, create: false },
        { title: 'FAQs', read: false, write: false, create: false },
        { title: 'Settings', read: false, write: false, create: false },
    ]);

    // Fetch staff details on component mount
    useEffect(() => {
            const fetchStaffDetails = async () => {
            try {
                const authToken = localStorage.getItem('authToken');
                const response = await axios.get(`https://api2.eromantic.ai/eromantic_api/staff_details/${staffId}/`, {
                    headers: {
                        'Authorization': `Token ${authToken}`
                    }

                });
                const staff = response.data;
                console.log(staff,'sssssssssss')

                setStaffName(staff.name);
                setStaffEmail(staff.email);
                setMobileNumber(staff.mobile_number);
                setRole(staff.role);
                setStatus(staff.status ? 1 : 0);
                // Assuming access_permissions is an object like the one constructed in handleAddUser
                const updatedPermissions = permissions.map((permission) => {
                    const permissionKey = permission.title.toLowerCase().replace(' ', '_');

                    // Retrieve the permission string from staff's access_permissions
                    const permissionString = staff.access_permissions[0][permissionKey];


                    // Initialize permission flags
                    let read = false, write = false, create = false;

                    // Check the permissionString and set the flags accordingly
                    if (permissionString === 'ALL') {
                        read = true;
                        write = true;
                        create = true;
                    } else if (permissionString) {
                        read = permissionString.includes('read');
                        write = permissionString.includes('write');
                        create = permissionString.includes('create');
                    }

                    return {
                        ...permission,
                        read,
                        write,
                        create,
                    };
                });

                setPermissions(updatedPermissions);
            } catch (error) {
                console.error('Failed to fetch staff details:', error.response?.data || error.message);
                alert(`Failed to fetch staff details: ${JSON.stringify(error.response?.data || error.message)}`);
            }
        };

        fetchStaffDetails();
    }, [staffId]);

    // Handle checkbox change
    const handleCheckboxChange = (index, permission) => {
        const updatedPermissions = [...permissions];
        updatedPermissions[index][permission] = !updatedPermissions[index][permission];
        setPermissions(updatedPermissions);
    };

    // Handle select all
    const handleSelectAll = (selectAll) => {
        const updatedPermissions = permissions.map((item) => ({
            ...item,
            read: selectAll,
            write: selectAll,
            create: selectAll,
        }));
        setPermissions(updatedPermissions);
    };

    // Helper function to build the permission string
    function getPermissionString(permission) {
        const perms = [];
        if (permission.read) perms.push('read');
        if (permission.write) perms.push('write');
        if (permission.create) perms.push('create');

        if (perms.length === 0) return null;            // No permissions selected
        if (perms.length === 3) return 'ALL';           // All permissions selected
        return perms.join('+');                         // Combine permissions with '+'
    }

    // Handle updating staff details
    const handleUpdateUser = async () => {
        if (password !== confirmPassword) {
            alert('Passwords do not match');
            return;
        }

        // Construct the accessPermissions object as a single dictionary
        const accessPermissions = [{
            dashboard: getPermissionString(permissions.find(p => p.title === 'Dashboard')),
            users: getPermissionString(permissions.find(p => p.title === 'Users')),
            our_staff: getPermissionString(permissions.find(p => p.title === 'Our Staff')),
            invoice: getPermissionString(permissions.find(p => p.title === 'Invoice')),
            blogs: getPermissionString(permissions.find(p => p.title === 'Blogs')),
            help_desk: getPermissionString(permissions.find(p => p.title === 'Help Desk')),
            faqs: getPermissionString(permissions.find(p => p.title === 'FAQs')),
            settings: getPermissionString(permissions.find(p => p.title === 'Settings')),
        }];

        const staffData = {
            name: staffName,
            email: staffEmail,
            mobile_number: mobileNumber,
            role,
            password,
            access_permissions: accessPermissions, // Send as a list of one dictionary
            status: Boolean(status),  // Ensure status is a boolean
        };

        try {
            console.log(staffData,'ssswww')
            const authToken = localStorage.getItem('authToken');
            const response = await fetch(`https://api2.eromantic.ai/eromantic_api/edit/${staffId}/`, {
            method: 'PUT',
            body: JSON.stringify(staffData),
            headers: {
                'Authorization': `Token ${authToken}`,
                'Content-Type': 'application/json',
            },
        });
            if (response.status === 200) {
                alert('Staff member updated successfully');
                navigate('/admin/ourStaff');
            }
        } catch (error) {
            console.error('There was an error updating the staff member:', error.response?.data || error.message);
        }
    };

    return (
        <div className='bg-white px-7 pb-20 font-josefin-sans'>
            <PagenameSearchBox title='Edit Staff Details' btnname='Back to staff list' btnfunction={handleBlog} />
            <InputBox title='Staff Name' placeholderName='Full Name' value={staffName} onChange={(e) => setStaffName(e.target.value)} />
            <InputBox title='Staff Email' placeholderName='Email' value={staffEmail} onChange={(e) => setStaffEmail(e.target.value)} />
            <InputBox title='Mobile Number' placeholderName='Mobile Number' value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
            <InputBox title='Role Name' placeholderName='Role' value={role} onChange={(e) => setRole(e.target.value)} />
            <InputBox title='Password' placeholderName='Password' type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            <InputBox title='Confirm Password' placeholderName='Confirm Password' type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />

            <div className="relative overflow-x-auto sm:rounded-lg mt-10">
                <table className="w-full text-left text-gray-500 border border-stone-300">
                    <thead className="text-xl text-black bg-stone-300">
                        <tr>
                            <th scope="col" className="px-6 py-3">Access Permissions</th>
                            <th scope="col" className="px-6 py-3">Read</th>
                            <th scope="col" className="px-6 py-3">Write</th>
                            <th scope="col" className="px-6 py-3">Create</th>
                            <th scope="col" className="px-6 py-3">
                                <div className="flex items-center gap-5 justify-center">
                                    <input
                                        id="checkbox-select-all"
                                        type="checkbox"
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                        onChange={(e) => handleSelectAll(e.target.checked)}
                                    />
                                    <label htmlFor="checkbox-select-all" className="sr-only">Select All</label>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {permissions.map((item, index) => (
                            <tr key={index} className="bg-white border-b text-xl font-normal text-zinc-600 border-stone-300">
                                <td className="px-6 py-4 whitespace-nowrap">{item.title}</td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <input
                                        type="checkbox"
                                        checked={item.read}
                                        onChange={() => handleCheckboxChange(index, 'read')}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                    />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <input
                                        type="checkbox"
                                        checked={item.write}
                                        onChange={() => handleCheckboxChange(index, 'write')}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                    />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <input
                                        type="checkbox"
                                        checked={item.create}
                                        onChange={() => handleCheckboxChange(index, 'create')}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                    />
                                </td>
                                <td className="px-6 py-4"></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <button
                onClick={handleUpdateUser}
                className='rounded-lg w-full mt-6 bg-red-800 hover:bg-red-700 font-semibold text-white text-lg p-4'>
                Update Staff
            </button>
        </div>
    );
};

export default EditStaff;
