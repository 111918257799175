//import React, { useState, useRef, useEffect } from 'react';
//import { IoSearchOutline, IoSettingsSharp } from 'react-icons/io5';
//import { NavLink, useNavigate } from 'react-router-dom';
//import { userHeader, userProfileHeader } from '../../assets';
//import { FaUserLarge } from 'react-icons/fa6';
//
//const Header = () => {
//    const [isPopupVisible, setIsPopupVisible] = useState(false);
//    const popupRef = useRef(null);
//
//    const navigate = useNavigate();
//
//    const handleLogout = () => {
//        navigate('/admin');
//    };
//
//    const handleClickOutside = (event) => {
//        if (popupRef.current && !popupRef.current.contains(event.target)) {
//            setIsPopupVisible(false);
//        }
//    };
//
//    useEffect(() => {
//        if (isPopupVisible) {
//            document.addEventListener('mousedown', handleClickOutside);
//        } else {
//            document.removeEventListener('mousedown', handleClickOutside);
//        }
//
//        return () => {
//            document.removeEventListener('mousedown', handleClickOutside);
//        };
//    }, [isPopupVisible]);
//
//    return (
//        <>
//            <div className='bg-white flex justify-between items-center pt-8 pb-3 md:ps-48 lg:ps-80 w-full font-josefin-sans'>
//                <div className='bg-white ms-7 text-lg px-2 py-1 border-2 border-zinc-300 rounded-lg flex justify-between items-center lg:w-[500px]'>
//                    <span className='flex items-center'>
//                        <IoSearchOutline color='#9CA3C1' size={23} className='me-3' />
//                        <input type="text" className='focus:outline-none md:w-40 lg:w-80' placeholder='Search your page...' />
//                    </span>
//                    <button className='rounded-tr-lg rounded-br-lg text bg-red-800 hover:bg-red-700 text-white py-2 px-4'>Submit</button>
//                </div>
//                <img
//                    onClick={() => setIsPopupVisible(true)}
//                    src={userHeader}
//                    alt="userHeader"
//                    className='me-7 hover:cursor-pointer h-[60px] w-[60px] rounded-full'
//                />
//            </div>
//
//            {isPopupVisible && (
//                <div className="fixed right-0 z-10 flex justify-end pr-16">
//                    <div ref={popupRef} className="bg-white shadow-[0px_1px_4px_0px_rgba(188,186,184.25)] rounded-2xl w-64 mt-2 text-center">
//                        <div className='flex gap-5 py-5 justify-center items-center border-b-2 border-stone-300'>
//                            <div>
//                                <img src={userProfileHeader} alt="userProfileHeader" className='h-[50px] w-[50px] rounded-full' />
//                            </div>
//                            <div className='text-start'>
//                                <p className='text-[17px] font-semibold'>Gagandeep Singh</p>
//                                <p className='text-sm font-medium text-zinc-600'>Admin</p>
//                            </div>
//                        </div>
//                        <div className='ps-7 text-zinc-600'>
//                            <NavLink to={'/admin/userProfile'} onClick={() => setIsPopupVisible(false)}>
//                                <div className='flex pt-2 justify-start gap-5 items-center'>
//                                    <FaUserLarge size={20} />
//                                    <p className="text-lg font-medium">Profile</p>
//                                </div>
//                            </NavLink>
//                            <NavLink to={'/admin/generalPage'} onClick={() => setIsPopupVisible(false)}>
//                                <div className='flex pt-2 justify-start gap-5 items-center'>
//                                    <IoSettingsSharp size={20} />
//                                    <p className="text-lg font-medium">Settings</p>
//                                </div>
//                            </NavLink>
//                            <NavLink to={'/admin/generalPage'} onClick={() => setIsPopupVisible(false)}>
//                                <div className='flex pt-2 justify-start gap-5 items-center'>
//                                    <IoSettingsSharp size={20} />
//                                    <p className="text-lg font-medium">Settings</p>
//                                </div>
//                            </NavLink>
//                        </div>
//                        <div className='px-7'>
//                            <button
//                                onClick={handleLogout}
//                                className="px-4 my-5 w-full py-2 hover:bg-red-600 bg-red-800 font-semibold text-white rounded-lg">
//                                Logout
//                            </button>
//                        </div>
//                    </div>
//                </div>
//            )}
//        </>
//    );
//};
//
//export default Header;




import React, { useState, useRef, useEffect } from 'react';
import { IoSearchOutline, IoSettingsSharp } from 'react-icons/io5';
import { NavLink, useNavigate } from 'react-router-dom';
import { userHeader, userProfileHeader } from '../../assets';
import { FaUserLarge } from 'react-icons/fa6';
import axios from 'axios';

const Header = () => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [userName, setUserName] = useState('');
    const popupRef = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {
        // Fetch user data from the API
        const fetchUserData = async () => {
            try {
             const authToken = localStorage.getItem('authToken');
            console.log(authToken);
                const response = await axios.get('https://api2.eromantic.ai/api/user/', {
                    headers: {
                        Authorization: `Token ${authToken}`, // Assuming the token is stored in localStorage
                    },
                });
                setUserName(response.data.first_name+" "+response.data.last_name); // Assuming the API returns a username field
                console.log(response.data,"ahjhsjhjhksdf")
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, []);


    const handleLogout = async () => {
        try {
            const response = await axios.post('https://api2.eromantic.ai/api/logout/', {}, {
                headers: {
                    Authorization: `Token ${localStorage.getItem('authToken')}`,
                },
            });

            if (response.status === 200) {
                // Clear token from local storage
                localStorage.removeItem('authToken');
                localStorage.removeItem("hasVisited");
                navigate('/admin');
            } else {
                console.error('Logout failed:', response.data.error);
            }
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setIsPopupVisible(false);
        }
    };

    useEffect(() => {
        if (isPopupVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isPopupVisible]);

    return (
        <>
            <div className='bg-white flex justify-end items-center pt-8 pb-3 md:ps-48 lg:ps-80 w-full font-josefin-sans'>
                {/* <div className='bg-white ms-7 text-lg px-2 py-1 border-2 border-zinc-300 rounded-lg flex justify-between items-center lg:w-[500px]'>
                    <span className='flex items-center'>
                        <IoSearchOutline color='#9CA3C1' size={23} className='me-3' />
                        <input type="text" className='focus:outline-none md:w-40 lg:w-80' placeholder='Search your page...' />
                    </span>
                    <button className='rounded-tr-lg rounded-br-lg text bg-red-800 hover:bg-red-700 text-white py-2 px-4'>Submit</button>
                </div> */}
                <img
                    onClick={() => setIsPopupVisible(true)}
                    src={userHeader}
                    alt="userHeader"
                    className='me-7 hover:cursor-pointer h-[60px] w-[60px] rounded-full'
                />
            </div>

            {isPopupVisible && (
                <div className="fixed right-0 z-10 flex justify-end pr-16">
                    <div ref={popupRef} className="bg-white shadow-[0px_1px_4px_0px_rgba(188,186,184.25)] rounded-2xl w-64 mt-2 text-center">
                        <div className='flex gap-5 py-5 justify-center items-center border-b-2 border-stone-300'>
                            <div>
                                <img src={userProfileHeader} alt="userProfileHeader" className='h-[50px] w-[50px] rounded-full' />
                            </div>
                            <div className='text-start'>
                                <p className='text-[17px] font-semibold'>{userName || 'Guest User'}</p>
                                <p className='text-sm font-medium text-zinc-600'>Admin</p>
                            </div>
                        </div>
                        <div className='ps-7 text-zinc-600'>
                            <NavLink to={'/admin/userProfile'} onClick={() => setIsPopupVisible(false)}>
                                <div className='flex pt-2 justify-start gap-5 items-center'>
                                    <FaUserLarge size={20} />
                                    <p className="text-lg font-medium">Profile</p>
                                </div>
                            </NavLink>
                            <NavLink to={'/admin/generalPage'} onClick={() => setIsPopupVisible(false)}>
                                <div className='flex pt-2 justify-start gap-5 items-center'>
                                    <IoSettingsSharp size={20} />
                                    <p className="text-lg font-medium">Settings</p>
                                </div>
                            </NavLink>
                        </div>
                        <div className='px-7'>
                            <button
                                onClick={handleLogout}
                                className="px-4 my-5 w-full py-2 hover:bg-red-600 bg-red-800 font-semibold text-white rounded-lg">
                                Logout
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Header;

