import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Togal from '../../components/Togal';
import { DeleteImg, eye, rename, tableUser1 } from '../../../assets';
import PagenameSearchBox from '../../components/PagenameSearchBox';
import DeletePopupStaff from '../../components/DeletePopupStaff';
import axios from 'axios';

const OurStaff = () => {
    const navigate = useNavigate();
    const [staffList, setStaffList] = useState([]);
    const [filteredStaff, setFilteredStaff] = useState([]); // State for filtered staff
    const [searchQuery, setSearchQuery] = useState(''); // State for search query
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [staffToDelete, setStaffToDelete] = useState(null); // Track which staff member is being deleted

    useEffect(() => {
        // Fetch the staff list when the component is mounted
        const fetchStaffList = async () => {
            try {
                const authToken = localStorage.getItem('authToken');
                const response = await axios.get('https://api2.eromantic.ai/eromantic_api/list/', {
                    headers: {
                        'Authorization': `Token ${authToken}`
                    }
                });
                setStaffList(response.data);
                setFilteredStaff(response.data); // Initialize filtered staff
            } catch (error) {
                console.error('Error fetching staff list:', error);
            }
        };

        fetchStaffList();
    }, []);

    useEffect(() => {
        // Filter staff based on search query
        const results = staffList.filter(staff =>
            staff.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredStaff(results);
    }, [searchQuery, staffList]);

    const handleBlog = () => {
        navigate('/admin/addStaff');
    };

    const handleProflie = (staffId) => {
        navigate(`/admin/userProfile/${staffId}`);
    };

    const handleEdit = (staffId) => {
        navigate(`/staff/edit_staff/${staffId}`);
    };

    const togglePopup = (staffId) => {
        setStaffToDelete(staffId); // Set the staff member to delete
        setIsPopupOpen(!isPopupOpen); // Toggle the popup
    };

    const handleDelete = async () => {
        if (!staffToDelete) return;

        try {
            const authToken = localStorage.getItem('authToken');
            await axios.delete(`https://api2.eromantic.ai/eromantic_api/delete/${staffToDelete}/`, {
                headers: {
                    'Authorization': `Token ${authToken}`
                }
            });
            setIsPopupOpen(false);
            setStaffList(staffList.filter(staff => staff.id !== staffToDelete)); // Update the staff list in state
            setFilteredStaff(filteredStaff.filter(staff => staff.id !== staffToDelete)); // Update filtered staff
            setStaffToDelete(null); // Reset the staff to delete
        } catch (error) {
            console.error('Error deleting staff:', error);
        }
    };

    const toggleStatus = async (staffId, currentStatus) => {
        console.log(`Toggling status for staff ID: ${staffId}, current status: ${currentStatus}`);

        const staffToUpdate = staffList.find(staff => staff.id === staffId);

        if (!staffToUpdate) {
            console.error('Staff member not found');
            return;
        }

        const updatedStaff = {
            ...staffToUpdate,
            status: !currentStatus // Toggle the status
        };

        try {
            const authToken = localStorage.getItem('authToken');
            const response = await axios.put(`https://api2.eromantic.ai/eromantic_api/edit/${staffId}/`, updatedStaff, {
                headers: {
                    'Authorization': `Token ${authToken}`
                }
            });

            if (response.status === 200) {
                setStaffList(prevStaffList =>
                    prevStaffList.map(staff =>
                        staff.id === staffId ? updatedStaff : staff
                    )
                );
                setFilteredStaff(prevFilteredList =>
                    prevFilteredList.map(staff =>
                        staff.id === staffId ? updatedStaff : staff
                    )
                );
                console.log('Status updated successfully', response.data);
            }
        } catch (error) {
            console.error('Error updating status:', error.response?.data || error.message);
            alert('Failed to update status');
        }
    };

    return (
        <>
            <div className='bg-white px-7 pb-20 font-josefin-sans'>
                <PagenameSearchBox 
                    title='User Staff' 
                    placename='Search Staff' 
                    btnname='Add Staff' 
                    btnfunction={handleBlog} 
                    onSearchChange={e => setSearchQuery(e.target.value)} // Set search query
                />
                <div className="relative overflow-x-auto sm:rounded-lg mt-10">
                    <table className="w-full text-left border-stone-300">
                        <thead className="text-xl text-black bg-stone-300">
                            <tr>
                                <th scope="col" className="px-6 py-3">Staff</th>
                                <th scope="col" className="px-6 py-3">Name</th>
                                <th scope="col" className="px-6 py-3">Role</th>
                                <th scope="col" className="px-6 py-3">Status</th>
                                <th scope="col" className="px-6 py-3 text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredStaff.map((staff) => (
                                <tr key={staff.id} className="bg-white border-b text-black font-normal text-xl border-stone-300">
                                    <td onClick={() => handleProflie(staff.id)} className="px-6 flex items-center py-4 whitespace-nowrap">
                                        <img src={staff.profile_image || tableUser1} alt="user" className='hover:cursor-pointer w-9 h-9 rounded-full' />
                                        <td className="whitespace-nowrap">
                                            <td className="px-4 text-lg whitespace-nowrap">{staff.name}</td>
                                        </td>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">{staff.email}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{staff.role}</td>
                                    <td className="px-6 py-4">
                                        <Togal isChecked={staff.status} value={staff.status} handleChange={() => toggleStatus(staff.id, staff.status)} />
                                    </td>
                                    <td className="px-6 py-4">
                                        <div className='flex gap-5 justify-center items-center'>
                                            <img src={eye} alt="view" className='hover:cursor-pointer' />
                                            <img src={rename} alt="rename" className='hover:cursor-pointer' onClick={() => handleEdit(staff.id)} />
                                            <img src={DeleteImg} alt="delete" className='hover:cursor-pointer' onClick={() => togglePopup(staff.id)} />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {isPopupOpen && (
                <DeletePopupStaff togglePopup={togglePopup} handleDelete={handleDelete} />
            )}
        </>
    );
};

export default OurStaff;
