import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaHeart } from 'react-icons/fa6';
import { GiRoundStar } from 'react-icons/gi';
import { AiFillLike } from 'react-icons/ai';
import { IoIosTime } from 'react-icons/io';
import { IoSearchOutline } from 'react-icons/io5';
import Footer from '../../frontend/components/Footer';

const DiscoverImagesBox = () => {
    const [imageData, setImageData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);
    const [userDetails, setUserDetails] = useState('');
    const navigate = useNavigate();

    const Showcasedata = [
        { name: 'Showcased', icon: <FaHeart size={20} color='#616161' /> },
        { name: 'Most Liked', icon: <GiRoundStar size={20} color='#616161' /> },
        { name: 'Followings', icon: <AiFillLike size={20} color='#616161' /> },
        { name: 'Newest', icon: <IoIosTime size={20} color='#616161' /> }
    ];

    const handleFilter = () => {
        setOpen(!open);
    };

    useEffect(() => {
        const fetchImageData = async () => {
            setLoading(true);
            const logger = localStorage.getItem('login');
            try {
                const response = await axios.get('https://api2.eromantic.ai/api/v1/get_allPublic_image/');
                console.log(response.data, 'Fetched Image Data');
                setImageData(response.data); // Set fetched image data to state
                setUserDetails(logger);
                setLoading(false);
            } catch (error) {
                setError('Failed to fetch data');
                setLoading(false);
            }
        };
        fetchImageData();
    }, []);

    const handleImageView = (imgId) => {
        navigate(`/dashboard/ImageView/${imgId}`);
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    // Reverse the imageData to get the latest images first and slice the last 10 to 15
    const imagesToShow = imageData.reverse().slice(0, 15); // Get the latest 15 images

    return (
        <>
            <div className="p-5 md:p-10">
                <div className="columns-1 gap-1 lg:gap-1 sm:columns-2 lg:columns-3 xl:columns-4 [&>img:not(:first-child)]:mt-5 lg:[&>img:not(:first-child)]:mt-2">
                    {imagesToShow.map((item, index) => {
                        const baseUrl = `.187.26.202:8000/media/output/${item.image_file_name}?auto=format&fit=crop`;
                        return (
                            <div
                                key={index}
                                onClick={() => handleImageView(item.generation_id)}
                                className="relative group"
                            >
                                <img
                                    className="h-auto max-w-full rounded-2xl object-cover object-center p-2 transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg cursor-pointer"
                                    src={`${baseUrl}`}
                                    alt={`gallery-photo-${index}`}
                                />
                                {/* Details Overlay */}
                                <div className="absolute bottom-2 left-2 right-2 bg-black bg-opacity-70 opacity-0 group-hover:opacity-100 transition-opacity duration-300 p-4 text-white text-center border-1 border-gray-400 rounded-[10px]">
                                    <p className="text-sm sm:text-md md:text-lg">{item.generation_prompt}</p>
                                    <h2 className='text-red-800 text-xl'>@{item.user_name}</h2>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default DiscoverImagesBox;
