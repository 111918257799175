import React from 'react'
import ProfileCard from '../../components/ProfileCard'
import { img1, img4, img7, loginImg } from '../../../assets'

const Creators = () => {
    return (
        <>
            <div className=" mt-20 px-2 lg:px-20 pb-96 bg-black pt-10 text-white font-josefin-sans h-full">
                <h1 className="text-white text-2xl md:text-3xl lg:text-4xl font-semibold mb-4 text-center">All Contributesrs</h1>
                <p className='text-zinc-600 text-2xl font-semibold text-center mt-3 lg:mt-8 mb-5 lg:mb-16'>The ranking is established based on the quantity of likes and the count of publicly approved generations.</p>
                <div className='justify-center flex flex-wrap gap-8 w-full 2xl:w-[70%] 2xl:m-auto'>
                    <ProfileCard color="amber" type='Bronze' imges={img4} />
                    <ProfileCard color="stone" type='Silver' imges={loginImg} />
                    <ProfileCard color="yellow" type='Gold' imges={img1} />
                    <ProfileCard color="yellow" type='Gold' imges={img7} />
                    <ProfileCard color="amber" type='Bronze' imges={img4} />
                    <ProfileCard color="stone" type='Silver' imges={loginImg} />
                    <ProfileCard color="yellow" type='Gold' imges={img1} />
                    <ProfileCard color="yellow" type='Gold' imges={img7} />
                </div>
            </div>
        </>
    )
}

export default Creators
