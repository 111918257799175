import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Add axios import
import { img1, img10, img2, img3, img4, img5, img6, img7, img8, img9 } from '../../assets'
import { useNavigate } from 'react-router-dom'
import ImgDetailOnhoverGenration from './ImgDetailOnhoverGenration'
import { Fragment } from 'react';

const GenerationImagesBox = () => {
    const navigate = useNavigate();

    // State management for loading, error, and image data
    const [loading, setLoading] = useState(false);
     const [userDetails, setUserDetails] = useState(null);
    const [error, setError] = useState('');
    const [imageData, setImageData] = useState([]);

    const handleImageView = (imgId) => {
            navigate(`/dashboard/ImageView/${imgId}`);
        };


    useEffect(() => {
        const fetchImageData = async () => {
            setLoading(true);
            const logger = localStorage.getItem('login');
            try {
                const response = await axios.get(`https://api2.eromantic.ai/api/v1/get_allGenerated_image/`, {
                    params: { userId: logger }
                });
                console.log(response.data, 'Fetched Image Data');
                setImageData(response.data);
                setLoading(false);
                setUserDetails(logger);
            } catch (error) {
                setError(error.response.data.detail);
                setLoading(false);
            }
        };


        fetchImageData();
    }, []); // Empty dependency array to ensure useEffect runs once

//    useEffect(() => {
//        const fetchImageData = async () => {
//            setLoading(true);
//            const logger = localStorage.getItem('login');
//            try {
//                const response = await axios.get('https://api2.eromantic.ai/api/v1/get_allPublic_image/');
//                console.log(response.data, 'Fetched Image Data');
//                setImageData(response.data); // Set fetched image data to state
//                setLoading(false);
//                setUserDetails(logger);
//            } catch (error) {
//                setError('Failed to fetch data');
//                setLoading(false);
//            }
//        };
//
//
//        fetchImageData();
//    }, []); // Empty dependency array to ensure useEffect runs once


    if (loading) return <div>Loading...</div>;
    if (error) return <div className='w-full grid grid-cols-1 gap-3 justify-center mt-5 lg:mt-14'> {/* Using CSS Grid for gallery view */}<div className='text-center relative text-red-500 text-2xl font-bold'>{error}</div></div>;
    console.log(userDetails,"ususususu")
    const getRandomWidth = () => Math.floor(Math.random() * (380 - 360 + 80)) + 360;
    const getRandomHeight = () => Math.floor(Math.random() * (524 - 244 + 120)) + 244;

    console.log('imageData11111------------------',imageData)

    return (
        <>
            {/* <div className='w-full grid grid-cols-4 gap-3 justify-center mt-5 lg:mt-14'>
                {imageData.map((item, index) => (
                    <div
                        key={index}
                        className="relative hover:cursor-pointer"
                        onClick={() => handleImageView(item.generation_id)}  // Pass a function reference

                        style={{ width: `${getRandomWidth()}px`, height: `${getRandomHeight()}px` }}
                    >
                        <img
                            src={`https://api2.eromantic.ai/media/output/${item.image_file_name}`}
                            alt="Hover Effect"
                            className="h-full w-full object-cover rounded-xl"
                        />
                        <ImgDetailOnhoverGenration username={userDetails} prompt={item.generation_prompt} model={item.Aimodel} extensions={item.extensions} />

                    </div>
                ))}
            </div> */}

            <div className="p-5 md:p-10">
                <div className="columns-1 gap-1 lg:gap-1 sm:columns-2 lg:columns-3 xl:columns-4 [&>img:not(:first-child)]:mt-5 lg:[&>img:not(:first-child)]:mt-2">
                {imageData.map((item, index) => {
                        const baseUrl = `https://api2.eromantic.ai/media/output/${item.image_file_name}?auto=format&fit=crop`;
                        return (
                            <div  className="relative group" key={index} onClick={() => handleImageView(item.generation_id)}>
                                <img
                                    className="h-auto w-full rounded-2xl object-cover object-center p-2 transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg cursor-pointer"

                                    src={`${baseUrl}`}
                                    alt={`gallery-photo-${index}`}
                                />
                                    <div className="absolute bottom-2 left-2 right-2 bg-black bg-opacity-70 opacity-0 group-hover:opacity-100 transition-opacity duration-300 p-4 text-white text-center border-1 border-gray-400 rounded-[10px]">
                                    <p className="text-sm sm:text-md md:text-lg">{item.generation_prompt}</p>
                                    <h2 className='text-red-800 text-xl'>@{item.user}</h2>
                                    </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    )
}

export default GenerationImagesBox;
