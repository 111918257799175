import React, { useState } from 'react';

const CategoryDiscription = () => {
    const articlesData = [
        {
            name: 'How to Generate SFW (Safe-For-Work) Pictures',
            subdata: [
                { disc: "If you have generated a girl or character on another AI generator, you may be able to re-use it on Seduced.ai. To do so, you must have purchased the plan through Patreon or Cryptocurrency, as you will have to upload the image to the platform and CCBill customers are unable to do so due to their policies." },
                { disc: "You'll have to upload the image on the homepage." },
                { disc: "After you've done so, it might take a couple of hours before our moderation team reviews and approves it." },
                { disc: "After approval, you should be able to click on the image and select 'replicate face'." },
                { disc: "If you get an error saying 'image not approved for face replication', it's likely the image does not look like AI generated and our moderation team missed it." },
            ],
            subheadind: 'How to import a previously AI generated girl from another AI generator'
        },
        {
            name: "'Replicating' generations", subdata: [{ disc: "After approval, you should be able to click on the image and select 'replicate face'." },
            { disc: "If you get an error saying 'image not approved for face replication', it's likely the image does not look like AI generated and our moderation team missed it." },], subheadind: "'Replicating' generations"
        },
        { name: 'AI Models', subdata: [{ disc: "After you've done so, it might take a couple of hours before our moderation team reviews and approves it." },], subheadind: 'AI Models' },
        { name: 'Tips on how to use Seduced.AI', subdata: [{ disc: "You'll have to upload the image on the homepage." }, { disc: "If you get an error saying 'image not approved for face replication', it's likely the image does not look like AI generated and our moderation team missed it." },], subheadind: 'Tips on how to use Seduced.AI' },
        { name: 'How to import a previously AI generated girl from another AI generator', subdata: [{ disc: "After approval, you should be able to click on the image and select 'replicate face'." },], subheadind: 'How to import a previously AI generated girl from another AI generator' },
    ];

    const [selectedArticle, setSelectedArticle] = useState(articlesData[0]);

    return (
        <div className="mt-20 px-2 md:px-5 lg:px-10 pb-36 bg-black pt-10 text-white font-josefin-sans">
            <p className='text-2xl font-semibold mt-5 lg:pt-10'>EROMANTIC.AI Getting started</p>
            <div className='w-full flex flex-wrap xl:justify-center lg:justify-between xl:gap-14 mt-10 lg:mt-32'>
                <div className='w-full lg:w-[40%] xl:w-[30%] 2xl:w-[20%]'>
                    <p className='text-[27px] font-bold'>Articles in this section</p>
                    <ul className='text-xl xl:text-2xl font-normal list-disc ps-10'>
                        {
                            articlesData.map((item, index) => (
                                <li
                                    key={index}
                                    className={`pt-6 leading-tight hover:cursor-pointer ${selectedArticle.name === item.name ? 'text-white' : ''}`}
                                    onClick={() => setSelectedArticle(item)}
                                >
                                    {item.name}
                                </li>
                            ))
                        }
                    </ul>
                </div>
                <div className='w-full lg:w-[55%] xl:w-[65%] 2xl:w-[48%] mt-20 lg:mt-0'>
                    {
                        selectedArticle.subheadind &&
                        <p className='text-3xl xl:text-[40px] font-semibold leading-tight'>
                            {selectedArticle.subheadind}
                        </p>
                    }
                    <p className='text-xl font-extralight mt-5'>6 months ago Updated</p>
                    <ul className='text-xl xl:text-2xl font-normal'>
                        {
                            selectedArticle.subdata.map((data, index) => (
                                <li key={index} className='pt-8 lg:pt-10 leading-tight'>
                                    {data.disc}
                                </li>
                            ))
                        }
                    </ul>
                    <div className='border-t-2 border-white mt-20'></div>
                    <p className='text-[24px] font-semibold text-center mt-8'>Was this article helpful?</p>
                    <div className='flex justify-center gap-4 mt-3'>
                        <button className='bg-white text-lg lg:text-[24px] font-semibold text-red-800 rounded-xl text-center w-32 lg:w-44 py-2 lg:py-4'>YES</button>
                        <button className='bg-white text-lg lg:text-[24px] font-semibold text-red-800 rounded-xl text-center w-32 lg:w-44 py-2 lg:py-4'>NO</button>
                    </div>
                    <p className='text-[24px] font-semibold text-center mt-5'>4 out of 4 found this helpful</p>
                </div>
            </div>
        </div>
    );
};

export default CategoryDiscription;
