import React, { useState } from 'react'
import EasyTrackingBox from '../../components/EasyTrackingBox'
import { videoPlay } from '../../../assets'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Footer from '../../../frontend/components/Footer';
const BecomeAffiliate = () => {

    const [copySuccess, setCopySuccess] = useState('');

    const copyToClipboard = () => {
        const textToCopy = "https://www.eromantic.ai?ref=6638b4f04b073b3fadebe7d9";
        const textArea = document.createElement('textarea');
        textArea.value = textToCopy;
        document.body.appendChild(textArea);
        textArea.select();

        try {
            document.execCommand('copy');
            setCopySuccess('Copied!');
            setTimeout(() => {
                setCopySuccess('');
            }, 2000);
        } catch (err) {
            console.error('Failed to copy text', err);
        }

        document.body.removeChild(textArea);
    };

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const handleAffiliateSignup = () => {
        // Implement affiliate sign-up logic here, such as redirecting to a sign-up form
        window.location.href = '/dashboard/affiliate-signup'; // Example: Redirect to the affiliate sign-up page
    };

    return (
        <>
            {/* <div className='mt-20 px-2 md:px-5 xl:px-20  pb-20 bg-white pt-10 text-black font-kanit'>
                <p className='text-center text-[40px]'>Referrals</p>
                <div className='flex justify-center pt-3'>
                    <p className='text-2xl xl:w-[70%] text-center'>Earn commissions by referring users to Seduced and receiving a share of up to 40% from their plan purchases or renewals.</p>
                </div>
                <div className='w-full flex flex-wrap gap-5 justify-center mt-16'>
                    <div className=' flex flex-col justify-center gap-8'>
                        <EasyTrackingBox name='Lucrative Commissions:' text='Pocket a generous 25% of net revenue for every member you bring on board! With premium plans ranging from $20-$120, your earning potential is seriously high.' />

                        <EasyTrackingBox name='Passive Income Stream:' text='Enjoy recurring revenue month after month, as long as your referrals remain subscribed. Build a base of referrals and watch your income grow.' />

                        <EasyTrackingBox name='Easy Tracking:' text='Our advanced affiliate dashboard gives you real-time insights. See exactly who signs up through your link and track your earnings with crystal clarity.' />
                    </div>
                    <div className='flex flex-col justify-center gap-8'>
                        <div>
                            <img src={videoPlay} alt="videoPlay" className='rounded-xl md:w-[630px] 2xl:w-[730px] h-[220px] md:h-[390px]' />
                        </div>
                        <EasyTrackingBox name='Premium Product, High Conversions:' text='You will be promoting a cutting-edge AI image generation solution that is in high demand. Our proven marketing materials help you close sales effortlessly.' />
                    </div>
                </div>

                <div className='border-2 border-zinc-600 rounded-xl p-5 mt-10'>
                    <p className='text-2xl text-left pb-2'>Your tracking link</p>
                    <div className="flex items-center border-2 border-zinc-600 rounded-xl p-2 bg-white text-black">
                        <input
                            type="text"
                            value="https://www.eromantic.ai?ref=6638b4f04b073b3fadebe7d9"
                            readOnly
                            className="flex-grow bg-white text-black border-none focus:outline-none focus:ring-0"
                        />
                        <button
                            onClick={copyToClipboard}
                            className="ml-2 px-1 md:px-4 py-2 bg-white text-red-600 rounded-md hover:bg-gray-300"
                        >
                            {copySuccess ? 'Copied' : 'CopyLink'}
                        </button>
                    </div>
                </div>

                <div className="p-4 border-2 border-zinc-600 text-black rounded-lg mt-10">
                    <div className="mb-4 flex items-center justify-start gap-10">
                        <span className="text-2xl ">Sales history</span>
                        <div className="flex flex-wrap items-center justify-center border-2 border-zinc-600">
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                className="md:px-2 py-1 text-center w-fit bg-[#61616100] rounded text-black"
                            />
                            <span>-</span>
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                className="md:px-2 py-1 text-center w-fit bg-[#61616100]  rounded text-black"
                            />
                        </div>
                    </div>

                    <div className="relative overflow-x-auto sm:rounded-lg mt-10 font-josefin-sans">
                        <table className="w-full text-left ">
                            <thead className=" border-t-2 border-zinc-600 text-black">
                                <tr className='text-lg'>
                                    <th className="px-4 py-2 text-left uppercase tracking-wider">Date</th>
                                    <th className="px-4 py-2 text-left uppercase tracking-wider">Renewal</th>
                                    <th className="px-4 py-2 text-left uppercase tracking-wider">Src</th>
                                    <th className="px-4 py-2 text-left uppercase tracking-wider">ID</th>
                                    <th className="px-4 py-2 text-left uppercase tracking-wider">Payment method</th>
                                    <th className="px-4 py-2 text-left uppercase tracking-wider">Gross</th>
                                    <th className="px-4 py-2 text-left uppercase tracking-wider">Gateway fees</th>
                                    <th className="px-4 py-2 text-left uppercase tracking-wider">Refunded</th>
                                    <th className="px-4 py-2 text-left uppercase tracking-wider">Charged back</th>
                                    <th className="px-4 py-2 text-left uppercase tracking-wider">Affiliate %</th>
                                    <th className="px-4 py-2 text-left uppercase tracking-wider">Earnings</th>
                                </tr>
                            </thead>
                            <tbody>
                               
                            </tbody>
                        </table>
                    </div>


                </div>
            </div> */}

            <div className='flex flex-col min-h-screen'>
            <div className="flex-1 container mx-auto px-6 py-12">
            <h1 className="text-3xl font-semibold text-center mb-8">Become an Affiliate Member</h1>
            <p className="text-center text-lg mb-12">
                Join our affiliate program and start earning by promoting Eros Media LLC. Our program is designed to offer attractive commissions, dedicated support, and an easy way to grow your earnings.
            </p>

            {/* Affiliate Program Overview */}
            <div className="mb-8">
                <h2 className="text-2xl font-semibold mb-2">Why Join Our Affiliate Program?</h2>
                <p className="text-lg mb-4">
                    As an affiliate, you earn a commission for every successful referral. Our program offers up to 50% commission on direct referrals, with additional earnings from sub-affiliate referrals. We provide support to help you maximize your success as an affiliate.
                </p>
            </div>

            {/* Policy Summary */}
            <div className="mb-8">
                <h2 className="text-2xl font-semibold mb-2">Affiliate Program Policy</h2>
                <p className="text-lg mb-4">
                    Our affiliate program is designed for transparency and fairness. Affiliates are expected to adhere to ethical promotion practices, including honest representation and avoiding any misleading promotions. Earnings are processed on demand once you reach a threshold of $150, and we offer support to answer any questions or concerns along the way.
                </p>
                <p className="text-lg mb-4">
                    For more details, please refer to our Affiliate Program Policy available in our Policies section.
                </p>
            </div>

            {/* Benefits Section */}
            <div className="mb-8">
                <h2 className="text-2xl font-semibold mb-2">Benefits of Becoming an Affiliate</h2>
                <ul className="list-disc list-inside text-lg mb-4">
                    <li>Earn up to 50% commission on direct referrals.</li>
                    <li>Additional earnings from sub-affiliate referrals.</li>
                    <li>Access to exclusive affiliate offers and promotions.</li>
                    <li>Dedicated support team to help you succeed.</li>
                    <li>Comprehensive tracking and reporting tools.</li>
                </ul>
            </div>

            {/* CTA Button */}
            <div className="text-center mt-12">
                <button
                    onClick={handleAffiliateSignup}
                    className="bg-[#ea3333d1] text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-purple-700 transition duration-200"
                >
                    Become an Affiliate
                </button>
            </div>

            {/* Contact Support */}
            <div className="text-center mt-8">
                <p className="text-lg font-medium">
                    Have questions about the affiliate program? Contact our support team at 
                    <a href="mailto:support@eromantic.ai" className="text-blue-500 ml-1">
                        support@eromantic.ai
                    </a>.
                </p>
            </div>
            </div>
            <Footer/>
            </div>
        </>
    )
}

export default BecomeAffiliate;