import React, { useState, useEffect } from 'react';
import { blogPosts, postData } from '../../assets/data';
import { useNavigate } from "react-router-dom";
import Footer from '../components/Footer';
import axios from 'axios';

const BASE_URL = 'https://api2.eromantic.ai/api/'; // Replace with your actual API URL

const Blog = () => {
    const [categories, setCategories] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const navigate = useNavigate();

    const handleOpenBlog = () => {
        navigate("/singleblogs/${blogId}");
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch Categories
                const categoriesResponse = await axios.get(`${BASE_URL}show_blog_categories/`);
                setCategories(categoriesResponse.data);

                // Fetch Blogs
                const blogsResponse = await axios.get(`${BASE_URL}show_blog_post_public/`);
                const formattedBlogs = blogsResponse.data.map(blog => {
                const date = new Date(blog.created_at);

                // Format the date
                const day = date.getDate().toString().padStart(2, '0'); // Ensure two digits
                const month = date.toLocaleString('en-US', { month: 'short' }); // 'Aug'

                const formattedDate = `${day} ${month}`;

                // Return the blog object with the formatted date
                return {
                    ...blog,
                    formattedDate,  // Add the formatted date as a new property
                };
            });

                // Set blogs with the formatted date
            console.log(formattedBlogs)
            setBlogs(formattedBlogs);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    function stripHtml(html) {
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
    }

    return (
        <>
            <div className='bg-black px-5 lg:px-10 xl:px-20'>
                <p className='text-white font-semibold text-[40px] text-center py-5'>Our Blogs</p>
                <div className=''>
                    <div className='flex flex-col lg:flex-row justify-center lg:justify-between flex-wrap py-10'>
                        <div className='w-full lg:w-[60%] xl:w-[70%] h-[800px] flex justify-start flex-wrap gap-8 lg:gap-5 pt-4 overflow-y-auto'>
                           {blogs.map(blog => (
                                <div key={blog.blog_id} className="relative w-[80%] md:w-[40%] lg:w-[48%] xl:w-[31%] h-[350px] lg:h-[360px] xl:h-[350px] bg-white border border-gray-200 rounded-2xl shadow p-2 cursor-pointer" onClick={() => navigate(`/singleBlog/${blog.slug}`)}>
                                    <div className='absolute flex justify-center left-8 -top-4 bg-[#FF0013] rounded-full w-[50px] h-[50px] text-sm font-bold text-white text-center items-center leading-none p-1'>
                                        {blog.formattedDate}
                                    </div>
                                    <div>
                                        <img className="object-cover w-full h-full rounded-t-2xl" src={blog.image} alt={blog.title} style={{ height: '210px' }} />
                                    </div>
                                    <div className='py-3'>
                                        <p className='text-[14px] font-extrabold'>{blog.title?.length>80?stripHtml(blog.title).slice(0, 80)+'...':stripHtml(blog.title)}</p>
                                        <p className='text-[12px] font-normal'>{blog.description?.length>80?stripHtml(blog.description).slice(0, 80)+'...':stripHtml(blog.description)}</p>
                                    </div>
                                    <button className='text-[14px] text-[#FF0013] font-extrabold pb-6'>Read More +</button>
                                </div>
                            ))}
                        </div>
                        <div className='mt-10 lg:mt-0 lg:w-[35%] xl:w-[27%] px-5 bg-white rounded-2xl font-josefin-sans  md:mx-32 lg:mx-0'>
                            <div className='max-w-xs 2xl:max-w-sm w-full mx-auto'>
                                <div className="relative md:mx-3 lg:mx-0 xl:mx-5 2xl:mx-0 bg-white rounded-full my-8">
                                    <input placeholder="Search" className="rounded-full text-lg w-full h-14 bg-transparent py-2 pl-8 pr-32 outline-none border-2 border-[#000000] hover:outline-none" type="text" name="query" id="query" />
                                    <button type="submit" className="absolute inline-flex items-center h-12 w-12 rounded-full outline-none right-1 top-1 bg-black sm:px-6 focus:bg-gray-600">
                                    </button>
                                </div>

                                <div className='text-[23px] md:mx-3 lg:mx-0 xl:mx-5 2xl:mx-0'>
                                    <p className='font-bold'>Categories</p>
                                    <div className='py-2'>
                                        {categories.length > 0 ? (
                                            categories.map((item, index) => (
                                                <div key={index} className='font-light flex gap-3'>
                                                    <p className='text-[#FF0A0A]'>{item.plush}</p>
                                                    <p>{item.title} ({item.blog_count?item.blog_count:0})</p>
                                                </div>
                                            ))
                                        ) : (
                                            <p>No categories found.</p>
                                        )}
                                    </div>
                                </div>

                                <div className='md:mx-3 lg:mx-0 xl:mx-5 2xl:mx-0'>
                                    <p className='text-[23px] font-bold'>Popular Posts</p>

                                    <div className="flex flex-col gap-5 mt-3">
                                        {blogs.slice(0, 3).map((post) => (
                                            <div key={post.id} className="flex items-center">
                                                <div>
                                                    <img src={post.image} alt="Rectangle57" className='w-[160px] object-cover rounded-xl' style={{ maxWidth: '350px', height: '130px' }} />
                                                </div>
                                                <div className="ml-4">
                                                    <p className="font-extrabold text-[16px]">{post.title?.length>50?stripHtml(post.title).slice(0, 50)+'...':stripHtml(post.title)}</p>
                                                    <p className="text-[13px]">{post.description?.length>80?stripHtml(post.description).slice(0, 80)+'...':stripHtml(post.description)}</p>
                                                    <p className='text-[#FF0013] text-[16px] font-extrabold py-3'>{post.formattedDate}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='lg:mt-5 flex flex-col justify-center items-center'>
                {/* <hr className='border-1 border-black w-[641px]' /> */}
                <div className='flex text-center justify-center gap-10 text-black text-[22px] font-semibold my-5'>
                    <a className="text-[14px] lg:text-lg" href="/blogs">Blogs</a>
                    <a className="text-[14px] lg:text-lg" href="/terms-of-service">Terms of Service</a>
                    <a className="text-[14px] lg:text-lg" href="/privacy-policy">Privacy Policy</a>
                    <a className="text-[14px] lg:text-lg" href="/cookie-policy">Cookie Policy</a>
                </div>
            </div>

            {/* <Footer /> */}
        </>
    );
};

export default Blog;

