import React, { useState } from 'react'
import { AiFillLike } from 'react-icons/ai'
import { FaHeart } from 'react-icons/fa6'
import { GiRoundStar } from 'react-icons/gi'
import { IoIosTime } from 'react-icons/io'
import { IoSearchOutline } from 'react-icons/io5'
import { filter } from '../../../assets'
import { filterOn } from '../../../assets';
import GenerationImagesBox from '../../components/GenerationImagesBox'
import Footer from '../../../frontend/components/Footer'

const Generations = () => {

    const [open, setOpen] = useState(false)

    const Showcasedata = [
        { name: 'Waiting', icon: <IoIosTime size={20} color='#616161' /> }, { name: 'All', icon: <AiFillLike size={20} color='#616161' /> }, { name: 'Upscaled', icon: <GiRoundStar size={20} color='#616161' /> }, { name: 'Liked', icon: <FaHeart size={20} color='#616161' /> },
    ]

    const afterModelData = [
        { name: 'AFTER SEX', }, { name: 'AFTER SEX', }, { name: 'AFTER SEX', }, { name: 'AFTER SEX', }, { name: 'AFTER SEX', }, { name: 'AFTER SEX', }, { name: 'AFTER SEX', }, { name: 'AFTER SEX', }, { name: 'AFTER SEX', }, { name: 'AFTER SEX', },]

    const handleFilter = () => {
        setOpen(!open)
    }

    return (
        <>
            <div className='mt-20 lg:px-20 px-2 pb-20 pt-10 text-black font-josefin-sans'>
                <div className='flex justify-center xl:justify-between flex-wrap items-center'>
                    <div className='m-2 px-3 py-2 w-[450px] text-black border-2 border-zinc-600 flex items-center gap-5 bg-white rounded-full '>
                        <IoSearchOutline color='black' size={23} />
                        <input type="text" placeholder='Search anything' className='bg-white rounded-full w-full focus:outline-none text-xl' />
                    </div>
                    <div className='flex justify-center lg:justify-start items-center flex-wrap'>
                        {
                            Showcasedata.map((item, index) => {
                                return (
                                    <div key={index} className="w-60 md:w-48 m-2 justify-center flex items-center  gap-2 px-4 py-2 border-2 bg-[#6161614A] hover:cursor-pointer hover:bg-[#FF00133D] border-zinc-600 text-black text-lg rounded-full hover:border-red-700 hover:text-red-500">
                                        {item.icon}
                                        <p className='text-center'>{item.name}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='flex gap-5 items-center m-2 hover:cursor-pointer' onClick={handleFilter} >
                        <p className={`font-semibold text-lg ${open ? 'text-red-500' : 'text-black'  }`}>Filters</p>
                        <img src={open ? filterOn : filter} alt="filter" />
                </div>
                </div>

                {
                    open &&
                    <div>
                        <div className="flex flex-wrap items-center justify-center space-x-4 px-4 py-2 border-2 border-zinc-600 bg-[#6161614A] text-black rounded-lg mt-5">
                            <div className='border-r-2 border-zinc-600 flex justify-center'>
                                <button className="px-4 py-2 text-black rounded-lg hover:text-zinc-600 focus:outline-none">NORMAL</button>
                                <button className="px-4 py-2 text-black rounded-lg hover:text-zinc-600 focus:outline-none">EXPENDED</button>
                                <button className="px-4 py-2 text-black rounded-lg hover:text-zinc-600 focus:outline-none">UPSCALED</button>
                            </div>
                            <div className='border-r-2 border-zinc-600 flex justify-center'>
                                <button className="px-4 py-2 text-black rounded-lg hover:text-zinc-600 focus:outline-none">IMAGE</button>
                                <button className="px-4 py-2 text-black rounded-lg hover:text-zinc-600 focus:outline-none">VIDEO</button>
                            </div>
                            <div className='border-r-2 border-zinc-600 flex justify-center'>
                                <button className="px-4 py-2 text-black rounded-lg hover:text-zinc-600 focus:outline-none">REALISTIC</button>
                                <button className="px-4 py-2 text-black rounded-lg hover:text-zinc-600 focus:outline-none">ANIME</button>
                            </div>
                            <button className="px-4 py-2 text-black rounded-lg hover:text-zinc-600 focus:outline-none">NSFW</button>
                        </div>

                        <div className='border-2 px-11 py-8 border-zinc-600 bg-[#6161614A] rounded-xl w-full h-[465px] overflow-y-auto scrollbar-thumb-rose-500 mt-5'>
                            <div className='flex flex-wrap justify-center gap-7 '>
                                {
                                    afterModelData.map((item1, index) => {
                                        return (
                                            <div key={index} className='bg-[#ECECEC] w-[268px] h-[172px] rounded-2xl mt-3'>
                                                <div className='aftersexBg opacity-60 w-[268px] h-[172px] rounded-2xl flex justify-center items-center'>
                                                    <p className='text-xl'>{item1.name}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
                <GenerationImagesBox />
            </div>
            <Footer/>
        </>
    )
}

export default Generations
