//import React, { useState } from 'react';
//
//const Dropdown = () => {
//    const [isOpen, setIsOpen] = useState(false);
//    const [selectedOption, setSelectedOption] = useState(null);
//
//    const toggleDropdown = () => {
//        setIsOpen(!isOpen);
//    };
//
//    const handleOptionClick = (option) => {
//        setSelectedOption(option);
//        setIsOpen(false);
//    };
//
//    return (
//        <div className="relative inline-block text-left w-[100%] font-josefin-sans pt-2">
//            <div>
//                <button
//                    type="button"
//                    className="bg-white w-[100%] text-gray-400 px-2 text-[20px] py-3 border border-zinc-300 rounded-lg"
//                    onClick={toggleDropdown}
//                >
//                    <div className={`flex justify-between text-lg items-center px-5 ${selectedOption ? 'text-black' : 'text-zinc-300'}`}>
//                        {selectedOption || 'Select Categories here...'}
//                        <svg
//                            className="ml-2 h-5 w-5 inline-block"
//                            xmlns="http://www.w3.org/2000/svg"
//                            viewBox="0 0 20 20"
//                            fill="currentColor"
//                            aria-hidden="true"
//                        >
//                            <path
//                                fillRule="evenodd"
//                                d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4.25 4.25a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 01.02-1.06z"
//                                clipRule="evenodd"
//                            />
//                        </svg>
//                    </div>
//                </button>
//            </div>
//
//            {isOpen && (
//                <div className="absolute w-full z-10 mt-2 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
//                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
//                        <button
//                            className="block px-4 py-2 text-lg text-gray-700 hover:bg-gray-300 w-full text-start hover:text-gray-900"
//                            onClick={() => handleOptionClick('Option 1')}
//                        >
//                            Option 1
//                        </button>
//                        <button
//                            className="block px-4 py-2 text-lg text-gray-700 hover:bg-gray-300 w-full text-start hover:text-gray-900"
//                            onClick={() => handleOptionClick('Option 2')}
//                        >
//                            Option 2
//                        </button>
//                        <button
//                            className="block px-4 py-2 text-lg text-gray-700 hover:bg-gray-300 w-full text-start hover:text-gray-900"
//                            onClick={() => handleOptionClick('Option 3')}
//                        >
//                            Option 3
//                        </button>
//                    </div>
//                </div>
//            )}
//        </div>
//    );
//};
//
//export default Dropdown;




import React, { useState } from 'react';

const Dropdown = ({ options, onChange, placeholder = 'Select Categories here...' }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option.label);
        setIsOpen(false);
        onChange(option); // Call the onChange callback with the selected option
    };

    return (
        <div className="relative inline-block text-left w-[100%] font-josefin-sans pt-2">
            <div>
                <button
                    type="button"
                    className="bg-white w-[100%] text-gray-400 px-2 text-[20px] py-3 border border-zinc-300 rounded-lg"
                    onClick={toggleDropdown}
                >
                    <div className={`flex justify-between text-lg items-center px-5 ${selectedOption ? 'text-black' : 'text-zinc-300'}`}>
                        {selectedOption || placeholder}
                        <svg
                            className="ml-2 h-5 w-5 inline-block"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                fillRule="evenodd"
                                d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4.25 4.25a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 01.02-1.06z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </div>
                </button>
            </div>

            {isOpen && (
                <div className="absolute w-full z-10 mt-2 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        {options.map((option) => (
                            <button
                                key={option.value}
                                className="block px-4 py-2 text-lg text-gray-700 hover:bg-gray-300 w-full text-start hover:text-gray-900"
                                onClick={() => handleOptionClick(option)}
                            >
                                {option.label}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Dropdown;

