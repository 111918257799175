import { useState, useEffect } from "react";
import { RiCompassDiscoverLine, RiAiGenerate } from "react-icons/ri";
import { TbGenderDemigirl, TbPremiumRights, TbArrowGuide, TbGenderDemiboy } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";

export const Bottombar = ({ toggleSidebar, isOpen,setActiveTab }) => {
    const [active, setActive] = useState(1);
    const [Userlogger, setUserlogger] = useState(null);
    const [usercheck, setUsercheck] = useState(null);
    const goto = (index) => setActive(index);
    const navigate = useNavigate();

    useEffect(() => {
        setUsercheck(localStorage.getItem('hasVisited'));
        setUserlogger(localStorage.getItem('login'));
    }, []); // Add dependency array to avoid infinite loop


    return (
        <>
            <div class="bottombar bg-[#dbd5d5] fixed bottom-0 z-1000 w-full -translate-x-1/2 border-t border-black left-1/2 " style={{zIndex:"9999"}}>
                <div class="w-full">
                    <div class="grid max-w-xs grid-cols-2 gap-1 p-1 mx-auto mt-1 bg-[#99111f] rounded-lg" role="group">
                        <button type="button" onClick={() => setActiveTab('Girlfriend')} class="px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-[#a97b7b] dark:text-white dark:hover:bg-[#9d8989] rounded-lg">
                            AI GirlFriend
                        </button>
                        <button type="button" onClick={() => setActiveTab('Boyfriend')} class="px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-[#9d8989] rounded-lg">
                            AI BoyFriend
                        </button>
                    </div>
                </div>
                <div class="grid h-full max-w-lg grid-cols-5 mx-auto">
                {!isOpen ?
                    <button data-tooltip-target="tooltip-home"  onClick={toggleSidebar} type="button" class="inline-flex flex-col items-center justify-center p-3 m-1 hover:bg-[#ffd1d1] group">
                    <svg class="w-5 h-5 mb-1 text-black group-hover:text-black-600 dark:group-hover:text-black-500" stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="21" y1="10" x2="3" y2="10"></line><line x1="21" y1="6" x2="3" y2="6"></line><line x1="21" y1="14" x2="3" y2="14"></line><line x1="21" y1="18" x2="3" y2="18"></line></svg>
                        <span class="sr-only">Home</span>
                    </button>
                    :
                    <button data-tooltip-target="tooltip-home"  onClick={toggleSidebar} type="button" class="inline-flex flex-col items-center justify-center p-3 m-1 hover:bg-[#ffd1d1] group">
                    <svg class="w-5 h-5 mb-1 text-black group-hover:text-black-600 dark:group-hover:text-black-500" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49z"></path></svg>
                        <span class="sr-only">Home</span>
                    </button>
                }
                    <div id="tooltip-home" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                        Home
                        <div class="tooltip-arrow" data-popper-arrow></div>
                    </div>
                    <button data-tooltip-target="tooltip-bookmark" type="button" onClick={() => navigate(Userlogger ? "/AidreamGFchat" : "/login")} class="inline-flex flex-col items-center justify-center p-3 m-1 hover:bg-[#ffd1d1] group">
                        <i class="fa-solid fa-comments fa-xl text-black group-hover:text-black-600 dark:group-hover:text-black-500"></i>
                        <span class="sr-only">Chat</span>
                    </button>
                    <div id="tooltip-bookmark" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                        Chat
                        <div class="tooltip-arrow" data-popper-arrow></div>
                    </div>
                    <button data-tooltip-target="tooltip-post" type="button" onClick={() => navigate(Userlogger ? "/dashboard" : "/login")} class="inline-flex flex-col items-center justify-center p-3 m-1 hover:bg-[#ffd1d1] group">
                        <svg class="w-5 h-5 mb-1 text-black group-hover:text-black-600 dark:group-hover:text-black-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16"/>
                        </svg>
                        <span class="sr-only">New Generate</span>
                    </button>
                    <div id="tooltip-post" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                        New Generate
                        <div class="tooltip-arrow" data-popper-arrow></div>
                    </div>
                    <button data-tooltip-target="tooltip-search" type="button" onClick={() => navigate(Userlogger ? "/dashboard/generations" : "/login")}  class="inline-flex flex-col items-center justify-center p-3 m-1 hover:bg-[#ffd1d1] group">
                        <i class="fa-solid fa-images fa-xl text-black group-hover:text-black-600 dark:group-hover:text-black-500"></i>
                        <span class="sr-only">Gallery</span>
                    </button>
                    <div id="tooltip-search" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                        Gallery
                        <div class="tooltip-arrow" data-popper-arrow></div>
                    </div>
                    <button data-tooltip-target="tooltip-settings" type="button" onClick={() => navigate(Userlogger ? "/dashboard/accountProfile" : "/login")} class="inline-flex flex-col items-center justify-center p-3 m-1 hover:bg-[#ffd1d1] group">
                        <i class="fa-solid fa-user fa-xl text-black group-hover:text-black-600 dark:group-hover:text-black-500"></i>
                        <span class="sr-only">Profile</span>
                    </button>
                    <div id="tooltip-settings" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                        Profile
                        <div class="tooltip-arrow" data-popper-arrow></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Bottombar;
