import React from 'react'
import Recreateimage from '../../components/Recreateimage'
import SelectAIModel from '../../components/SelectAIModel'
import UseShortWordsInputBox from '../../components/UseShortWordsInputBox'
import FooterMakeAI from '../../components/FooterMakeAI'
import SearchExtension from '../../components/SearchExtension'

const ReplicatePose = () => {
    return (
        <>
            <p className='pt-5 text-xl xl:text-[25px] font-medium'>Poses are more precise than extensions, so if you know you want an image with a certain pose, we recommend using this section. We advise against selecting extensions which are sex poses.</p>

            <Recreateimage />

            <SelectAIModel />

            <SearchExtension />

            <UseShortWordsInputBox name='Imagine (prompt)' plachold='a beautiful girl, pink hair, russian, ahegao, sticking her tongue out, (eyes crossed, crossing her eyes), cum leaking out, face covered in cum, grool, wearing lingerie, perfect teeth, 8k, raw, cinematic' />
            <UseShortWordsInputBox name='Face prompt (optional)' plachold='e.g. beautiful' />
            <UseShortWordsInputBox name='Exclude' plachold='e.g. multi people, 2 girls' />

            <FooterMakeAI />
        </>
    )
}

export default ReplicatePose