import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Ani1, img1, img3, img4, img7, smalltits } from '../../../assets';
import {
    firstImage, second_ani_1, hair3, body3, img42, skinnyBody, secondThird, largeB3, hugeOo4, slim2, thick4, secondFifth, greenEye,
    secondSecond, hugeO4, sixFirstLong, hair5, img41, averageB2, averageOo2, age4, age2, hugeB4, secondSix, secondAni3,
    average2, shortHairo, longHair, img44, age3, age1, skinny1, slimBody, huge4, hair2, img45, smallO1, secondAni2,
    secondFourth, blackEye, shortHair, smallB1, large3, fifthFirst, fifthThird, fifthFourth, fifthSecond, grayEye, brownEye,
    largeO3, longHairo, averageo2, hair4, thickBody, blueEye, img43, secondAni1, largeOo3, secondFirstImage, athletic3, small1, smallai,
    understanding, tooth, writer, innocent, hammer, handInHand, teacher, graphicDesigner, couple1, fireman, laboratory, hugs, couple, librarian,
    fuel, voiceMessage, kiss, winner, hug, hearts, hugCopy, redhair, greenhair, blackhair, brownhair, pinkhair, greyhair,
} from '../../../assets';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import loaders from '../../../frontend/components/loaders';

const groupData = {
    group1: null,
    group2: null,
    group3: null,
}
const BASE_URL = "https://api2.eromantic.ai/assistant_api/";

const AIGirlfriend = () => {
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [gfData, setGFdata] = useState('');
    const [style, setStyle] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [finalInputValue, setFinalInputValue] = useState('');

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [optionsData, setOptionsData] = useState('');
    const [selectedColor, setSelectedColor] = useState('');
    const [pageSequence, setPageSequence] = useState([]);
    const [firstmsg, setFirstmsg] = useState();
    const [completedata, setCompletedata] = useState("");
    const [generatedimg, setGeneratedimg] = useState("");

    const handleColorClick = (color) => {
        setSelectedColor(color);
    };

    const navigate = useNavigate();

    const images0 = [{ src: firstImage, title: 'Realistic', tab: 'Style' }, { src: second_ani_1, title: 'Anime', tab: 'Style' }];
    const images1 = [{ src: secondFirstImage, title: 'Caucasian', tab: 'Ethnicity' }, { src: secondSecond, title: 'Asian', tab: 'Ethnicity' }, { src: secondThird, title: 'Arabic', tab: 'Ethnicity' }, { src: secondFourth, title: 'Latin', tab: 'Ethnicity' }, { src: secondFifth, title: 'African', tab: 'Ethnicity' }, { src: secondSix, title: 'Anime', tab: 'Ethnicity' }];
    const images2 = [{ src: age1, title: 'Teen (18+)', tab: 'Age' }, { src: age2, title: 'Twenties', tab: 'Age' }, { src: age3, title: 'Thirties', tab: 'Age' }, { src: age4, title: 'Forties', tab: 'Age' }];
    const images3 = [{ src: brownEye, title: 'Brown', tab: 'Eyes' }, { src: blueEye, title: 'Blue', tab: 'Eyes' }, { src: greenEye, title: 'Green', tab: 'Eyes' }, { src: blackEye, title: 'Black', tab: 'Eyes' }, { src: grayEye, title: 'Gray', tab: 'Eyes' }];
    const images4 = [{ src: fifthFirst, title: 'Straight', tab: 'Hair Style & Color' }, { src: fifthSecond, title: 'Curly', tab: 'Hair Style & Color' }, { src: fifthThird, title: 'Ponytail', tab: 'Hair Style & Color' }, { src: fifthFourth, title: 'Braids', tab: 'Hair Style & Color' }, { src: fifthFirst, title: 'Long', tab: 'Hair Style & Color' }, { src: shortHairo, title: 'Short', tab: 'Hair Style & Color' }];
    const images5 = [{ src: skinny1, title: 'Skinny', tab: 'Body Type' }, { src: athletic3, title: 'Slim', tab: 'Body Type' }, { src: slim2, title: 'Athletic', tab: 'Body Type' }, { src: thick4, title: 'Curvy', tab: 'Body Type' }];
    const images6 = [{ src: small1, title: 'Small', tab: 'Breast Size' }, { src: averageOo2, title: 'Medium', tab: 'Breast Size' }, { src: largeOo3, title: 'Large', tab: 'Breast Size' }, { src: hugeOo4, title: 'Huge', tab: 'Breast Size' }];
    const images7 = [{ src: smallO1, title: 'Small', tab: 'Butt Size' }, { src: averageo2, title: 'Medium', tab: 'Butt Size' }, { src: largeO3, title: 'Large', tab: 'Butt Size' }, { src: hugeO4, title: 'Athletic', tab: 'Butt Size' }];


    // understanding, , writer, , hammer, handInHand, , , couple1, fireman, , hugs, couple, ,
    // fuel, voiceMessage, kiss, winner, hug, hearts, hugCopy,

    const images8 = [{ src: '', title: 'Trailblazer', tab: 'Personality' }, { src: '', title: 'Nurturer', tab: 'Personality' },
    { src: '', title: 'Free Spirit', tab: 'Personality' }, { src: '', title: 'Intellectual', tab: 'Personality' },
    { src: '', title: 'Social Butterfly', tab: 'Personality' }, { src: '', title: 'Creative Soul', tab: 'Personality' },
    { src: '', title: 'Leader', tab: 'Personality' }, { src: '', title: 'Peacemaker', tab: 'Personality' },
    { src: '', title: 'Analyst', tab: 'Personality' }, { src: '', title: 'Optimist', tab: 'Personality' },
    { src: '', title: 'Realist', tab: 'Personality' }, { src: '', title: 'Advocate', tab: 'Personality' },
    { src: '', title: 'Homebody', tab: 'Personality' }, { src: '', title: 'Wit', tab: 'Personality' },
    { src: '', title: 'Life of the Party', tab: 'Personality' }, { src: '', title: 'Wife', tab: 'Personality' }, { src: '', title: 'Sexual Deviant', tab: 'Personality' },
    { src: '', title: 'Mistress', tab: 'Personality' }, { src: '', title: 'Milf', tab: 'Personality' },
    { src: '', title: 'Drama Queen', tab: 'Personality' }, { src: '', title: 'Party Girl', tab: 'Personality' },
    { src: '', title: 'Submissive Girlfriend', tab: 'Personality' }, { src: '', title: 'Caregiver', tab: 'Personality' },
    { src: '', title: 'Rebel', tab: 'Personality' }, { src: '', title: 'Thrill-Seeker', tab: 'Personality' },
    { src: '', title: 'Minimalist', tab: 'Personality' }, { src: '', title: 'Fashionista', tab: 'Personality' },
    { src: '', title: 'Gamer', tab: 'Personality' }, { src: '', title: 'Foodie', tab: 'Personality' },
    { src: '', title: 'Nature Lover', tab: 'Personality' }, { src: '', title: 'Bookworm', tab: 'Personality' },
    { src: '', title: 'Manipulator', tab: 'Personality' }];
    const images9 = [{ src: voiceMessage, title: 'Natural', tab: 'Voice' }, { src: voiceMessage, title: 'Formal', tab: 'Voice' }, { src: voiceMessage, title: 'Casual', tab: 'Voice' }, { src: voiceMessage, title: 'Emotional', tab: 'Voice' }];
    const images11 = [{ src: '', title: 'Nurse', tab: 'Occupation' }, { src: '', title: 'Teacher', tab: 'Occupation' },
    { src: '', title: 'Lawyer', tab: 'Occupation' }, { src: '', title: 'Doctor', tab: 'Occupation' },
    { src: '', title: 'Dentist', tab: 'Occupation' }, { src: '', title: 'Pharmacist', tab: 'Occupation' },
    { src: '', title: 'Veterinarian', tab: 'Occupation' }, { src: '', title: 'Physical Therapist', tab: 'Occupation' },
    { src: '', title: 'Mental Health Counselor', tab: 'Occupation' }, { src: '', title: 'Librarian', tab: 'Occupation' },
    { src: '', title: 'School Counselor', tab: 'Occupation' }, { src: '', title: 'Accountant', tab: 'Occupation' },
    { src: '', title: 'Personal Trainer', tab: 'Occupation' }, { src: '', title: 'Human Resources', tab: 'Occupation' },
    { src: '', title: 'Project Manager', tab: 'Occupation' }, { src: '', title: 'Software Engineer', tab: 'Occupation' },
    { src: '', title: 'Musician', tab: 'Occupation' }, { src: '', title: 'Artist', tab: 'Occupation' },
    { src: '', title: 'Data Scientist', tab: 'Occupation' }, { src: '', title: 'Interior Designer', tab: 'Occupation' },
    { src: '', title: 'Writer', tab: 'Occupation' }, { src: '', title: 'Architect', tab: 'Occupation' },
    { src: '', title: 'Environmental Scientist', tab: 'Occupation' }, { src: '', title: 'Entrepreneur', tab: 'Occupation' },
    { src: '', title: 'Construction Worker', tab: 'Occupation' }, { src: '', title: 'Auto Mechanic', tab: 'Occupation' },
    { src: '', title: 'Electrician', tab: 'Occupation' }, { src: '', title: 'Ballerina', tab: 'Occupation' },
    { src: '', title: 'Social Worker', tab: 'Occupation' }, { src: '', title: 'Pilot', tab: 'Occupation' },
    { src: '', title: 'Real Estate Agent', tab: 'Occupation' }, { src: '', title: 'Chef', tab: 'Occupation' }
    ];

    const images12 = [{ src: '', title: 'Casual', tab: 'Relationship' }, { src: '', title: 'Romantic', tab: 'Relationship' }, { src: '', title: 'Friendship', tab: 'Relationship' }, { src: '', title: 'Wife', tab: 'Relationship' }, { src: '', title: 'Girlfriend', tab: 'Relationship' }];


    const images10 = [{ src: secondAni1, title: 'Caucasian', tab: 'Ethnicity' }, { src: secondAni2, title: 'Asian', tab: 'Ethnicity' }, { src: secondAni3, title: 'Arabic', tab: 'Ethnicity' },];
    const images20 = [{ src: age1, title: 'Teen (18+)', tab: 'Age' }, { src: age2, title: 'Twenties', tab: 'Age' }, { src: age3, title: 'Thirties', tab: 'Age' }, { src: age4, title: 'Forties', tab: 'Age' }];
    const images30 = [{ src: img42, title: 'Brown', tab: 'Eyes' }, { src: img44, title: 'Blue', tab: 'Eyes' }, { src: img45, title: 'Green', tab: 'Eyes' }, { src: img41, title: 'Black', tab: 'Eyes' }, { src: img43, title: 'Gray', tab: 'Eyes' }];
    const images40 = [{ src: hair2, title: 'Straight', tab: 'Hair Style & Color' }, { src: hair3, title: 'Curly', tab: 'Hair Style & Color' }, { src: hair4, title: 'Ponytail', tab: 'Hair Style & Color' }, { src: hair5, title: 'Braids', tab: 'Hair Style & Color' }, { src: longHair, title: 'Long', tab: 'Hair Style & Color' }, { src: shortHair, title: 'Short', tab: 'Hair Style & Color' }];
    const images50 = [{ src: skinnyBody, title: 'Skinny', tab: 'Body Type' }, { src: slimBody, title: 'Slim', tab: 'Body Type' }, { src: body3, title: 'Athleic', tab: 'Body Type' }, { src: thickBody, title: 'Curvy', tab: 'Body Type' }];
    const images60 = [{ src: smallai, title: 'Small', tab: 'Breast Size' }, { src: average2, title: 'Medium', tab: 'Breast Size' }, { src: large3, title: 'Large', tab: 'Breast Size' }, { src: huge4, title: 'Huge', tab: 'Breast Size' }];
    const images70 = [{ src: smallB1, title: 'Small', tab: 'Butt Size' }, { src: averageB2, title: 'Medium', tab: 'Butt Size' }, { src: largeB3, title: 'Large', tab: 'Butt Size' }, { src: hugeB4, title: 'Athletic', tab: 'Butt Size' }];

    const images80 = [{ src: '', title: 'Trailblazer', tab: 'Personality' }, { src: '', title: 'Nurturer', tab: 'Personality' },
    { src: '', title: 'Free Spirit', tab: 'Personality' }, { src: '', title: 'Intellectual', tab: 'Personality' },
    { src: '', title: 'Social Butterfly', tab: 'Personality' }, { src: '', title: 'Creative Soul', tab: 'Personality' },
    { src: '', title: 'Leader', tab: 'Personality' }, { src: '', title: 'Peacemaker', tab: 'Personality' },
    { src: '', title: 'Analyst', tab: 'Personality' }, { src: '', title: 'Optimist', tab: 'Personality' },
    { src: '', title: 'Realist', tab: 'Personality' }, { src: '', title: 'Advocate', tab: 'Personality' },
    { src: '', title: 'Homebody', tab: 'Personality' }, { src: '', title: 'Wit', tab: 'Personality' },
    { src: '', title: 'Life of the Party', tab: 'Personality' }, { src: '', title: 'Wife', tab: 'Personality' }, { src: '', title: 'Sexual Deviant', tab: 'Personality' },
    { src: '', title: 'Mistress', tab: 'Personality' }, { src: '', title: 'Milf', tab: 'Personality' },
    { src: '', title: 'Drama Queen', tab: 'Personality' }, { src: '', title: 'Party Girl', tab: 'Personality' },
    { src: '', title: 'Submissive Girlfriend', tab: 'Personality' }, { src: '', title: 'Caregiver', tab: 'Personality' },
    { src: '', title: 'Rebel', tab: 'Personality' }, { src: '', title: 'Thrill-Seeker', tab: 'Personality' },
    { src: '', title: 'Minimalist', tab: 'Personality' }, { src: '', title: 'Fashionista', tab: 'Personality' },
    { src: '', title: 'Gamer', tab: 'Personality' }, { src: '', title: 'Foodie', tab: 'Personality' },
    { src: '', title: 'Nature Lover', tab: 'Personality' }, { src: '', title: 'Bookworm', tab: 'Personality' },
    { src: '', title: 'Manipulator', tab: 'Personality' }];
    const images90 = [{ src: understanding, title: 'Natural', tab: 'Voice' }, { src: '', title: 'Formal', tab: 'Voice' }, { src: '', title: 'Casual', tab: 'Voice' }, { src: '', title: 'Emotional', tab: 'Voice' }];
    const images110 = [{ src: '', title: 'Nurse', tab: 'Occupation' }, { src: '', title: 'Teacher', tab: 'Occupation' },
    { src: '', title: 'Lawyer', tab: 'Occupation' }, { src: '', title: 'Doctor', tab: 'Occupation' },
    { src: '', title: 'Dentist', tab: 'Occupation' }, { src: '', title: 'Pharmacist', tab: 'Occupation' },
    { src: '', title: 'Veterinarian', tab: 'Occupation' }, { src: '', title: 'Physical Therapist', tab: 'Occupation' },
    { src: '', title: 'Mental Health Counselor', tab: 'Occupation' }, { src: '', title: 'Librarian', tab: 'Occupation' },
    { src: '', title: 'School Counselor', tab: 'Occupation' }, { src: '', title: 'Accountant', tab: 'Occupation' },
    { src: '', title: 'Personal Trainer', tab: 'Occupation' }, { src: '', title: 'Human Resources', tab: 'Occupation' },
    { src: '', title: 'Project Manager', tab: 'Occupation' }, { src: '', title: 'Software Engineer', tab: 'Occupation' },
    { src: '', title: 'Musician', tab: 'Occupation' }, { src: '', title: 'Artist', tab: 'Occupation' },
    { src: '', title: 'Data Scientist', tab: 'Occupation' }, { src: '', title: 'Interior Designer', tab: 'Occupation' },
    { src: '', title: 'Writer', tab: 'Occupation' }, { src: '', title: 'Architect', tab: 'Occupation' },
    { src: '', title: 'Environmental Scientist', tab: 'Occupation' }, { src: '', title: 'Entrepreneur', tab: 'Occupation' },
    { src: '', title: 'Construction Worker', tab: 'Occupation' }, { src: '', title: 'Auto Mechanic', tab: 'Occupation' },
    { src: '', title: 'Electrician', tab: 'Occupation' }, { src: '', title: 'Ballerina', tab: 'Occupation' },
    { src: '', title: 'Social Worker', tab: 'Occupation' }, { src: '', title: 'Pilot', tab: 'Occupation' },
    { src: '', title: 'Real Estate Agent', tab: 'Occupation' }, { src: '', title: 'Chef', tab: 'Occupation' }
    ];
    const images120 = [{ src: '', title: 'Casual', tab: 'Relationship' }, { src: '', title: 'Romantic', tab: 'Relationship' }, { src: '', title: 'Friendship', tab: 'Relationship' }, { src: '', title: 'Wife', tab: 'Relationship' }, { src: '', title: 'Girlfriend', tab: 'Relationship' }];

    const images = [{ src: img1, title: 'Realistic' }, { src: img3, title: 'Anime' }, { src: img4, title: 'Cartoon' }, { src: img7, title: 'Sci-Fi' },];


    useEffect(() => {
        setSelectedOptions([]);
    }, [currentPage]);

    useEffect(() => {
        if (selectedOptions.length === 3) {
            handleNextoptions();
        }
    }, [selectedOptions]);

    const handleOptionSelect = (value) => {
        setSelectedOptions(prevState => {
            // If the value is already selected, remove it; otherwise, add it
            if (prevState.includes(value)) {
                return prevState.filter(option => option !== value);
            } else if (prevState.length < 3) {
                return [...prevState, value];
            }
            return prevState; // Don't add more than 3
        });
    };

    const handleImageClick = (index) => {
        setSelectedIndex(index);
    };

    const handleBack = () => {
        if (selectedIndex === null) {
            if (currentPage === 1) {
                navigate(-1);
            } else {
                setCurrentPage(currentPage - 1);
            }
        } else {
            setSelectedIndex(null);
        }
    };

    const colors = [
        { name: redhair, color: 'red' },
        { name: greenhair, color: 'green' },
        { name: pinkhair, color: 'pink' },
        { name: blackhair, color: 'black' },
        { name: brownhair, color: 'brown' },
        { name: greyhair, color: 'grey' },
    ];


    const handleNext = (item, selectedColor) => {
        const { title, tab } = item;
        if (currentPage === 1) {
            setStyle(title);
        }
        let newData = { ...gfData, [`gfData${currentPage}`]: { title, tab } };

        if (currentPage >= 1 && currentPage <= 4) {
            setGFdata(newData);
            if (currentPage < 15) {
                setCurrentPage(currentPage + 1);
            } else {
                navigate('/final');
            }
        } else if (currentPage === 5) {
            if (selectedColor == '') {
                toast.error('Select Hair Color First', {
                    position: "top-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                newData = { ...newData, [`gfData${currentPage}`]: { title, tab, color: selectedColor } };
                setGFdata(newData);
                setCurrentPage(currentPage + 1);
            }
        } else if (currentPage >= 6 && currentPage <= 12) {
            // Update state for pages 6 through 8
            let newData = { ...gfData, [`gfData${currentPage}`]: { title, tab } };
            setGFdata(newData);
            if (currentPage < 15) {
                setCurrentPage(currentPage + 1);
            } else {
                navigate('/final');
            }
        }
    };


    const pageNames = {
        13: 'Hobbies'
    };

    const getPageName = (pageNumber) => pageNames[pageNumber] || `Page ${pageNumber}`;
    const handleNextoptions = () => {
        let nextPage = currentPage;
        if (currentPage >= 13 && currentPage <= 13) {
            if (Object.values(selectedOptions).every(option => option !== null)) {
                if (currentPage <= 13) {
                    nextPage = currentPage + 1;
                } else {
                    navigate('/final');
                    return;
                }
            }
        }
        setPageSequence(prevSequence => {
            const updatedSequence = [...prevSequence, currentPage, selectedOptions];
            const groupByPage = (sequence) => {
                const grouped = {};
                for (let i = 0; i < sequence.length; i += 2) {
                    const page = sequence[i];
                    const options = sequence[i + 1];
                    if (!grouped[page]) {
                        grouped[page] = [];
                    }
                    grouped[page].push(options);
                }
                return grouped;
            };
            const formatGroupedData = (groupedData) => {
                return Object.keys(groupedData).sort((a, b) => parseInt(a) - parseInt(b)).map(page => ({
                    page: getPageName(parseInt(page)),
                    options: groupedData[page]
                }));
            };
            const groupedData = groupByPage(updatedSequence);
            const formattedData = formatGroupedData(groupedData);

            setOptionsData(formattedData);
            return updatedSequence;
        });

        setCurrentPage(nextPage);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    // Handler for button click
    const handleData = () => {
        if (inputValue.trim() === '') {
            alert("Set GirlFriend Name")
        } else {
            setCurrentPage(currentPage + 1);
        }
    };
    const u_id = localStorage.getItem('u_id');

    const Generategf = async () => {
        if (!inputValue || inputValue.trim() === "") {
            toast.error('Please provide a Name', {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            })
            return;
        }
        const payload = {
            gfData: gfData,
            optionsData: optionsData,
            GFNmae: inputValue,
            Summery: finalInputValue,
        };

        try {
            loaders(true, "heart")
            const authToken = localStorage.getItem('token');
            console.log("aaaaaaaaaa", authToken)
            const response = await axios.post(`${BASE_URL}bot_create/`, payload, {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });
            if (response.status === 201) {
                loaders(false, "heart")
                setFirstmsg(response.data.response_message)
                setCurrentPage(currentPage + 1);
                setGeneratedimg(response.data.imgname)
            }
        } catch (error) {
            loaders(false, "heart")
            const errorMessage = error.response?.data?.message || 'An unexpected error occurred.';
            toast.error(errorMessage, {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            console.error('Error logging in:', error);
        }
    };


    const Chatpage = () => {
        navigate('/AidreamGFchat');
    }

    useEffect(() => {
        NewGenerate();
    }, []);

    const NewGenerate = async () => {
        // setLoading(true);
        // setError(null);
        try {
            const response = await axios.get(`${BASE_URL}bot_fetch/?user_id=${u_id}`);
            if (response.status === 200) {
                setCompletedata(response.data)
            } else {
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
        }
    };

    return (
        <>
            <ToastContainer />
            {currentPage === 1 && (
                <>
                    <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                        Looking for a simple chat? Try our Basic AI Girlfriend. Want a deeper connection? Choose the Advanced AI Girlfriend for a more personalized experience.
                    </p>
                    <div className='bg-gray-500 flex items-center justify-between rounded-xl mt-[40px] mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38'>
                        <div className='text-xl xl:text-[25px] text-white font-medium text-center'>
                            <span>1 /</span>
                            <span className='text-red-600'>
                                15
                                <span className='inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800'></span>
                            </span>
                            <span> Select Style</span>
                        </div>
                        <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button>
                    </div>

                    <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>

                        {images0.map((item, index) => (
                            <div
                                key={index}
                                className={`relative w-72 h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] rounded-[16px] ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border border-transparent'}`}
                                onClick={() => handleNext(item)}
                            >
                                <img
                                    src={item.src}
                                    alt={item.title}
                                    className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                />
                                <button className='dreamclr text-white font-medium rounded-full absolute px-5 py-1 bottom-10 ml-[100px] xl:ml-[100px] ' onClick={() => handleNext(item)}>
                                    {item.title}
                                </button>
                            </div>
                        ))}
                    </div>
                </>
            )}

            {currentPage === 2 && (
                <>
                    <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                        Looking for a simple chat? Try our Basic AI Girlfriend. Want a deeper connection? Choose the Advanced AI Girlfriend for a more personalized experience.</p>
                    <div className='bg-gray-500 flex items-center justify-between rounded-xl mt-[40px] mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38'>
                        <div className='text-xl xl:text-[25px] text-white font-medium text-center'>
                            <span>2 /</span>
                            <span className='text-red-600'>
                                15
                                <span className='inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800'></span>
                            </span>
                            <span> Select Ethnicity</span>
                        </div>
                        <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button>
                    </div>

                    <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>
                        {style === 'Realistic' ? (
                            images1.map((item, index) => (
                                <div
                                    key={index}
                                    className={`relative w-72 h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] rounded-[16px] ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border border-transparent'}`}
                                    onClick={() => handleNext(item)}
                                >
                                    <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    />
                                    <button className='dreamclr text-white font-medium rounded-full absolute px-5 py-1 bottom-10 ml-[100px] xl:ml-[100px] ' onClick={() => handleNext(item)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        ) : (
                            images10.map((item, index) => (
                                <div
                                    key={index}
                                    className={`relative w-72 h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] rounded-[16px] ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border border-transparent'}`}
                                    onClick={() => handleNext(item)}
                                >
                                    <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    />
                                    <button className='dreamclr text-white font-medium rounded-full absolute px-5 py-1 bottom-10 ml-[100px] xl:ml-[100px] ' onClick={() => handleNext(item)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        )}
                    </div>
                </>
            )}

            {currentPage === 3 && (
                <>
                    <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                        Looking for a simple chat? Try our Basic AI Girlfriend. Want a deeper connection? Choose the Advanced AI Girlfriend for a more personalized experience.                    </p>
                    <div className='bg-gray-500 flex items-center justify-between rounded-xl mt-[40px] mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38'>
                        <div className='text-xl xl:text-[25px] text-white font-medium text-center'>
                            <span>3 /</span>
                            <span className='text-red-600'>
                                15
                                <span className='inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800'></span>
                            </span>
                            <span> Select Age</span>
                        </div>
                        <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button>
                    </div>

                    <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>
                        {style === 'Realistic' ? (
                            images2.map((item, index) => (
                                <div
                                    key={index}
                                    className={`relative w-72 h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] rounded-[16px] ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border border-transparent'}`}
                                    onClick={() => handleNext(item)}
                                >
                                    <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    />
                                    <button className='dreamclr text-white font-medium rounded-full absolute px-5 py-1 bottom-10 ml-[100px] xl:ml-[100px] ' onClick={() => handleNext(item)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        ) : (
                            images20.map((item, index) => (
                                <div
                                    key={index}
                                    className={`relative w-72 h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] rounded-[16px] ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border border-transparent'}`}
                                    onClick={() => handleNext(item)}
                                >
                                    <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    />
                                    <button className='dreamclr text-white font-medium rounded-full absolute px-5 py-1 bottom-10 ml-[100px] xl:ml-[100px] ' onClick={() => handleNext(item)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        )}
                    </div>

                </>
            )}

            {currentPage === 4 && (
                <>
                    <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                        Looking for a simple chat? Try our Basic AI Girlfriend. Want a deeper connection? Choose the Advanced AI Girlfriend for a more personalized experience.                    </p>
                    <div className='bg-gray-500 flex items-center justify-between rounded-xl mt-[40px] mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38'>
                        <div className='text-xl xl:text-[25px] text-white font-medium text-center'>
                            <span>4 /</span>
                            <span className='text-red-600'>
                                15
                                <span className='inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800'></span>
                            </span>
                            <span> Select Eyes</span>
                        </div>
                        <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button>
                    </div>

                    <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>
                        {style === 'Realistic' ? (
                            images3.map((item, index) => (
                                <div
                                    key={index}
                                    className={`relative w-72 h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] rounded-[16px] ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border border-transparent'}`}
                                    onClick={() => handleNext(item)}
                                >
                                    <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    />
                                    <button className='dreamclr text-white font-medium rounded-full absolute px-5 py-1 bottom-10 ml-[100px] xl:ml-[100px] ' onClick={() => handleNext(item)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        ) : (
                            images30.map((item, index) => (
                                <div
                                    key={index}
                                    className={`relative w-72 h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] rounded-[16px] ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border border-transparent'}`}
                                    onClick={() => handleNext(item)}
                                >
                                    <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    />
                                    <button className='dreamclr text-white font-medium rounded-full absolute px-5 py-1 bottom-10 ml-[100px] xl:ml-[100px] ' onClick={() => handleNext(item)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        )}
                    </div>

                </>
            )}

            {currentPage === 5 && (
                <>
                    <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                        Looking for a simple chat? Try our Basic AI Girlfriend. Want a deeper connection? Choose the Advanced AI Girlfriend for a more personalized experience.
                    </p>
                    <div className='bg-gray-500 flex items-center justify-between rounded-xl mt-[40px] mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38'>
                        <div className='text-xl xl:text-[25px] text-white font-medium text-center'>
                            <span>5 /</span>
                            <span className='text-red-600'>
                                15
                                <span className='inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800'></span>
                            </span>
                            <span> Select hair Style & Color*</span>
                        </div>
                        <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button>
                    </div>

                    <div className='text-center'>
                        {/* <p className='text-[35px] py-2'> Choose hair Color*</p> */}
                        <div className='grid grid-cols-3 sm:grid-cols-3 lg:grid-cols-6 gap-4 justify-between'>
                            {colors.map(({ name, color }) => (
                                <div className='grid'>
                                    <div
                                        key={name}
                                        className={`relative w-[8rem] h-[8rem] rounded-full inline-flex items-center justify-center text-xl font-bold border-[1px] ${selectedColor === color ? 'border-red-600' : ''
                                            }`}
                                        onClick={() => handleColorClick(color)}
                                    >
                                        <img
                                            src={name}
                                            alt={name}
                                            className='w-[8rem] h-[8rem] rounded-full object-cover'
                                        />

                                    </div>
                                    <span>{color}</span>
                                </div>
                            ))}
                        </div>

                    </div>

                    <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 my-5'>
                        {style === 'Realistic' ? (
                            images4.map((item, index) => (
                                <div
                                    key={index}
                                    className={`relative w-72 h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] rounded-[16px] ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border border-transparent'}`}
                                    onClick={() => handleNext(item, selectedColor)}
                                >
                                    <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    />
                                    <button className='dreamclr text-white font-medium rounded-full absolute px-5 py-1 bottom-10 ml-[100px] xl:ml-[100px] ' onClick={() => handleNext(item, selectedColor)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        ) : (
                            images40.map((item, index) => (
                                <div
                                    key={index}
                                    className={`relative w-72 h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] rounded-[16px] ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border border-transparent'}`}
                                    onClick={() => handleNext(item, selectedColor)}
                                >
                                    <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    />
                                    <button className='dreamclr text-white font-medium rounded-full absolute px-5 py-1 bottom-10 ml-[100px] xl:ml-[100px] ' onClick={() => handleNext(item, selectedColor)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        )}
                    </div>
                </>
            )}

            {currentPage === 6 && (
                <>
                    <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                        Looking for a simple chat? Try our Basic AI Girlfriend. Want a deeper connection? Choose the Advanced AI Girlfriend for a more personalized experience.                    </p>
                    <div className='bg-gray-500 flex items-center justify-between rounded-xl mt-[40px] mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38'>
                        <div className='text-xl xl:text-[25px] text-white font-medium text-center'>
                            <span>6 /</span>
                            <span className='text-red-600'>
                                15
                                <span className='inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800'></span>
                            </span>
                            <span>Choose Body Type</span>
                        </div>
                        <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button>
                    </div>

                    <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>
                        {style === 'Realistic' ? (
                            images5.map((item, index) => (
                                <div
                                    key={index}
                                    className={`relative w-72 h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] rounded-[16px] ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border border-transparent'}`}
                                    onClick={() => handleNext(item)}
                                >
                                    <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    />
                                    <button className='dreamclr text-white font-medium rounded-full absolute px-5 py-1 bottom-10 ml-[100px] xl:ml-[100px] ' onClick={() => handleNext(item)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        ) : (
                            images50.map((item, index) => (
                                <div
                                    key={index}
                                    className={`relative w-72 h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] rounded-[16px] ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border border-transparent'}`}
                                    onClick={() => handleNext(item)}
                                >
                                    <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    />
                                    <button className='dreamclr text-white font-medium rounded-full absolute px-5 py-1 bottom-10 ml-[100px] xl:ml-[100px] ' onClick={() => handleNext(item)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        )}
                    </div>

                </>
            )}

            {currentPage === 7 && (
                <>
                    <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                        Looking for a simple chat? Try our Basic AI Girlfriend. Want a deeper connection? Choose the Advanced AI Girlfriend for a more personalized experience.                    </p>
                    <div className='bg-gray-500 flex items-center justify-between rounded-xl mt-[40px] mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38'>
                        <div className='text-xl xl:text-[25px] text-white font-medium text-center'>
                            <span>7 /</span>
                            <span className='text-red-600'>
                                15
                                <span className='inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800'></span>
                            </span>
                            <span> Choose Breast Size</span>
                        </div>
                        <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button>
                    </div>

                    <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>
                        {style === 'Realistic' ? (
                            images6.map((item, index) => (
                                <div
                                    key={index}
                                    className={`relative w-72 h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] rounded-[16px] ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border border-transparent'}`}
                                    onClick={() => handleNext(item)}
                                >
                                    <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    />
                                    <button className='dreamclr text-white font-medium rounded-full absolute px-5 py-1 bottom-10 ml-[100px] xl:ml-[100px] ' onClick={() => handleNext(item)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        ) : (
                            images60.map((item, index) => (
                                <div
                                    key={index}
                                    className={`relative w-72 h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] rounded-[16px] ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border border-transparent'}`}
                                    onClick={() => handleNext(item)}
                                >
                                    <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    />
                                    <button className='dreamclr text-white font-medium rounded-full absolute px-5 py-1 bottom-10 ml-[100px] xl:ml-[100px] ' onClick={() => handleNext(item)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        )}
                    </div>

                </>
            )}

            {currentPage === 8 && (
                <>
                    <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                        Looking for a simple chat? Try our Basic AI Girlfriend. Want a deeper connection? Choose the Advanced AI Girlfriend for a more personalized experience.                    </p>
                    <div className='bg-gray-500 flex items-center justify-between rounded-xl mt-[40px] mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38'>
                        <div className='text-xl xl:text-[25px] text-white font-medium text-center'>
                            <span>8 /</span>
                            <span className='text-red-600'>
                                15
                                <span className='inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800'></span>
                            </span>
                            <span> Choose The Butt Size</span>
                        </div>
                        <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button>
                    </div>

                    <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>
                        {style === 'Realistic' ? (
                            images7.map((item, index) => (
                                <div
                                    key={index}
                                    className={`relative w-72 h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] rounded-[16px] ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border border-transparent'}`}
                                    onClick={() => handleNext(item)}
                                >
                                    <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    />
                                    <button className='dreamclr text-white font-medium rounded-full absolute px-5 py-1 bottom-10 ml-[100px] xl:ml-[100px] ' onClick={() => handleNext(item)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        ) : (
                            images70.map((item, index) => (
                                <div
                                    key={index}
                                    className={`relative w-72 h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] rounded-[16px] ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border border-transparent'}`}
                                    onClick={() => handleNext(item)}
                                >
                                    <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    />
                                    <button className='dreamclr text-white font-medium rounded-full absolute px-5 py-1 bottom-10 ml-[100px] xl:ml-[100px] ' onClick={() => handleNext(item)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        )}
                    </div>

                </>
            )}

            {currentPage === 9 && (
                <>
                    <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                        Looking for a simple chat? Try our Basic AI Girlfriend. Want a deeper connection? Choose the Advanced AI Girlfriend for a more personalized experience.                    </p>
                    <div className='bg-gray-500 flex items-center justify-between rounded-xl mt-[40px] mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38'>
                        <div className='text-xl xl:text-[25px] text-white font-medium text-center'>
                            <span>9 /</span>
                            <span className='text-red-600'>
                                15
                                <span className='inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800'></span>
                            </span>
                            <span> Choose Personality</span>
                        </div>
                        <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button>
                    </div>

                    <div>
                        <div className='flex flex-wrap justify-center gap-2 lg:gap-5 mt-5'>
                            {style === 'Realistic' ? (
                                images8.map((item, index) => (
                                    <div
                                        key={index}
                                        className={`flex justify-center items-center relative w-40 h-20  text-center cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] transition-shadow duration-300 ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border '}`}
                                        onClick={() => handleNext(item)}
                                    >
                                        {/* <img
                                            src={item.src}
                                            alt={item.title}
                                            className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                        /> */}
                                        <button className='dreamclr text-white font-medium w-40 rounded-full absolute px-5 py-1' onClick={() => handleNext(item)}>
                                            {item.title}
                                        </button>
                                    </div>
                                ))
                            ) : (
                                images80.map((item, index) => (
                                    <div
                                        key={index}
                                        className={`flex justify-center items-center relative w-40 h-20  text-center cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] rounded-[16px] ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border '}`}
                                        onClick={() => handleNext(item)}
                                    >
                                        {/* <img
                                            src={item.src}
                                            alt={item.title}
                                            className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                        /> */}
                                        <button className='dreamclr text-white font-medium rounded-full absolute px-5 py-1 ' onClick={() => handleNext(item)}>
                                            {item.title}
                                        </button>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </>
            )}

            {currentPage === 10 && (
                <>
                    <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                        Looking for a simple chat? Try our Basic AI Girlfriend. Want a deeper connection? Choose the Advanced AI Girlfriend for a more personalized experience.
                    </p>
                    <div className='bg-gray-500 flex items-center justify-between rounded-xl mt-[40px] mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38'>
                        <div className='text-xl xl:text-[25px] text-white font-medium text-center'>
                            <span>10 /</span>
                            <span className='text-red-600'>
                                15
                                <span className='inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800'></span>
                            </span>
                            <span> Choose Voice</span>
                        </div>
                        <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button>
                    </div>

                    <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>
                        {style === 'Realistic' ? (
                            images9.map((item, index) => (
                                <div
                                    key={index}
                                    className={`flex justify-center items-center relative w-40 h-20  text-center cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] transition-shadow duration-300 ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border'}`}
                                    onClick={() => handleNext(item)}
                                >
                                    {/* <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    /> */}
                                    <button className='dreamclr text-white w-40 font-medium rounded-full absolute px-5 py-1 ' onClick={() => handleNext(item)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        ) : (
                            images90.map((item, index) => (
                                <div
                                    key={index}
                                    className={`flex justify-center items-center relative w-40 h-20  text-center cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] transition-shadow duration-300 ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border'}`}
                                    onClick={() => handleNext(item)}
                                >
                                    {/* <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    /> */}
                                    <button className='dreamclr text-white w-40 font-medium rounded-full absolute px-5 py-1' onClick={() => handleNext(item)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        )}
                    </div>
                </>
            )}

            {currentPage === 11 && (
                <>
                    <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                        Looking for a simple chat? Try our Basic AI Girlfriend. Want a deeper connection? Choose the Advanced AI Girlfriend for a more personalized experience.
                    </p>
                    <div className='bg-gray-500 flex items-center justify-between rounded-xl mt-[40px] mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38'>
                        <div className='text-xl xl:text-[25px] text-white font-medium text-center'>
                            <span>11 /</span>
                            <span className='text-red-600'>
                                15
                                <span className='inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800'></span>
                            </span>
                            <span> Choose Occupation</span>
                        </div>
                        <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button>
                    </div>

                    <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>
                        {style === 'Realistic' ? (
                            images11.map((item, index) => (
                                <div
                                    key={index}
                                    className={`flex justify-center items-center relative w-40 h-20  text-center cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] transition-shadow duration-300 ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border'}`}
                                    onClick={() => handleNext(item)}
                                >
                                    {/* <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    /> */}
                                    <button className='dreamclr w-40 text-white font-medium rounded-full absolute px-5 py-1' onClick={() => handleNext(item)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        ) : (
                            images110.map((item, index) => (
                                <div
                                    key={index}
                                    className={`flex justify-center items-center relative w-40 h-20  text-center cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] transition-shadow duration-300 ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border'}`}
                                    onClick={() => handleNext(item)}
                                >
                                    {/* <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    /> */}
                                    <button className='dreamclr text-white w-40 font-medium rounded-full absolute px-5 py-1' onClick={() => handleNext(item)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        )}
                    </div>
                </>
            )}

            {currentPage === 12 && (
                <>
                    <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                        Looking for a simple chat? Try our Basic AI Girlfriend. Want a deeper connection? Choose the Advanced AI Girlfriend for a more personalized experience.
                    </p>
                    <div className='bg-gray-500 flex items-center justify-between rounded-xl mt-[40px] mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38'>
                        <div className='text-xl xl:text-[25px] text-white font-medium text-center'>
                            <span>12 /</span>
                            <span className='text-red-600'>
                                15
                                <span className='inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800'></span>
                            </span>
                            <span>Choose Relationship</span>
                        </div>
                        <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button>
                    </div>

                    <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>
                        {style === 'Realistic' ? (
                            images12.map((item, index) => (
                                <div
                                    key={index}
                                    className={`flex justify-center items-center relative w-40 h-20  text-center cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] transition-shadow duration-300 ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border '}`}
                                    onClick={() => handleNext(item)}
                                >
                                    {/* <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    /> */}
                                    <button className='dreamclr w-40 text-white font-medium rounded-full absolute px-5 py-1 ' onClick={() => handleNext(item)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        ) : (
                            images120.map((item, index) => (
                                <div
                                    key={index}
                                    className={`flex justify-center items-center relative w-40 h-20  text-center cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] rounded-[16px] ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border'}`}
                                    onClick={() => handleNext(item)}
                                >
                                    {/* <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    /> */}
                                    <button className='dreamclr text-white font-medium rounded-full absolute px-5 py-1 ' onClick={() => handleNext(item)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        )}
                    </div>
                </>
            )}

            {currentPage === 13 && (
                <>
                    <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                        Looking for a simple chat? Try our Basic AI Boyfriend. Want a deeper connection? Choose the Advanced AI Boyfriend for a more personalized experience.
                    </p>
                    <div className='bg-gray-500 flex items-center justify-between rounded-xl mt-[40px] mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38'>
                        <div className='text-xl xl:text-[25px] text-white font-medium text-center'>
                            <span>{selectedOptions.length} /</span>
                            <span className='text-red-600'>
                                3
                                <span className='inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800'></span>
                            </span>
                            <span>Choose Hobbies (You can choose up to 3 options)</span>
                        </div>
                        <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button>
                    </div>

                    <div>
                        <div className='flex flex-wrap justify-center gap-2 lg:gap-5 mt-5'>
                            {[
                                'Painting/Drawing', 'Photography', 'Writing (poetry, fiction, journaling)', 'Knitting/Crocheting', 'Sewing', 'Jewelry Making', 'Pottery/Ceramics',
                                'Calligraphy', 'Scrapbooking', 'Baking/Cooking', 'Yoga', 'Dancing (ballet, hip-hop, salsa, etc.)', 'Hiking/Camping', 'Running/Jogging',
                                'Cycling', 'Swimming', 'Gardening', 'Rock Climbing', 'Reading', 'Learning a new language', 'Playing a musical instrument',
                                'Astronomy', 'History', 'Stamps/Coins', 'Antiques', 'Books', 'Vinyl Records', 'Traveling', 'Volunteering', 'Blogging',
                                'Birdwatching', 'Learning a new skill (coding, woodworking, etc.)', 'Meditation', 'Playing board games/card games',
                                'Stargazing', 'DIY projects', 'Learning about different cultures', 'Genealogy', 'Interior design', 'Fashion', 'Watching movies', 'Going to festivals'
                            ].map(option => (
                                <div
                                    key={option}
                                    className={`w-60 h-20 md:h-15 m-2 justify-center flex items-center gap-2 px-4 py-2 border-2 bg-[#6161614A] 
                        ${selectedOptions.includes(option) ? 'bg-[#FF00133D] border-red-700 text-red-500' : ''} 
                        border-zinc-600 text-white text-lg rounded-[10px] hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] transition-shadow duration-300`}
                                    onClick={() => handleOptionSelect(option)}
                                >
                                    <p className='text-center'>{option}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}


            {currentPage === 14 && (
                <>
                    <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                        Looking for a simple chat? Try our Basic AI Girlfriend. Want a deeper connection? Choose the Advanced AI Girlfriend for a more personalized experience.                    </p>
                    <div className='bg-gray-500 flex items-center justify-between rounded-xl mt-[40px] mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38'>
                        <div className='text-xl xl:text-[25px] text-white font-medium text-center'>
                            <span>14 /</span>
                            <span className='text-red-600'>
                                15
                                <span className='inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800'></span>
                            </span>
                            <span> Name Your Girlfriend</span>
                        </div>
                        <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button>
                    </div>

                    <div className=''>
                        <div className='bg-gray-700 rounded-xl mt-2 px-5 py-3 text-black text-2xl font-light w-auto h-38'>
                            <input
                                type="text"
                                className='focus:outline-none text-white bg-gray-700 w-full'
                                name='gfname'
                                placeholder='Beautiful'
                                value={inputValue}
                                onChange={handleInputChange}
                            />
                        </div>
                        <p className='my-5'>Name Suggestions:</p>
                        <div className='flex justify-center'>
                            <button
                                className='w-80 bg-red-700 text-white px-5 py-2 rounded mt-[100px]'
                                onClick={Generategf}
                            >
                                Bring my Girlfriend to life
                            </button>
                        </div>
                    </div>
                </>
            )}

            {currentPage === 15 && (
                <>
                    <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                        Looking for a simple chat? Try our Basic AI Girlfriend. Want a deeper connection? Choose the Advanced AI Girlfriend for a more personalized experience.                    </p>
                    <div className='bg-gray-500 flex items-center justify-between rounded-xl mt-[40px] mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38'>
                        <div className='text-xl xl:text-[25px] text-white font-medium text-center'>
                            <span>15 /</span>
                            <span className='text-red-600'>
                                15
                                <span className='inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800'></span>
                            </span>
                            <span> Summary</span>
                        </div>
                        {/* <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button> */}
                    </div>

                    <div className=''>
                        <div className="flex flex-col xl:flex-row justify-center items-center xl:items-start">
                            {/* Image Section */}
                            <img
                                src={generatedimg}
                                alt="generatedimg"
                                className="w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover mb-6 xl:mb-0"
                            />

                            {/* Profile Section */}
                            <div className="xl:ml-[80px]">
                                <h1 className="text-2xl text-[#99000b] mb-4 text-center xl:text-left">Character Profile</h1>
                                <ul>
                                    {Object.entries(gfData).map(([key, value]) => (
                                        <li key={key} className="mb-0">
                                            <div className="flex flex-row text-black mb-2">
                                                <div className="w-full xl:w-[10rem] mb-2 xl:mb-0">
                                                    <h3 className="mr-2"><strong>{value.tab}:</strong></h3>
                                                </div>
                                                <div className="flex">
                                                    <span>{value.title}</span>
                                                </div>
                                                {value.color && (
                                                    <div className="mt-0 xl:mt-0">
                                                        <span className="ml-1">({value.color})</span>
                                                    </div>
                                                )}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className='flex justify-center'>
                            <button
                                className='w-80 bg-red-700 text-white px-5 py-2 rounded mt-[70px] mb-[80px]'
                                onClick={Chatpage}
                            >
                                Chat Now
                            </button>
                        </div></div>
                </>
            )}
        </>
    );
};

export default AIGirlfriend;
