import React, { useEffect, useState } from 'react';
import { AiFillLike } from 'react-icons/ai';
import { FaHeart } from 'react-icons/fa6';
import axios from 'axios';
import { IoIosTime } from 'react-icons/io';
import { useNavigate } from "react-router-dom";
import {
    img1, img3, img4, img7, pg12, pg22, pg23, pg24, pg25, pg26, brown1, AURORA1, AURORA2, ELORA1, ELORA2, LISA1, LISA2, MYRA1, MYRA2, NOLA1, NOLA2, RHEA1, RHEA2, YESENIA1, YESENIA2, ZEMIRA1, ZEMIRA2, ZULEIKA1, ZULEIKA2, GIANINA1, GIANINA2, whitebg, Bodyicon,
    Bodyback, Relationshipicon, Location, age, brest, country, eyes, hair, occupation, manbody, personality, abrafo1, abrafo2, akshat1, akshat2, brian1, brian2,
    charles1, charles2, daniel1, daniel2, joseph1, joseph2, joshua1, joshua2, matthew1, matthew2, thomas1, thomas2, william1, william2, ashley1,
    ashley2, avni1, avni2, emma1, emma2, grace1, grace2, hannah1, hannah2, izumi1, izumi2, nailah1, nailah2, olivia1, olivia2, sarah1, sarah2,
    sophia1, sophia2, CONNOR1, CONNOR2, JACK1, JACK2, LUKE1, LUKE2, ETHAN1, ETHAN2, OZAN1, OZAN2, JUNG1, JUNG2, HORO1, HORO2, ANDREW1, ANDREW2, JACOB1, JACOB2,
    HENRY1, HENRY2,
} from '../../assets';
import Characters from "./LandingPage/Characters";
import OurcommunitySection from "./LandingPage/OurcommunitySection";
import Accordion from "./LandingPage/Accordion";
import Blogs from "./LandingPage/blogs";
import Footer from "../components/Footer";
import LandingPopup from "./LandingPopup";


const LandingPage = ({ activeTab, handleActiveTab }) => {

    const [selected, setSelected] = useState('Saved');
    const [Userlogger, setUserlogger] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modaldata, setModaldata] = useState('');
    const [modalImg, setModalImg] = useState("");
    const [liked, setLiked] = useState(false);
    const [activeTabani, setActiveTabani] = useState(1);
    const [LikesCount, setLikesCount] = useState(0);

    // let LikesCount=0;

    const openModal = async (assistant_id) => {
        if (!assistant_id) {
            console.error("assistant_id is required");
            return;
        }


        // if (response.data[0].gender === 'male') {
        //     setAssistants(imgboydata);
        // } 
        // else if (response.data[0].gender === 'female' && response.data[0].OtherDetails.style === 'Realistic')
        //      { setAssistants(imgdata); }
        // else if (response.data[0].gender === 'male_v2') 
        //     { setAssistants(imgboydataani); }
        // else if (response.data[0].gender === 'male_v1')
        //      { setAssistants(imgboydataani); }
        // else if (response.data[0].gender === 'female' && response.data[0].OtherDetails.style === 'Anime_static') 
        //     { setAssistants(imgdataanime); }
        let selectedImage = null;
        if (imgdataanime.some(item => item.assistant_id === assistant_id)) {
            selectedImage = imgdataanime.find(item => item.assistant_id === assistant_id);
        } else if (imgdata.some(item => item.assistant_id === assistant_id)) {
            selectedImage = imgdata.find(item => item.assistant_id === assistant_id);
        } else if (imgboydata.some(item => item.assistant_id === assistant_id)) {
            selectedImage = imgboydata.find(item => item.assistant_id === assistant_id);
        }
        else if (imgboydataani.some(item => item.assistant_id === assistant_id)) {
            selectedImage = imgboydataani.find(item => item.assistant_id === assistant_id);
        }
        if (selectedImage) {
            setModalImg(selectedImage.src);
        }

        const payload = { assistant_id };
        try {
            const response = await axios.get(`https://api2.eromantic.ai/assistant_api/fetch_static_data/`, {
                params: payload,
            });

            if (response.status !== 200) {
                throw new Error('Network response was not ok');
            }

            const data = response.data;
            console.log(data, "datadatadatadatadata")
            setModaldata(data)
            setLikesCount(data.likes);
            // Process data as needed here
            setIsModalOpen(true);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };



    const closeModal = () => setIsModalOpen(false);
    const navigate = useNavigate();
    const imgdata = [
        { src: AURORA1, hoverSrc: AURORA2, name: "AURORA", age: "28 Years", assistant_id: "asst_OGGFo9rnAEPIuMU37eHs80DP", content: "Unraveling emotions, one thread at a time. Looking for a partner who can keep up with my analytical mind and adventurous spirit." },
        { src: ELORA1, hoverSrc: ELORA2, name: "Elora", age: "22 Years", assistant_id: "asst_Ti4jo2EGinj4JUr9jZ4QlWTo", content: "I'm a hopeless romantic with a practical side. I love tending to my garden, losing myself in a good book, and gazing at the stars." },
        { src: LISA1, hoverSrc: LISA2, name: "Lisa", age: "21 Years", assistant_id: "asst_L8ZcBr21o3Ekjubc2MW6aTMD", content: "I turn canvases into dreams and words into worlds. Ready to add a splash of color to your life?" },
        { src: MYRA1, hoverSrc: MYRA2, name: "Myra", age: "19 Years", assistant_id: "asst_OczH4KGWzPMDgzLP6xtFG0GO", content: "Musician, dancer, and world traveler. Looking for someone to share adventures and good vibes with" },
        { src: NOLA1, hoverSrc: NOLA2, name: "Nola", age: "31 Years", assistant_id: "asst_uyNEMKqzsyWVfmyPjmBkVi0J", content: "Gamer, coder, and a heart of gold. Looking for someone to level up with." },
        { src: RHEA1, hoverSrc: RHEA2, name: "Rhea", age: "20 Years", assistant_id: "asst_J5AJQusKdRLaIj1XvVquhDbQ", content: "Looking for someone to share cozy nights, starry skies, and creative projects with. Let's build something beautiful together." },
        { src: YESENIA1, hoverSrc: YESENIA2, name: "Yesenia", assistant_id: "asst_I085SetTJBARA2124cWWzqk9", age: "26 Years", content: "I'm not just a nerd with a lab coat. I'm also a nature lover, stargazer, and jewelry artist. Looking for someone to share my passions with." },
        { src: ZEMIRA1, hoverSrc: ZEMIRA2, name: "Zemira", assistant_id: "asst_YqKR18n7ZJ0gW9HjJzYdRe1k", age: "35 Years", content: "From house hunting to heart hunting, I've got you covered. Love running, reading, and crafting. Let's build a cozy home together." },
        { src: ZULEIKA1, hoverSrc: ZULEIKA2, name: "Zuleika", assistant_id: "asst_vJGcjDn66XnBKNm6kyzAAoSz", age: "20 Years", content: "Looking for my knight in shining armor or at least someone who can beat me at a game of Catan. Nurse by day, history buff and blogger by night. Let's build a cozy kingdom together." },
    ];
    const imgdataanime = [
        {
            src: ashley1, hoverSrc: ashley2, name: "Ashley", age: "28 Years", assistant_id: "asst_fnp30F4VmVCjEYDafnDFuAB8",
            content: "Unraveling emotions, one thread at a time. Looking for a partner who can keep up with my analytical mind and adventurous spirit."
        },
        {
            src: avni1, hoverSrc: avni2, name: "Avni", age: "22 Years", assistant_id: "asst_lCQq3kvopBvRHs3Bxzd2LJJW",
            content: "I'm a hopeless romantic with a practical side. I love tending to my garden, losing myself in a good book, and gazing at the stars."
        },
        {
            src: emma1, hoverSrc: emma2, name: "Emma", age: "21 Years", assistant_id: "asst_13Fe5pJ0MWOLOTrY5r6akPUM",
            content: "I turn canvases into dreams and words into worlds. Ready to add a splash of color to your life?"
        },
        { src: grace1, hoverSrc: grace2, name: "Grace", age: "19 Years", assistant_id: "asst_Ao68dLUiNhZpb5GEdrcyThCA", content: "Musician, dancer, and world traveler. Looking for someone to share adventures and good vibes with." },
        {
            src: hannah1, hoverSrc: hannah2, name: "Nola", age: "31 Years",
            assistant_id: "asst_wX85ThfVVHWCeeH6elCCKuql", content: "Gamer, coder, and a heart of gold. Looking for someone to level up with."
        },
        {
            src: izumi1, hoverSrc: izumi2, name: "Rhea", age: "20 Years", assistant_id: "asst_RtAXqeQfNYKirPj23b1WMoKd",
            content: "Looking for someone to share cozy nights, starry skies, and creative projects with. Let's build something beautiful together."
        },
        {
            src: nailah1, hoverSrc: nailah2, name: "Yesenia", age: "26 Years", assistant_id: "asst_lYAgEm65IPFEOanzcaY8Y07R",
            content: "I'm not just a nerd with a lab coat. I'm also a nature lover, stargazer, and jewelry artist. Looking for someone to share my passions with."
        },
        {
            src: olivia1, hoverSrc: olivia2, name: "Zemira", age: "35 Years", assistant_id: "asst_cL6bVyUQSbB5oLAVJI2hSJqV",
            content: "From house hunting to heart hunting, I've got you covered. Love running, reading, and crafting. Let's build a cozy home together."
        },
        {
            src: sarah1, hoverSrc: sarah2, name: "Zuleika", age: "20 Years", assistant_id: "asst_wQl54haWnxvy1VCAZeMsq1yr",
            content: "Looking for my knight in shining armor or at least someone who can beat me at a game of Catan. Nurse by day, history buff and blogger by night. Let's build a cozy kingdom together."
        }
    ];


    const imgboydata = [
        { src: william1, hoverSrc: william2, name: "William", age: "31 Years", assistant_id: "asst_2Fh2n9ZzVcb9GfwIU1yQnviP", content: "A nurturing real estate agent with a love for running and creativity, let's explore our dreams together while making unforgettable memories filled with fun and intimacy." },
        { src: abrafo1, hoverSrc: abrafo2, name: "Abrafo", age: "28 Years", assistant_id: "asst_7T7S0pBDD65XtdXDTjj4loch", content: "An artist at heart and a peacemaker by nature, let's ignite our passions and explore our wildest desires together, creating unforgettable memories." },
        { src: akshat1, hoverSrc: akshat2, name: "Akshat", age: "25 Years", assistant_id: "asst_hca01x1mT37Tk2aPn5XEdyfy", content: "With the precision of a data analyst and the curiosity of a traveler, I'm on a quest to decode the wonders of life." },
        { src: brian1, hoverSrc: brian2, name: "Brian", age: "26 Years", assistant_id: "asst_WuMAwtO9AOcJwNAlK3ckiWFE", content: "Equipped with the skill of an electrician and the curiosity of a historian, I'm here to illuminate life's stories." },
        { src: charles1, hoverSrc: charles2, name: "Charles", age: "29 Years", assistant_id: "asst_pf3MT9dygawukbAcfLdvfEe5", content: "With the wisdom of a lawyer and the curiosity of a historian, I'm ready to uncover life's narratives." },
        { src: daniel1, hoverSrc: daniel2, name: "Daniel", age: "30 Years", assistant_id: "asst_nA9EyllxUdEQ3rzGpNMGPWE8", content: "A visionary accountant with a passion for stargazing, I'm here to lead our adventures and ignite our nights." },
        { src: joseph1, hoverSrc: joseph2, name: "Joseph", age: "24 Years", assistant_id: "asst_5UiZUpcmSRsQWmRpvhQ5nV2c", content: "A game-loving accountant with a passion for history, let's cozy up and explore our shared adventures." },
        { src: joshua1, hoverSrc: joshua2, name: "Joshua", age: "26 Years", assistant_id: "asst_9DWADrDHTkP3uOVLYGhMwx5Y", content: "With the heart of a romantic and the insight of a counselor, I'm here to explore the beauty of life's connections." },
        { src: matthew2, hoverSrc: matthew1, name: "Matthew", age: "34 Years", assistant_id: "asst_izcamm3cLwOwWcBSgpj5Gybn", content: "A romantic free spirit with a heart for service, I'm here to explore life's wonders together." },
        { src: thomas1, hoverSrc: thomas2, name: "Thomas", age: "30 Years", assistant_id: "asst_v5yATNwrbIp1yB4l4h3pAqAL", content: "Mind like a scientist, heart like an adventurer. Ready to explore life's mysteries with someone who dares to keep up." },
    ];
    const imgboydataani = [
        { src: CONNOR1, hoverSrc: CONNOR2, name: "Connor", age: "24 Years", assistant_id: "asst_ftGmmK7lD635qFx9shcL86y8", content: "An adventurer with a heart full of love and a head full of dreams—let’s stitch memories together, one wild, tender moment at a time." },
        { src: JACK1, hoverSrc: JACK2, name: "Jack", age: "28 Years", assistant_id: "asst_eNvf957PCjaqz6XiDyvVRpRs", content: "An adventurer with a radiant soul, let’s blaze trails together—through mountains, dreams, and every unforgettable moment in between." },
        { src: LUKE1, hoverSrc: LUKE2, name: "Luke", age: "32 Years", assistant_id: "asst_ToII8hAldzyu3LommepRRZNm", content: "A lover of life and laughter—let’s make every moment a picture-perfect adventure, diving deep into fun, passion, and unforgettable memories." },
        { src: ETHAN1, hoverSrc: ETHAN2, name: "Ethan", age: "23 Years", assistant_id: "asst_SIhnX2bdvCmKL6EUSWZOAzSf", content: "A dreamer with a fiery heart—let’s stargaze, create stories, and build a life full of passion, peace, and unshakable love." },
        { src: OZAN1, hoverSrc: OZAN2, name: "Ozan", age: "29 Years", assistant_id: "asst_dmbKYvi7nrK6szoj6CEBfuf0", content: "Let’s rewrite the rules together—bold adventures, soulful melodies, and a love so fierce, it turns every moment into an unforgettable journey." },
        { src: JUNG1, hoverSrc: JUNG2, name: "Jung", age: "30 Years", assistant_id: "asst_uuXikNax7Re62ZZoyebcVtHf", content: "Soft-spoken yet strong—a partner who nurtures love like a garden, treasures history like an antique, and keeps life beautifully balanced." },
        { src: HORO1, hoverSrc: HORO2, name: "Horo", age: "34 Years", assistant_id: "asst_vVFkL1a8U6fbdLQsG9G0SS7o", content: "Rule-breaker, justice-seeker, and all-around firestarter—Horo is here to flip the script, spark rebellion, and make every moment unforgettable." },
        { src: ANDREW1, hoverSrc: ANDREW2, name: "Andrew", age: "25 Years", assistant_id: "asst_bcK6d8mxZyVTGUqurOTpGOa4", content: "Festivals, meditation, and heart-to-heart talks—Andrew’s the best friend who’s always down to party or pause for a life chat." },
        { src: JACOB1, hoverSrc: JACOB2, name: "Jacob", age: "38 Years", assistant_id: "asst_0I4DikgoGToat0Ib7lnyH4yM", content: "A creative soul with a heart of gold and a mind full of wonders—let’s craft a love as timeless and beautiful as art." },
        { src: HENRY1, hoverSrc: HENRY2, name: "Henry", age: "32 Years", assistant_id: "asst_8IDqJpOnQmtgxJFk969KiKc9", content: "An introspective soul with a cosmic mind and a romantic heart, let's write our love story in the stars and the soil." },
    ];


    const BASE_URL = "https://api2.eromantic.ai/assistant_api/";

    const imgdatamob = [
        { src: AURORA1, hoverSrc: AURORA2, name: "AURORA", age: "28 Years", assistant_id: "asst_OGGFo9rnAEPIuMU37eHs80DP", content: "Unraveling emotions, one thread at a time. Looking for a partner who can keep up with my analytical mind and adventurous spirit." },
        { src: ELORA1, hoverSrc: ELORA2, name: "Elora", age: "22 Years", assistant_id: "asst_Ti4jo2EGinj4JUr9jZ4QlWTo", content: "I'm a hopeless romantic with a practical side. I love tending to my garden, losing myself in a good book, and gazing at the stars." },
        { src: LISA1, hoverSrc: LISA2, name: "Lisa", age: "21 Years", assistant_id: "asst_L8ZcBr21o3Ekjubc2MW6aTMD", content: "I turn canvases into dreams and words into worlds. Ready to add a splash of color to your life?" },
        { src: MYRA1, hoverSrc: MYRA2, name: "Myra", age: "19 Years", assistant_id: "asst_OczH4KGWzPMDgzLP6xtFG0GO", content: "Musician, dancer, and world traveler. Looking for someone to share adventures and good vibes with" },
        { src: NOLA1, hoverSrc: NOLA2, name: "Nola", age: "31 Years", assistant_id: "asst_uyNEMKqzsyWVfmyPjmBkVi0J", content: "Gamer, coder, and a heart of gold. Looking for someone to level up with." },
        { src: RHEA1, hoverSrc: RHEA2, name: "Rhea", age: "20 Years", assistant_id: "asst_J5AJQusKdRLaIj1XvVquhDbQ", content: "Looking for someone to share cozy nights, starry skies, and creative projects with. Let's build something beautiful together." },
        { src: YESENIA1, hoverSrc: YESENIA2, name: "Yesenia", assistant_id: "asst_I085SetTJBARA2124cWWzqk9", age: "26 Years", content: "I'm not just a nerd with a lab coat. I'm also a nature lover, stargazer, and jewelry artist. Looking for someone to share my passions with." },
        { src: ZEMIRA1, hoverSrc: ZEMIRA2, name: "Zemira", assistant_id: "asst_YqKR18n7ZJ0gW9HjJzYdRe1k", age: "35 Years", content: "From house hunting to heart hunting, I've got you covered. Love running, reading, and crafting. Let's build a cozy home together." },
        { src: ZULEIKA1, hoverSrc: ZULEIKA2, name: "Zuleika", assistant_id: "asst_vJGcjDn66XnBKNm6kyzAAoSz", age: "20 Years", content: "Looking for my knight in shining armor or at least someone who can beat me at a game of Catan. Nurse by day, history buff and blogger by night. Let's build a cozy kingdom together." },
    ];
    const imgdatamobanime = [
        {
            src: ashley1, hoverSrc: ashley2, name: "Ashley", age: "28 Years", assistant_id: "asst_fnp30F4VmVCjEYDafnDFuAB8",
            content: "Unraveling emotions, one thread at a time. Looking for a partner who can keep up with my analytical mind and adventurous spirit."
        },
        {
            src: avni1, hoverSrc: avni2, name: "Avni", age: "22 Years", assistant_id: "asst_lCQq3kvopBvRHs3Bxzd2LJJW",
            content: "I'm a hopeless romantic with a practical side. I love tending to my garden, losing myself in a good book, and gazing at the stars."
        },
        {
            src: emma1, hoverSrc: emma2, name: "Emma", age: "21 Years", assistant_id: "asst_13Fe5pJ0MWOLOTrY5r6akPUM",
            content: "I turn canvases into dreams and words into worlds. Ready to add a splash of color to your life?"
        },
        { src: grace1, hoverSrc: grace2, name: "Grace", age: "19 Years", assistant_id: "asst_Ao68dLUiNhZpb5GEdrcyThCA", content: "Musician, dancer, and world traveler. Looking for someone to share adventures and good vibes with." },
        {
            src: hannah1, hoverSrc: hannah2, name: "Nola", age: "31 Years",
            assistant_id: "asst_wX85ThfVVHWCeeH6elCCKuql", content: "Gamer, coder, and a heart of gold. Looking for someone to level up with."
        },
        {
            src: izumi1, hoverSrc: izumi2, name: "Rhea", age: "20 Years", assistant_id: "asst_RtAXqeQfNYKirPj23b1WMoKd",
            content: "Looking for someone to share cozy nights, starry skies, and creative projects with. Let's build something beautiful together."
        },
        {
            src: nailah1, hoverSrc: nailah2, name: "Yesenia", age: "26 Years", assistant_id: "asst_lYAgEm65IPFEOanzcaY8Y07R",
            content: "I'm not just a nerd with a lab coat. I'm also a nature lover, stargazer, and jewelry artist. Looking for someone to share my passions with."
        },
        {
            src: olivia1, hoverSrc: olivia2, name: "Zemira", age: "35 Years", assistant_id: "asst_cL6bVyUQSbB5oLAVJI2hSJqV",
            content: "From house hunting to heart hunting, I've got you covered. Love running, reading, and crafting. Let's build a cozy home together."
        },
        {
            src: sarah1, hoverSrc: sarah2, name: "Zuleika", age: "20 Years", assistant_id: "asst_wQl54haWnxvy1VCAZeMsq1yr",
            content: "Looking for my knight in shining armor or at least someone who can beat me at a game of Catan. Nurse by day, history buff and blogger by night. Let's build a cozy kingdom together."
        }
    ]
    const images1 = [pg12, pg22, pg23, brown1, pg25, pg26, pg12, pg22, pg23, brown1];

    const CreateDreamGF = () => {
        const token = localStorage.getItem('token');
        navigate(token ? "/CreateDreamGF" : "/login");
    };
    const Chatnow = (id) => {
        // StaticGenerate(id);
        const token = localStorage.getItem('token');
        navigate(token ? `/AidreamGFchats/${id}` : "/login");
    };

    const StaticGenerate = (id) => {
        const extractedPart = "asst_" + id.substring(id.lastIndexOf('/') + 1);
        const u_id = localStorage.getItem('u_id');
        const payload = { assistant_id: extractedPart, u_id: u_id };

        try {
            const response = axios.post(`${BASE_URL}static_chat/`, payload);
            console.log("1111111111sssssss", response.data[0]);

            if (response.status === 200 && response.data[0].name) {
                const responseName = response.data[0].name.toUpperCase();
                let matchedImage = null;

            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const CreateDreamBf = () => {
        const token = localStorage.getItem('token');
        navigate(token ? "/BoyFriend" : '/login');
    };

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [timeLeft, setTimeLeft] = useState(3600); // 1 hour in seconds

    const handleClosePopup = () => {
        window.location.reload();
        setShowPopup(false);
    };

    useEffect(() => {
        const checkloger = localStorage.getItem('hasVisited');
        if (checkloger === "true") {
            setIsLoggedIn(true);
            setTimeLeft(3600); // Reset the timer
        }
        let timer;
        if (isLoggedIn) {
            timer = setInterval(() => {
                setTimeLeft((prevTime) => {
                    if (prevTime <= 1) {
                        clearInterval(timer);
                        alert("You have been logged out due to inactivity.");
                        setIsLoggedIn(false);
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [isLoggedIn]);

    useEffect(() => {
        setUserlogger(localStorage.getItem('login'));
        const hasVisited = localStorage.getItem("hasVisited");
        if (!hasVisited) {
            setShowPopup(true);
            localStorage.setItem("hasVisited", "true");
            const timer = setTimeout(() => {
                localStorage.removeItem("hasVisited");
            }, 3600000);
            return () => clearTimeout(timer);
        }
    }, []);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };

    const toggleLike = async (assistant_id) => {
        const u_id = localStorage.getItem('u_id');
        const payload = { assistant_id: assistant_id , u_id: u_id };

        try {
            const response = await axios.post(`${BASE_URL}like_static/`, payload);

            console.log("1111111111sssssss", response.data[0]);

            if (response.status === 200) {
                setLiked(!liked);
                console.log(response.data,"asasddasasd")

                setLikesCount(response.data.likes);
               
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        // LikesCount+=1;
        // setLiked(!liked);
        

    };

    const handleTabClick = (tabIndex) => {
        setActiveTabani(tabIndex);
    };
    const handleTabClickboy = (tabIndex) => {
        setActiveTabani(tabIndex);
    };

    const itemsToDisplay = (modaldata.Butt_Size === '' || modaldata.Butt_Size === 'NA') &&
        (modaldata.Breast_Size === '' || modaldata.Breast_Size === 'NA')
        ? [
            { label: 'Relationship:', value: modaldata.Relationship, src: Relationshipicon },
            { label: 'Age:', value: modaldata.age, src: age },
            { label: 'Occupation:', value: modaldata.Occupation, src: occupation },
            { label: 'Personality:', value: modaldata.Personality, src: personality },
            { label: 'Hair Style and Color:', value: `${modaldata.hair_Style} ${modaldata.hair_color}`, src: hair },
            { label: 'Eyes:', value: modaldata.Eyes, src: eyes },
            { label: 'Ethnicity:', value: modaldata.Ethnicity, src: Location },
            { label: 'Region:', value: modaldata.region, src: country },
            { label: 'Body Type:', value: modaldata.Body_Type, src: manbody }
        ]
        : [
            { label: 'Relationship:', value: modaldata.Relationship, src: Relationshipicon },
            { label: 'Age:', value: modaldata.age, src: age },
            { label: 'Occupation:', value: modaldata.Occupation, src: occupation },
            { label: 'Personality:', value: modaldata.Personality, src: personality },
            { label: 'Hair Style and Color:', value: `${modaldata.hair_Style} ${modaldata.hair_color}`, src: hair },
            { label: 'Eyes:', value: modaldata.Eyes, src: eyes },
            { label: 'Ethnicity:', value: modaldata.Ethnicity, src: Location },
            { label: 'Region:', value: modaldata.region, src: country },
            { label: 'Body Type:', value: modaldata.Body_Type, src: Bodyicon },
            { label: 'Breast Size:', value: modaldata.Breast_Size, src: brest },
            { label: 'Butt Size:', value: modaldata.Butt_Size, src: Bodyback }
        ];
    return (
        <>
            <div className='px-2 xl:px-10 pb-5 text-white font-josefin-sans'>
                <div className="bg-gradient-to-r from-red-600 h-20 px-10 to-white w-full flex justify-between items-center">
                    <div className='text-xl lg:text-2xl'>First Subscription</div>
                    <div className='border-2 px-5 border-[#99000B] rounded-full p-2 transition-colors duration-300 bg-[#99000B] text-white'>Up to 70% OFF</div>
                    <div className='border-2 px-5 border-[#807D7D] rounded-full p-2 transition-colors duration-300 bg-[#807D7D] text-white'>{formatTime(timeLeft)}:00</div>
                </div>

                {
                    activeTab === "Girlfriend" ? (
                        <>
                            <div className="carousel-container">
                                <div className='flex items-center mt-[3rem] lg:mt-5 mb-10 w-full relative carousel-item'>
                                    <img src="/assets/img/Rectangledream.png" alt="Icon" className="w-full h-auto" />
                                    <div className='absolute right-0'>
                                        <div className='grid justify-center p-4 mr-4 sm:mr-6 lg:mr-10'>
                                            <div className='text-xl sm:text-1xl lg:text-3xl xl:text-5xl font-medium text-center'>
                                                Create your own <span className='text-red-500'>Dream Girlfriend</span>
                                            </div>
                                            <div className='text-center mt-1 lg:mt-10'>
                                                <button className="bg-red-800 text-white font-medium rounded-2xl p-2 sm:p-4 lg:px-6 py-1" onClick={CreateDreamGF}>
                                                    Create Your Dream Girlfriend
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Add more carousel items here --> */}
                                <div className='flex items-center mt-[7rem] lg:mt-5 mb-10 w-full relative carousel-item justify-center'>
                                    <img src="/assets/img/Rectangledream.png" alt="Icon" className="w-full h-auto" />
                                    <div className='absolute text-center'>
                                        <div className='grid justify-center p-4 mr-4 sm:mr-6 lg:mr-10'>
                                            <div className='text-xl sm:text-xl lg:text-2xl xl:text-5xl font-medium text-center'>
                                                Create your own <span className='text-red-500'>Dream Girlfriend</span>
                                            </div>
                                            <div className='text-center mt-1 lg:mt-10'>
                                                <button className="bg-red-800 text-white font-medium rounded-2xl p-2 sm:p-4 lg:px-10 lg:py-3" onClick={CreateDreamGF}>
                                                    Create Your Dream Girlfriend
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex items-center mt-[2rem] lg:mt-5 mb-10 w-full relative justify-center'>
                                <img src="/assets/img/Rectangledream.png" alt="Icon" className="w-full h-auto rounded-xl" />
                                <div className='absolute text-center'>
                                    <div className='grid justify-center p-4 mr-4 sm:mr-6 lg:mr-10'>
                                        <div className='text-xl sm:text-xl lg:text-2xl xl:text-5xl font-medium text-center'>
                                            Create your own <span className='text-red-500'>Dream Girlfriend</span>
                                        </div>
                                        <div className='text-center mt-1 lg:mt-10'>
                                            <button className="bg-red-800 text-white font-medium rounded-2xl p-2 sm:p-4 lg:px-6 py-1" onClick={CreateDreamGF}>
                                                Create Your Dream Girlfriend
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='text-xl lg:text-2xl lg:my-4 mt-0 font-bold lg:py-4 py-0 text-black text-center'>Meet our Best <span className='text-[#991b1b]'>AI GirlFriends</span></div>

                            <div className="flex justify-center space-x-4">
                                <button
                                    onClick={() => handleTabClick(1)}
                                    className={`px-4 py-2 w-[25%] font-semibold text-lg rounded-tl-lg rounded-bl-lg transition-colors duration-300 ${activeTabani === 1 ? 'bg-[#991b1b] text-white' : 'bg-gray-200 text-gray-600'
                                        }`}
                                >
                                    Realistic
                                </button>
                                <button
                                    onClick={() => handleTabClick(2)}
                                    style={{ margin: "0" }}
                                    className={`px-4 py-2 w-[25%] font-semibold text-lg rounded-tr-lg rounded-br-lg transition-colors duration-300 ${activeTabani === 2 ? 'bg-[#991b1b] text-white' : 'bg-gray-200 text-gray-600'
                                        }`}
                                >
                                    Anime
                                </button>
                            </div>

                            <div className="mt-4">
                                {activeTabani === 1 && (
                                    <div className='fordesk grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-5 gap-4'>
                                        {imgdata.map((item, index) => (
                                            <div
                                                key={index}
                                                className='relative'
                                                onMouseEnter={() => setHoveredIndex(index)}
                                                onMouseLeave={() => setHoveredIndex(null)}
                                            >
                                                <img
                                                    src={hoveredIndex === index ? item.hoverSrc : item.src}
                                                    alt={`Image of ${item.name}`}
                                                    className={`h-80 w-full xl:h-[500px] rounded-xl object-cover transition-transform duration-700 ease-in-out ${hoveredIndex === index ? 'scale-105' : ''}`}
                                                />
                                                <div onClick={() => openModal(item.assistant_id)} className='absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-700'>
                                                    <div className='flex justify-end p-4'>
                                                        <button className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                                            <img src="/assets/img/Group-icon.png" onClick={() => Chatnow(item.assistant_id.replaceAll("asst_", ""))} alt="Icon" className="w-12 h-12" />
                                                        </button>
                                                    </div>
                                                    <div className='absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg'>
                                                        <div className='flex justify-between py-1'>
                                                            <span className="text-lg lg:text-lg font-medium">{item.name}</span>
                                                            <span className="text-lg lg:text-lg font-medium">{item.age}</span>
                                                        </div>
                                                        <p className='text-sm lg:text-base'>{(item.content).slice(0, 60)}...</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {
                                            Userlogger ?
                                                <div className='relative'>
                                                    <img src={GIANINA1} alt="img1" className='h-80 w-full xl:h-[500px] rounded-xl object-cover' />
                                                    <div className='absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-700'>
                                                        <div className='flex justify-end p-4'>
                                                            <button className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                                                <img onClick={() => openModal('asst_N9awtinfHzE6V63hmUR8TUOg')} src="/assets/img/Group-icon.png" alt="Icon" className="w-16 h-15" />
                                                            </button>
                                                        </div>
                                                        <div className='absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg'>
                                                            <div className='flex justify-between py-1'>
                                                                <span className="text-lg lg:text-lg font-medium">GIANINA</span>
                                                                <span className="text-lg lg:text-lg font-medium">40 Years</span>
                                                            </div>
                                                            <p className='text-sm lg:text-base'>{`I'm a data scientist with a passion for puns and a love for the outdoors. Looking for someone to rock climb with and laugh at my jokes...`.slice(0, 60)}...</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className='relative bg-[#990013] rounded-xl'>
                                                    {/* <img src={img1} alt="img1" className='h-80 w-full xl:h-[500px] rounded-xl object-cover' /> */}
                                                    <div className=' inset-0 flex flex-col duration-700 p-5'>
                                                        <div className='flex justify-center p-4'>
                                                            <i class="fa-solid fa-gift fa-5x"></i>
                                                        </div>
                                                        <div className='bg-opacity-70 my-10 text-white p-2 rounded-b-lg'>
                                                            <div className='flex flex-col gap-14 text-center py-1'>
                                                                <span className="text-lg lg:text-lg font-medium">Register to view more</span>
                                                                <span className="text-lg lg:text-lg font-medium">Registration is FREE!<br /> No cards/payments required<br />
                                                                    for the free trial.</span>
                                                            </div>
                                                        </div>
                                                        <div className='absolute bottom-0 left-0 right-0 text-center bg-opacity-70 text-black p-2 rounded-b-lg'>
                                                            <button className="bg-red-800 [w-75%] text-white font-medium rounded-2xl p-4" onClick={CreateDreamBf}>
                                                                Free Trial
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                )}
                                {activeTabani === 2 && (
                                    <div className='fordesk grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-5 gap-4'>
                                        {imgdataanime.map((item, index) => (
                                            <div
                                                key={index}
                                                className='relative'
                                                onMouseEnter={() => setHoveredIndex(index)}
                                                onMouseLeave={() => setHoveredIndex(null)}
                                            >
                                                <img
                                                    src={hoveredIndex === index ? item.hoverSrc : item.src}
                                                    alt={`Image of ${item.name}`}
                                                    className={`h-80 w-full xl:h-[500px] rounded-xl object-cover transition-transform duration-700 ease-in-out ${hoveredIndex === index ? 'scale-105' : ''}`}
                                                />
                                                <div onClick={() => openModal(item.assistant_id)} className='absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-700'>
                                                    <div className='flex justify-end p-4'>
                                                        <button className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                                            <img src="/assets/img/Group-icon.png" onClick={() => Chatnow(item.assistant_id.replaceAll("asst_", ""))} alt="Icon" className="w-12 h-12" />
                                                        </button>
                                                    </div>
                                                    <div className='absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg'>
                                                        <div className='flex justify-between py-1'>
                                                            <span className="text-lg lg:text-lg font-medium">{item.name}</span>
                                                            <span className="text-lg lg:text-lg font-medium">{item.age}</span>
                                                        </div>
                                                        <p className='text-sm lg:text-base'>{(item.content).slice(0, 60)}...</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {
                                            Userlogger ?
                                                <div className='relative'>
                                                    <img src={sophia1} alt="img1" className='h-80 w-full xl:h-[500px] rounded-xl object-cover' />
                                                    <div className='absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-700'>
                                                        <div className='flex justify-end p-4'>
                                                            <button className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                                                <img onClick={() => openModal('asst_lJmsOx1UCLjAyh44EXyXiogb')} src="/assets/img/Group-icon.png" alt="Icon" className="w-16 h-15" />
                                                            </button>
                                                        </div>
                                                        <div className='absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg'>
                                                            <div className='flex justify-between py-1'>
                                                                <span className="text-lg lg:text-lg font-medium">GIANINA</span>
                                                                <span className="text-lg lg:text-lg font-medium">40 Years</span>
                                                            </div>
                                                            <p className='text-sm lg:text-base'>{`I'm a data scientist with a passion for puns and a love for the outdoors. Looking for someone to rock climb with and laugh at my jokes...`.slice(0, 60)}...</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className='relative bg-[#990013] rounded-xl'>
                                                    {/* <img src={img1} alt="img1" className='h-80 w-full xl:h-[500px] rounded-xl object-cover' /> */}
                                                    <div className=' inset-0 flex flex-col duration-700 p-5'>
                                                        <div className='flex justify-center p-4'>
                                                            <i class="fa-solid fa-gift fa-5x"></i>
                                                        </div>
                                                        <div className='bg-opacity-70 my-10 text-white p-2 rounded-b-lg'>
                                                            <div className='flex flex-col gap-14 text-center py-1'>
                                                                <span className="text-lg lg:text-lg font-medium">Register to view more</span>
                                                                <span className="text-lg lg:text-lg font-medium">Registration is FREE!<br /> No cards/payments required<br />
                                                                    for the free trial.</span>
                                                            </div>
                                                        </div>
                                                        <div className='absolute bottom-0 left-0 right-0 text-center bg-opacity-70 text-black p-2 rounded-b-lg'>
                                                            <button className="bg-red-800 [w-75%] text-white font-medium rounded-2xl p-4" onClick={CreateDreamBf}>
                                                                Free Trial
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                )}
                            </div>

                            {activeTabani === 1 && (
                            <div className='formobile grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4'>
                            {/* <div className='formobile grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-5 gap-4'> */}
                                {
                                    imgdatamob.map((item, index) => (
                                        <div
                                        key={index}
                                        className='relative'
                                        onMouseEnter={() => setHoveredIndex(index)}
                                        onMouseLeave={() => setHoveredIndex(null)}
                                    >
                                        <img
                                            src={hoveredIndex === index ? item.hoverSrc : item.src}
                                            alt={`Image of ${item.name}`}
                                            className={`h-80 w-full xl:h-[500px] rounded-xl object-cover transition-transform duration-700 ease-in-out ${hoveredIndex === index ? 'scale-105' : ''}`}
                                        />
                                        <div onClick={() => openModal(item.assistant_id)} className='absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-700'>
                                            <div className='flex justify-end p-4'>
                                                <button className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                                    <img src="/assets/img/Group-icon.png" onClick={() => Chatnow(item.assistant_id.replaceAll("asst_", ""))} alt="Icon" className="w-12 h-12" />
                                                </button>
                                            </div>
                                            <div className='absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg'>
                                                <div className='flex justify-between py-1'>
                                                    <span className="text-lg lg:text-lg font-medium">{item.name}</span>
                                                    <span className="text-lg lg:text-lg font-medium">{item.age}</span>
                                                </div>
                                                <p className='text-sm lg:text-base'>{(item.content).slice(0, 60)}...</p>
                                            </div>
                                        </div>
                                    </div>
                                    ))
                                }
                                {
                                    Userlogger ?
                                        <div className='relative'>
                                            <img src={img4} alt="img1" className='h-80 w-full xl:h-[500px] rounded-xl object-cover' />
                                            <div className='absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-700'>
                                                <div className='flex justify-end p-4'>
                                                    <button className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                                        <img src="/assets/img/Group-icon.png" alt="Icon" className="w-16 h-15" />
                                                    </button>
                                                </div>
                                                <div className='absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg'>
                                                    <div className='flex justify-between py-1'>
                                                        <span className="text-lg lg:text-lg font-medium">Cataline</span>
                                                        <span className="text-lg lg:text-lg font-medium">29 Years</span>
                                                    </div>
                                                    <p className='text-sm lg:text-base'>Weather report anchor in Vienna.<br /> Full of herself, she loves the...</p>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className='relative bg-[#990013] rounded-xl'>
                                            <div className=' inset-0 flex flex-col duration-700 p-5'>
                                                <div className='flex justify-center p-4'>
                                                    <i class="fa-solid fa-gift fa-5x"></i>
                                                </div>
                                                <div className='bg-opacity-70 my-10 text-white p-2 rounded-b-lg'>
                                                    <div className='flex flex-col gap-14 text-center py-1'>
                                                        <span className="text-lg lg:text-lg font-medium">Register to view more</span>
                                                        <span className="text-lg lg:text-lg font-medium">Registration is FREE!<br /> No cards/payments required<br />
                                                            for the free trial.</span>
                                                    </div>
                                                </div>
                                                <div className='absolute bottom-0 left-0 right-0 text-center bg-opacity-70 text-black p-2 rounded-b-lg'>
                                                    <button className="bg-red-800 [w-75%] text-white font-medium rounded-2xl p-4" onClick={CreateDreamBf}>
                                                        Free Trial
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                }

                            </div>
                            )}
                            <div className='formobile grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4'>
                            {/* <div className='formobile grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-5 gap-4'> */}
                                {
                                    imgdatamobanime.map((item, index) => (
                                        <div
                                        key={index}
                                        className='relative'
                                        onMouseEnter={() => setHoveredIndex(index)}
                                        onMouseLeave={() => setHoveredIndex(null)}
                                    >
                                        <img
                                            src={hoveredIndex === index ? item.hoverSrc : item.src}
                                            alt={`Image of ${item.name}`}
                                            className={`h-80 w-full xl:h-[500px] rounded-xl object-cover transition-transform duration-700 ease-in-out ${hoveredIndex === index ? 'scale-105' : ''}`}
                                        />
                                        <div onClick={() => openModal(item.assistant_id)} className='absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-700'>
                                            <div className='flex justify-end p-4'>
                                                <button className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                                    <img src="/assets/img/Group-icon.png" onClick={() => Chatnow(item.assistant_id.replaceAll("asst_", ""))} alt="Icon" className="w-12 h-12" />
                                                </button>
                                            </div>
                                            <div className='absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg'>
                                                <div className='flex justify-between py-1'>
                                                    <span className="text-lg lg:text-lg font-medium">{item.name}</span>
                                                    <span className="text-lg lg:text-lg font-medium">{item.age}</span>
                                                </div>
                                                <p className='text-sm lg:text-base'>{(item.content).slice(0, 60)}...</p>
                                            </div>
                                        </div>
                                    </div>
                                    ))
                                }
                                {
                                    Userlogger ?
                                        <div className='relative'>
                                            <img src={img4} alt="img1" className='h-80 w-full xl:h-[500px] rounded-xl object-cover' />
                                            <div className='absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-700'>
                                                <div className='flex justify-end p-4'>
                                                    <button className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                                        <img src="/assets/img/Group-icon.png" alt="Icon" className="w-16 h-15" />
                                                    </button>
                                                </div>
                                                <div className='absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg'>
                                                    <div className='flex justify-between py-1'>
                                                        <span className="text-lg lg:text-lg font-medium">Cataline</span>
                                                        <span className="text-lg lg:text-lg font-medium">29 Years</span>
                                                    </div>
                                                    <p className='text-sm lg:text-base'>Weather report anchor in Vienna.<br /> Full of herself, she loves the...</p>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className='relative bg-[#990013] rounded-xl'>
                                            <div className=' inset-0 flex flex-col duration-700 p-5'>
                                                <div className='flex justify-center p-4'>
                                                    <i class="fa-solid fa-gift fa-5x"></i>
                                                </div>
                                                <div className='bg-opacity-70 my-10 text-white p-2 rounded-b-lg'>
                                                    <div className='flex flex-col gap-14 text-center py-1'>
                                                        <span className="text-lg lg:text-lg font-medium">Register to view more</span>
                                                        <span className="text-lg lg:text-lg font-medium">Registration is FREE!<br /> No cards/payments required<br />
                                                            for the free trial.</span>
                                                    </div>
                                                </div>
                                                <div className='absolute bottom-0 left-0 right-0 text-center bg-opacity-70 text-black p-2 rounded-b-lg'>
                                                    <button className="bg-red-800 [w-75%] text-white font-medium rounded-2xl p-4" onClick={CreateDreamBf}>
                                                        Free Trial
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                }

                            </div>
                            <Characters />
                        </>
                    ) : (
                        <>
                            <div className='flex items-center mt-[7rem] lg:mt-5 mb-10 w-full relative'>
                                <img src="/assets/img/boydream.png" alt="Icon" className="w-full h-auto rounded-xl" />
                                <div className='absolute right-0'>
                                    <div className='grid justify-center p-4 mr-4 sm:mr-6 lg:mr-10'>
                                        <div className='text-xl sm:text-1xl lg:text-3xl xl:text-5xl font-medium text-center'>
                                            Create your own <span className='text-red-500'>Dream Boyfriend</span>
                                        </div>
                                        <div className='text-center mt-1 lg:mt-10'>
                                            <button className="bg-red-800 text-white font-medium rounded-2xl p-2 sm:p-4 lg:p-5" onClick={CreateDreamBf}>
                                                Create Your Dream Boyfriend
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* 
                            <div className='flex items-center mt-5 mb-10 w-full relative'>
                                <img src="/assets/img/boydream.png" alt="Icon" className="w-full rounded-xl h-auto" />
                                <div className='absolute right-0'>
                                    <div className='grid justify-center p-4 mt-[70px] mr-[100px]'>
                                        <div className='text-4xl lg:text-5xl font-medium'>Create your own <span className='text-red-500'>Dream Boyfriend</span></div>
                                        <div className='text-center mt-10'>
                                            <button className="bg-red-800 text-white font-medium rounded-2xl p-4" onClick={CreateDreamBf}>
                                                Create Your Dream Boyfriend
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className='text-xl lg:text-2xl lg:my-4 mt-0 font-bold lg:py-4 py-0 text-black text-center'>Meet our Best <span className='text-[#991b1b]'>AI BoyFriends</span></div>
                            <div className="flex justify-center space-x-4">
                                <button
                                    onClick={() => handleTabClickboy(1)}
                                    className={`px-4 py-2 w-[25%] font-semibold text-lg rounded-tl-lg rounded-bl-lg transition-colors duration-300 ${activeTabani === 1 ? 'bg-[#991b1b] text-white' : 'bg-gray-200 text-gray-600'
                                        }`}
                                >
                                    Realistic
                                </button>
                                <button
                                    onClick={() => handleTabClickboy(2)}
                                    style={{ margin: "0" }}
                                    className={`px-4 py-2 w-[25%] font-semibold text-lg rounded-tr-lg rounded-br-lg transition-colors duration-300 ${activeTabani === 2 ? 'bg-[#991b1b] text-white' : 'bg-gray-200 text-gray-600'
                                        }`}
                                >
                                    Anime
                                </button>
                            </div>
                            <div className="mt-4">
                                {activeTabani === 1 && (
                                    <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4'>
                                        {imgboydata.map((item, index) => (
                                            <div
                                                key={index}
                                                className='relative'
                                                onMouseEnter={() => setHoveredIndex(index)}
                                                onMouseLeave={() => setHoveredIndex(null)}
                                            >
                                                <img
                                                    src={hoveredIndex === index ? item.hoverSrc : item.src}
                                                    alt={`Image of ${item.name}`}
                                                    className={`h-80 w-full xl:h-[500px] rounded-xl object-cover transition-transform duration-700 ease-in-out ${hoveredIndex === index ? 'scale-105' : ''}`}
                                                />
                                                <div onClick={() => openModal(item.assistant_id)} className='absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-700'>
                                                    <div className='flex justify-end p-4'>
                                                        <button className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                                            <img onClick={() => Chatnow(item.assistant_id.replaceAll("asst_", ""))} src="/assets/img/Group-icon.png" alt="Icon" className="w-12 h-12" />
                                                        </button>
                                                    </div>
                                                    <div className='absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg'>
                                                        <div className='flex justify-between py-1'>
                                                            <span className="text-lg lg:text-lg font-medium">{item.name}</span>
                                                            <span className="text-lg lg:text-lg font-medium">{item.age}</span>
                                                        </div>
                                                        <p className='text-sm lg:text-base'>{(item.content).slice(0, 60)}...</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                )}
                                {activeTabani === 2 && (
                                    <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4'>
                                        {imgboydataani.map((item, index) => (
                                            <div
                                                key={index}
                                                className='relative'
                                                onMouseEnter={() => setHoveredIndex(index)}
                                                onMouseLeave={() => setHoveredIndex(null)}
                                            >
                                                <img
                                                    src={hoveredIndex === index ? item.hoverSrc : item.src}
                                                    alt={`Image of ${item.name}`}
                                                    className={`h-80 w-full xl:h-[500px] rounded-xl object-cover transition-transform duration-700 ease-in-out ${hoveredIndex === index ? 'scale-105' : ''}`}
                                                />
                                                <div onClick={() => openModal(item.assistant_id)} className='absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-700'>
                                                    <div className='flex justify-end p-4'>
                                                        <button className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                                            <img onClick={() => Chatnow(item.assistant_id.replaceAll("asst_", ""))} src="/assets/img/Group-icon.png" alt="Icon" className="w-12 h-12" />
                                                        </button>
                                                    </div>
                                                    <div className='absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg'>
                                                        <div className='flex justify-between py-1'>
                                                            <span className="text-lg lg:text-lg font-medium">{item.name}</span>
                                                            <span className="text-lg lg:text-lg font-medium">{item.age}</span>
                                                        </div>
                                                        <p className='text-sm lg:text-base'>{(item.content).slice(0, 60)}...</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                )}
                            </div>
                            <Characters />
                        </>
                    )
                }
                <Blogs />
                <Accordion />
                <Footer />
            </div>
            {showPopup && <LandingPopup onClose={handleClosePopup} />}

            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center">
                    <div
                        className="bg-white p-8 rounded w-[80%] mt-10 h-[80%] flex flex-col items-center justify-center "
                        style={{
                            // backgroundImage: `url(${modalImg})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            // filter: 'contrast(0.5)',
                        }}
                    >
                        <div className='flex flex-col sm:flex-row overflow-scroll' style={{scrollbarWidth: "none"}}>
                            <div className=''>
                                <img
                                    src={modalImg}
                                    alt="Generated Image"
                                    className="w-[350px] h-[400px] lg:w-[400px] xl:w-[500px] lg:h-[500px] xl:h-[600px] 2xl:w-[570px] 2xl:h-[621px] rounded-xl"
                                    style={{ objectFit: 'cover' }}
                                />
                            </div>


                            <div className='grid ml-4'>
                                <div className='flex justify-between'>
                                    <h2 className='text-[#b30016d9] sm:text-xl font-bold md:text-2xl lg:text-4xl whitespace-nowrap'>
                                        {modaldata.name}<span onClick={() => toggleLike(modaldata.assistant_id)} className='ms-5'>
                                            {liked ? (
                                                <>
                                                    <i className="fa-regular fa-sm fa-heart"></i>
                                                    <span className='text-lg'>{LikesCount}  Like</span>
                                                </>
                                            ) : (
                                                <>
                                                    <i className="fa-solid fa-sm fa-heart"></i>
                                                    <span className='text-lg'>{LikesCount} Liked</span>
                                                </>
                                            )}
                                        </span>
                                    </h2>
                                    <div>
                                        <button onClick={closeModal} className="text-black border-none px-6 py-2 rounded"> <i class="fa-regular fa-2xl fa-circle-xmark"></i> </button>
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-2 mb-3">


                                    {itemsToDisplay.map(({ label, value, src }, index) => (
                                        <div key={index} className="flex items-start">
                                            <span className='icon w-10 mt-1 mr-2'>
                                                <img src={src} alt='icon' />
                                            </span>
                                            <div className="flex flex-col">
                                                <span className="text-muted font-semibold text-sm">{label}</span>
                                                <span className="text-md">{value}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div class="flex items-start ">
                                    <span class="icon mt-1 mr-2 icon-summary"></span>
                                    <div class="flex flex-col">
                                        <span class=" font-semibold text-muted text-sm">About:</span>
                                        <span class="text-md">{modaldata.Summary}</span>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <button className="bg-red-800 text-white font-medium rounded-2xl p-2 sm:p-4 lg:p-4 w-[35%] lg:w-[25%]" onClick={() => Chatnow(modaldata.assistant_id.replaceAll("asst_", ""))}>
                            Chat Now
                        </button>
                    </div>
                </div>
            )}

        </>
    );
};

export default LandingPage;
