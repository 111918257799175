import { useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from 'axios';

const GoogleCallBack = () => {
    const navigate = useNavigate();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const query = useQuery();
    const error = query.get('error');
    if (error) {
        Swal.fire(error, '', 'error', 3000);
    }
    const refresh = query.get('refresh');
    const email = query.get('email');
    const token = query.get('token');
    const userId = query.get('userId');

    // Your API call to fetch user data
    const fetchData = async () => {
        try {
            // Making the API request to the new endpoint
            const response = await axios.get('https://api2.eromantic.ai/assistant_api/fetch_static_data/', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });

            if (response.data.success) {
                // If successful, proceed to the dashboard
                window.location.href = '/dashboard';
            } else {
                // If the response is not successful, clear the token and userId, then redirect
                localStorage.removeItem('token');
                localStorage.removeItem('userId');
                setTimeout(() => {
                    window.location.href = '/';
                }, 2000);
            }
        } catch (error) {
            // Handle errors in the API call
            console.error('Error fetching static data:', error);
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            setTimeout(() => {
                window.location.href = '/';
            }, 2000);
        }
    };

    useEffect(() => {
        if (!error) {
            localStorage.setItem('token', token);
            localStorage.setItem('userId', userId);
            fetchData();
        }
    }, [error, email, token, userId]);

    return (
        <div className="min-h-screen flex justify-center items-center bg-gray-100">
            <div className="w-full max-w-lg mx-auto">
                <div className="p-6 bg-white shadow-lg rounded-2xl">
                    <h4 className="text-center mb-4 text-xl font-semibold">Sign in with Google</h4>
                    <form className="text-center">
                        <h5 className="mb-3 text-lg text-gray-600">Please wait...</h5>

                        <div className="mb-3">
                            {!error ? (
                                <button className="w-full flex items-center justify-between py-2 px-4 border border-gray-300 rounded-lg bg-transparent hover:bg-gray-100">
                                    <div className="flex items-center">
                                        <div className="mr-2">
                                            <img
                                                src="/assets/Logo2.png"
                                                alt="Nimibot AI"
                                                className="rounded-full"
                                                style={{ width: "20px" }}
                                            />
                                        </div>
                                        <span className="text-gray-500">Nimibot AI</span>
                                    </div>
                                    {email && <span className="text-gray-500">to continue with: {email}</span>}
                                </button>
                            ) : (
                                <>
                                    {error && <p className="text-red-500 text-sm">{error}</p>}
                                </>
                            )}
                        </div>

                        <div className="mb-3">
                            <div className="w-full flex items-center justify-center">
                                <button
                                    className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700"
                                    onClick={() => { navigate('/') }}
                                >
                                    Home
                                </button>
                            </div>
                        </div>

                        <div className="text-gray-500 text-sm">
                            Before using this app, you can review Nimibot AI's <NavLink to="/privacy-policy" className="text-blue-600">privacy policy</NavLink> and <NavLink to="/terms-of-service" className="text-blue-600">Terms of Service</NavLink>
                        </div>
                    </form>
                </div>

                <div className="text-center mt-3 text-sm text-gray-500">
                    <NavLink to="/" className="hover:text-blue-600">Help</NavLink> | <NavLink to="/privacy-policy" className="hover:text-blue-600">Privacy</NavLink> | <NavLink to="/terms-of-service" className="hover:text-blue-600">Terms</NavLink>
                </div>
            </div>
        </div>
    );
}

export default GoogleCallBack;
