import React, { useState, useEffect } from 'react';
import Recreateimage from '../../components/Recreateimage';
import SelectAIModel from '../../components/SelectAIModel';
import SearchExtension from '../../components/SearchExtension';
import UseShortWordsInputBox from '../../components/UseShortWordsInputBox';
import FooterMakeAI from '../../components/FooterMakeAI';
import { IoSearchOutline } from 'react-icons/io5'
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-multi-carousel';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { aiModal1, aiModal2, aiModal3, aiModal4, aiModal11, aiModal12, aiModal13, aiModal5, aiModal6, aiModal7, aiModal8, bigtits, mediumtits, GiantTits, BJ, smalltits, goldE, info, boy, girls, 
  Ass_show,  Bondage,  From_behind,  Futanari,  Reverse_cowgirl,  bg1,  breast_massage, } from '../../../assets'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import '../../../../src/swipper.css';
import Footer from '../../../frontend/components/Footer';

const Simplified = () => {
  const [selectedFeature, setSelectedFeature] = useState('Hair color');
  const [selectedCharacter, setSelectedCharacter] = useState('1 Girl');
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedIndextwo, setSelectedIndextwo] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [selectedIndexAi, setSelectedIndexAi] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredone, setIsHoveredone] = useState(false);

  {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
    */}
  const [selectedTitleAi, setSelectedTitleAi] = useState(null);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1280 },
      items: 4,
      partialVisibilityGutter: 40
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 30
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30
    }
  };

  const data = [
    // { img: aiModal8, title: 'Super Realistic Model', model: 'realisticinstav6.safetensors [ecb478bd5e]' },
    { img: aiModal5, title: 'Realistic', model: 'influncerv2_p1.safetensors [b6a4e767b6]', description: "This model generates hyper-realistic human images, capturing subtle facial features, natural lighting, and detailed textures that mirror real-world photography. Ideal for lifelike portraits and high-detail renderings" },
    { img: aiModal6, title: 'Hentai', model: 'metrixhentaip.safetensors [184d9775e3]', description: "Specialized for creating anime-inspired content with traditional Hentai aesthetics. It maintains bold outlines, vivid colors, and highly stylized character designs suitable for fans of classic and contemporary Hentai art styles." },
    { img: aiModal4, title: 'Enchant', model: 'hyperrealisticv6.safetensors [fe7578cb5e]', description: "This model produces images with a hyper-realistic yet magical quality, blending realism with a touch of fantasy. The resulting artwork captures mystical atmospheres, perfect for enchanting portraits or dreamlike scenes with high attention to detail." },
    { img: aiModal11, title: 'Anime HD', model: 'animeCARTOON_v10.ckpt [c5321a0b10]', description: "A high-definition anime model optimized for crisp, vibrant, and detailed animation-style images. This model is suited for both fan art and original characters, capturing the essence of Japanese animation with clear lines and expressive colors." },
    { img: aiModal12, title: 'Surreal', model: 'realisticinstav6.safetensors [ecb478bd5e]', description: "Focusing on surrealism, this model generates lifelike images with an imaginative twist, merging real-world elements with abstract concepts. Perfect for creative compositions that challenge reality while maintaining a high level of detail." },
    { img: aiModal13, title: 'Anime 3D', model: 'bestanimface.safetensors [d65f84e1d0]', description: "This model generates anime-style 3D renderings, providing depth and a unique stylized realism. Ideal for creating anime characters with a modern 3D aesthetic, bringing additional dimension and detail into traditional anime styles." },
    { img: aiModal3, title: 'Photoreal', model: 'realisticinstav6.safetensors [ecb478bd5e]', description: "Aiming for photorealism, this model generates highly detailed, lifelike images that closely resemble actual photography. With a focus on capturing fine textures, natural lighting, and realistic expressions, it's ideal for users looking for a true-to-life rendering style." },
    // { img: aiModal1, title: 'Surreal Anime Model', model: 'bestanimface.safetensors [d65f84e1d0]' },
    // { img: aiModal2, title: 'Realistic Anime Model', model: 'anniepornbestwithhuman.safetensors [dfc5cbba6c]' },
    // { img: aiModal7, title: 'Dark Anime Model', model: 'dksAnimeMerge_dksAnimeV10.safetensors [a378378a06]' },
  ];

  // Function to set the selected index based on the default model from the API
  const setDefaultModel = async () => {
    try {
      const response = await axios.get('https://api2.eromantic.ai/api/v1/GetDefaultModel/');
      const defaultModel = response.data.sd_model_checkpoint;

      // Find the index of the model in the data array
      const defaultIndex = data.findIndex(item => item.model === defaultModel);

      // If the model is found, set it as the selected index
      if (defaultIndex !== -1) {
        setSelectedIndexAi(defaultIndex);
      }
    } catch (error) {
      console.log("Error fetching default model:", error);
    }
  };

  useEffect(() => {
    // Call the function to fetch the default model when the component is mounted
    setDefaultModel();
  }, []);

  const handleModelSelection = (index) => {
    setSelectedIndexAi(index);

    const selectedtitle = data[index].title;
    setSelectedTitleAi(selectedtitle);
    const selectedModel = data[index].model;

    let requestData = {
      "sd_model_checkpoint": selectedModel
    };

    axios.post('https://api2.eromantic.ai/api/v1/sd_model_setup/', requestData)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
    */}

  // Sample data for images and their titles
  const images = [
    { src: smalltits, title: 'Small Tits', trigerWord: 'Small Tits', Lora: '' },
    { src: mediumtits, title: 'Medium Saggy Tits 2', trigerWord: 'Medium Saggy Tits', Lora: '' },
    { src: bigtits, title: 'Big Boobs', trigerWord: 'Big Tits', Lora: '' },
    { src: GiantTits, title: 'Huge Boobs', trigerWord: 'Huge Tits', Lora: '' },
  ];
  const imagestwo = [
    { src: Ass_show, title: 'Ass show', trigerWord: 'Ass_show', Lora: '' },
    { src: Bondage, title: 'Bondage', trigerWord: 'Bondage', Lora: '' },
    { src: From_behind, title: 'From behind', trigerWord: 'From_behind', Lora: '' },
    { src: Futanari, title: 'Futanari', trigerWord: 'Futanari', Lora: '' },
    { src: Reverse_cowgirl, title: 'Reverse cowgirl', trigerWord: 'Reverse_cowgirl', Lora: '' },
    { src: breast_massage, title: 'breast massage', trigerWord: 'breast_massage', Lora: '' },
  ];
  const imagesthree = [
    { src: BJ, title: 'BJ', trigerWord: 'penis, blowjob, blowjobTopofheadPovquiron, oral, nsfw, naked, nude, ', Lora: '<lora:quiron_BlowjobTopofHeadPOV_v040320_Lora:0.8>', ad_prompt: "((blowjob face:0.8)), ((hyper realistic face:0.5)), blowjobTopofheadPovquiron, penis", },
  ];

  const features = [
    { name: 'Hair color' },
    { name: 'Haircut' },
    { name: 'Wearing' },
    { name: 'Body type' },
    { name: 'Nationality' },
    { name: 'Environment' },
    // { name: 'Style' },
    { name: 'Effects' },
  ];

  const options = {
    'Characters': [
      { name: '1 Girl', src: girls, },
      { name: '1 Boy', src: boy, },
    ],
    '1 Girl': {
      'Hair color': [
        { 'name': 'Blonde' },
        { 'name': 'Black' },
        { 'name': 'Brown' },
        { 'name': 'Redhead' },
        { 'name': 'Pink' },
        { 'name': 'White' },
        { 'name': 'Ginger' },
        { 'name': 'Golden' },
        { 'name': 'blue' },
        { 'name': 'green' },

      ],
      'Haircut': [
        { 'name': 'Pixie Cut' },
        { 'name': 'Shag Hair' },
        { 'name': 'Bob Cut' },
        { 'name': 'Curly Hair' },
        { 'name': 'Braids' },
        { 'name': 'Bangs' },
        { 'name': 'Bald' },
        { 'name': 'Dreadlocks hair' },
        { 'name': 'Short curly hair' },
        { 'name': 'Long straight hair' },
        { 'name': 'Afro hair' },

      ],
      'Wearing': [
        { 'name': 'Lingerie' },
        { 'name': 'Fishnets' },
        { 'name': 'Swimwear' },
        { 'name': 'Bodysuits' },
        { 'name': 'Corset' },
        { 'name': 'Bikini' },
        { 'name': 'Chemise' },
        { 'name': 'French Maid' },
        { 'name': 'Thong' },
        { 'name': 'Yoga Pants' },
        { 'name': 'Nightgown' },
        { 'name': 'Transparent Clothing' },
        { 'name': 'Latex Clothing' },
      ],
      'Body type': [
        { 'name': 'Slim' },
        { 'name': 'Athletic' },
        { 'name': 'Curvy' },
        // { 'name': 'Petite' },
        { 'name': 'Hourglass' },
        // { 'name': 'Pear Shaped' },
        // { 'name': 'Tall' },
        { 'name': 'Plus Size' },
      ],
      'Nationality': [
        { 'name': 'American' },
        { 'name': 'Russian' },
        { 'name': 'Polish' },
        { 'name': 'German' },
        { 'name': 'Italian' },
        { 'name': 'French' },
        { 'name': 'Indian' },
        { 'name': 'Asian' },
        { 'name': 'Brazilian' },
        { 'name': 'Korean' },
        { 'name': 'Japanese' },
        { 'name': 'Mexican' },
        { 'name': 'African' }
      ],
      'Environment': [
        { 'name': 'Street' },
        { 'name': 'Swimming Pool' },
        { 'name': 'Bathroom' },
        { 'name': 'Apartment' },
        { 'name': 'Beach' },
        { 'name': 'Shopping Mall' },
        { 'name': 'Luxurious Castle' },
        { 'name': 'Cafe' },
        { 'name': 'University' },
        { 'name': 'Strip Club' },
        { 'name': 'Restaurant' },
        { 'name': 'Park' },
        // { 'name': 'Spaceship' },
        { 'name': 'Mars' },
        { 'name': 'Forest' },
        { 'name': 'Mountain' },
        { 'name': 'Lake' },
        { 'name': 'Library' },
        // { 'name': 'Concert Venue' },
        { 'name': 'Sports Stadium' },
        { 'name': 'Cruise Ship' },
        { 'name': 'Desert' },
        { 'name': 'Farm' },
        { 'name': 'Art Gallery' },
        { 'name': 'Amusement Park' },
        { 'name': 'Ski Resort' },
        { 'name': 'Rainforest' },
        // { 'name': 'Space Station' },
        { 'name': 'Aquarium' },
        { 'name': 'Vineyard' },
        // { 'name': 'Movie Theater' },
        { 'name': 'Fitness Center' },
        { 'name': 'Historical Monument' },
        { 'name': 'Airport' },
        // { 'name': 'Submarine' },
        // { 'name': 'Circus Tent' },
        { 'name': 'Casino' },
        { 'name': 'Underground Cave' },
        // { 'name': 'Space Observatory' },
        { 'name': 'Abandoned Mansion' },
        { 'name': 'Ski Lodge' },
        { 'name': 'Botanical Garden' },
        { 'name': 'Wildlife Sanctuary' },
        { 'name': 'Secret Garden' },
        { 'name': 'Floating Island' },
        { 'name': 'Whimsical Wonderland' },
        // { 'name': 'Mystical Labyrinth' },
        // { 'name': 'Neon Utopia' }    
      ],
      // 'Style': [
      //   { 'name': 'Realistic' },
      //   { 'name': 'Fantasy' },
      //   { 'name': 'Anime' },
      //   { 'name': 'Digital Art' },
      //   { 'name': 'Photorealism' },
      //   { 'name': 'Pop Art' },
      //   { 'name': 'Vintage' },
      //   { 'name': 'Surreal' },
      //   { 'name': 'Minimalist' },
      //   { 'name': 'Expressionist' },
      //   { 'name': 'Cubist' },
      //   { 'name': 'Gothic' },
      //   { 'name': 'Renaissance' },
      // ],
      'Effects': [
        { 'name': 'Bokeh' },
        // { 'name': 'Hardcore' },
        { 'name': 'Sepia' },
        { 'name': 'Vintage Film' },
        { 'name': 'Lens Flare' },
        { 'name': 'Reflection' },
        { 'name': 'Bloom' },
        { 'name': 'Depth Of Field' },
        { 'name': 'Dream Blur' },
        // { 'name': 'Monochrome' }    
      ],
    },
    '1 Boy': {
      'Hair color': [
        { 'name': 'Blonde' },
        { 'name': 'Black' },
        { 'name': 'Brown' },
        { 'name': 'Redhead' },
        { 'name': 'Pink' },
        { 'name': 'White' },
        { 'name': 'Ginger' },
        { 'name': 'Golden' },
        { 'name': 'blue' },
        { 'name': 'green' },

      ],
      'Haircut': [
        { 'name': 'Buzz Cut' },
        { 'name': 'Crew Cut' },
        { 'name': 'Man Bun Hair' },
        { 'name': 'Curly Hair' },
        { 'name': 'Quiff Hair Cut' },
        { 'name': 'Side Part' },
        { 'name': 'Dread locks Hair' },
        { 'name': 'Loose Wave Hair' },
        { 'name': 'Short Crop' },
        { 'name': 'Spiky' },
        { 'name': 'Mullet' },

      ],
      'Wearing': [
        { 'name': 'T-shirt' },
        { 'name': 'Jeans' },
        { 'name': 'Hoodie' },
        { 'name': 'Jacket' },
        { 'name': 'Shorts' },
        { 'name': 'Suit' },
        { 'name': 'Sweater' },
        { 'name': 'Blazer' },
        { 'name': 'Shirt' },
        { 'name': 'Tank Top' },
        { 'name': 'Polo Shirt' },
        { 'name': 'Cargo Pants' },
        { 'name': 'Tracksuit' },
        { 'name': 'Leather Jacket' },
        { 'name': 'V-Neck' },
      ],
      'Body type': [
        { 'name': 'Slim' },
        { 'name': 'Athletic' },
        { 'name': 'Muscular' },
        { 'name': 'Average' },
        { 'name': 'Broad Shouldered' },
        { 'name': 'Tall' },
        { 'name': 'Short' },
        { 'name': 'Stocky' },
      ],
      'Nationality': [
        { 'name': 'American' },
        { 'name': 'Russian' },
        { 'name': 'Polish' },
        { 'name': 'German' },
        { 'name': 'Italian' },
        { 'name': 'French' },
        { 'name': 'Indian' },
        { 'name': 'Asian' },
        { 'name': 'Brazilian' },
        // { 'name': 'Korean' },
        { 'name': 'Japanese' },
        { 'name': 'Mexican' },
        { 'name': 'African' }
      ],
      'Environment': [
        { 'name': 'Street' },
        { 'name': 'Swimming Pool' },
        { 'name': 'Bathroom' },
        { 'name': 'Apartment' },
        { 'name': 'Beach' },
        { 'name': 'Shopping Mall' },
        { 'name': 'Luxurious Castle' },
        { 'name': 'Cafe' },
        { 'name': 'University' },
        { 'name': 'Strip Club' },
        { 'name': 'Restaurant' },
        { 'name': 'Park' },
        { 'name': 'Mars' },
        { 'name': 'Forest' },
        { 'name': 'Mountain' },
        { 'name': 'Lake' },
        { 'name': 'Library' },
        { 'name': 'Sports Stadium' },
        { 'name': 'Cruise Ship' },
        { 'name': 'Desert' },
        { 'name': 'Farm' },
        { 'name': 'Art Gallery' },
        { 'name': 'Amusement Park' },
        { 'name': 'Ski Resort' },
        { 'name': 'Rainforest' },
        { 'name': 'Aquarium' },
        { 'name': 'Vineyard' },
        { 'name': 'Fitness Center' },
        { 'name': 'Historical Monument' },
        { 'name': 'Airport' },
        { 'name': 'Casino' },
        { 'name': 'Underground Cave' },
        { 'name': 'Abandoned Mansion' },
        { 'name': 'Ski Lodge' },
        { 'name': 'Botanical Garden' },
        { 'name': 'Wildlife Sanctuary' },
        { 'name': 'Secret Garden' },
        { 'name': 'Floating Island' },
        { 'name': 'Whimsical Wonderland' },
      ],
      // 'Style': [
      //   { 'name': 'Realistic' },
      //   { 'name': 'Cartoon' },
      //   { 'name': 'Abstract' },
      //   { 'name': 'Minimalist' },
      //   { 'name': 'Anime' },
      //   { 'name': 'Digital Art' },
      //   { 'name': 'Sci-Fi' },
      //   { 'name': 'Steampunk' },
      //   { 'name': 'Noir' },
      //   { 'name': 'Futuristic' },
      //   { 'name': 'Graffiti' },
      //   { 'name': '3D Rendering' },
      // ],
      'Effects': [
        { 'name': 'Bokeh' },
        { 'name': 'Sepia' },
        { 'name': 'Vintage Film' },
        { 'name': 'Lens Flare' },
        { 'name': 'Reflection' },
        { 'name': 'Bloom' },
        { 'name': 'Depth Of Field' },
        { 'name': 'Dream Blur' },
      ],
    },
  };
  const [selectedFeatures, setSelectedFeatures] = useState(options);
  {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
    */}
  const [selected, setSelected] = useState('medium');
  const [aiselected, setaiSelected] = useState('creative');
  const [MagicSelected, setMagicSelected] = useState('none');
  const [svgselected, setsvgSelected] = useState('fourth');
  const [otherInput, setOtherInput] = useState(null); // Add state for input value
  const [FaceoptionalInput, setFaceoptionalInput] = useState(null); // Add state for input value


  const MagicClick = (value) => {
    setMagicSelected(value);
  };
  const handleClick = (value) => {
    setSelected(value);
  };
  const handleaiClick = (value) => {
    setaiSelected(value);
  };

  const handlesvgClick = (value) => {
    setsvgSelected(value);
  };

  const handleOtherInputChange = (e) => {
    setOtherInput(e.target.value); // Update state when input changes
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const handleFaceoptionalInputChange = (e) => {
    setFaceoptionalInput(e.target.value); // Update state when input changes
  };

  const navigate = useNavigate()
  //  const generate = () => {
  //   navigate('/dashboard/ImageView')
  //  }

  {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
    */}
  const generate = () => {
    const selectedHairColor = typeof selectedFeatures[selectedCharacter]["Hair color"] === 'string' ? selectedFeatures[selectedCharacter]["Hair color"] : null;
    const selectedHaircut = typeof selectedFeatures[selectedCharacter]["Haircut"] === 'string' ? selectedFeatures[selectedCharacter]["Haircut"] : null;
    const selectedWearing = typeof selectedFeatures[selectedCharacter]["Wearing"] === 'string' ? selectedFeatures[selectedCharacter]["Wearing"] : null;
    const selectedBodyType = typeof selectedFeatures[selectedCharacter]["Body type"] === 'string' ? selectedFeatures[selectedCharacter]["Body type"] : null;
    const selectedNationality = typeof selectedFeatures[selectedCharacter]["Nationality"] === 'string' ? selectedFeatures[selectedCharacter]["Nationality"] : null;
    const selectedEnvironment = typeof selectedFeatures[selectedCharacter]["Environment"] === 'string' ? selectedFeatures[selectedCharacter]["Environment"] : null;
    const selectedStyle = typeof selectedFeatures[selectedCharacter]["Style"] === 'string' ? selectedFeatures[selectedCharacter]["Style"] : null;
    const selectedEffects = typeof selectedFeatures[selectedCharacter]["Effects"] === 'string' ? selectedFeatures[selectedCharacter]["Effects"] : null;
    const nationality = selectedNationality;
    const Upscale = isChecked;
    const promptString = Object.values({
      character: selectedCharacter || null,
      hair_color: selectedHairColor ? selectedHairColor + " hair" : null,
      haircut: selectedHaircut ? selectedHaircut + " hair" : null,
      wearing: selectedWearing ? "Wearing " + selectedWearing : null,
      body_type: selectedBodyType ? selectedBodyType + " body" : null,
      nationality: selectedNationality || null,
      environment: selectedEnvironment ? "in a " + selectedEnvironment : null,
      style: selectedStyle ? selectedStyle + " style" : null,
      effects: selectedEffects ? selectedEffects + " effect" : null,
      extensions: selectedIndex ? "(" + selectedIndex + ")" : null,
      other: otherInput || null,
      face_prompt: FaceoptionalInput || null
    })
      .filter(value => value !== null)
      .join(', ');
    setSelectedItem(promptString);


    console.log(promptString)

    // Other arguments
    var newDate = new Date();

    var datetime = newDate.getDate() + "_" + newDate.getMonth() + "_" + newDate.getFullYear() + "_" + newDate.getTime();
    const timestamp = new Date().getTime(); // Current timestamp
    const randomString = Math.random().toString(36).substring(2, 10); // Random string of 8 characters
    const requestPayload = {
      generation_prompt: promptString,
      steps: selected,
      imageupscale: Upscale,
      nationality: nationality,
      make_ai_listen: aiselected,
      magic: MagicSelected,
      aspect_ratio: svgselected,
      variations: null,
      make_private: false,
      user_name: localStorage.getItem('login'),
      generation_id: "ER_GenImg_" + datetime,
      generation_slug: "ER_" + selectedIndexAi + "_" + promptString.replaceAll(', ', '_').replaceAll(' ', '_'),
      Aimodel: selectedIndexAi,
      Aititle: selectedTitleAi,
      extensions: selectedIndex,
      face_prompt: FaceoptionalInput,
      image_file_name: `ER_image_${timestamp}_${randomString}.png`,
      other: otherInput,
    };
    console.log(requestPayload);

    axios.post('https://api2.eromantic.ai/api/v1/generate_image/', requestPayload)
      .then(response => {
        navigate('/dashboard/ImageView/ER_GenImg_' + datetime);
      })
      .catch(error => {
        console.log("Error generating image:", error);
      });
  };
  //const choosefeature=(item)=> {
  //    setSelectedItem(item)
  //};

  // const choosefeature = (featureName, featureType) => {
  //   setSelectedFeatures((prev) => ({
  //     ...prev,
  //     [selectedCharacter]: {
  //       ...prev[selectedCharacter],
  //       [featureType]: featureName,
  //     },
  //   }));
  // };

  const choosefeature = (featureName, featureType) => {
    setSelectedFeatures((prev) => ({
      ...prev,
      [selectedCharacter]: {
        ...prev[selectedCharacter],
        [featureType]: featureName,
      },
    }));

    // Move to the next feature
    const nextIndex = currentFeatureIndex + 1;
    if (nextIndex < features.length) {
      setSelectedFeature(features[nextIndex].name); // Set the next feature as selected
      setCurrentFeatureIndex(nextIndex); // Update current feature index
    } else {
      // Optionally, handle when all features have been selected
      console.log('All features have been selected');
    }
  };
  return (
    <>
      {/* <p className='pt-5 text-xl xl:text-[25px] font-medium'>This image generation Model provide Optimized AI Images and High-Quality Results.
      Use Simplified Mode: Stick to simplified mode unless you need specific control over word weights or intricate details, as it typically yields good results with minimal effort.

Avoid Long Prompts: Lengthy prompts dilute the power of selected extensions. Use concise words and avoid overloading the 'other' section to maintain focus and image quality.

Avoid Specific Body Part Terms: Using terms like "legs" or "hands" may lead to errors such as extra limbs.

Use Short Keywords + Commas: Simple words separated by commas work better than natural language for most models. Exceptions may apply, like the "semireal" model, which can handle natural phrases.

Face Prompt and Higher Resolution: A "face prompt" can enhance facial quality at high resolution, but it's not advisable when specific character attributes or face replication are needed.

2X Upscaling for Quality: Upscaling by 2X generally produces better quality than 3X, enhancing detail, realism, and facial features.

HD AI for Explicit Content: For explicit images, HD models provide better quality and fewer malformations than Base models.

Avoid Conflicting Extensions: Combining conflicting extensions (e.g., opposing poses) often results in image malformations.</p> */}
      {/* <Recreateimage /> */}

      <div className='pt-10 md:pt-20'>
        {/* <div className='flex justify-center gap-5 pb-2'>
          <p className='font-semibold text-3xl xl:text-4xl pb-5'>Select AI Model</p>
          <img src={info} alt="infoBlack" className='h-6' />
        </div> */}

        <div className="container">
          <p className='text-center font-semibold text-xl xl:text-2xl pb-5'>Select AI Model</p>
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={'auto'}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            pagination={{ el: '.swiper-pagination', clickable: true }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
              clickable: true,
            }}
            modules={[EffectCoverflow, Pagination, Navigation]}
            className="swiper_container"
          >

            {data.map((item, index) => (
              <SwiperSlide key={index}>
                <div
                  className="relative"
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  onClick={() => handleModelSelection(index)}
                >
                  <img src={item.img} alt={item.title} />
                  <div className="absolute bg-[#d3b7b79c] border-1 rounded-full p-3 sm:text-sm md:text-lg lg:text-xl xxl:text-2xl bottom-1 left-1">
                    {item.title}
                  </div>
                  <div className={`absolute text-white border-1 rounded-2xl p-3 sm:text-sm md:text-lg lg:text-xl xxl:text-2xl bottom-1 right-1 ${selectedIndexAi === index ? 'bg-[#0e7a36bf]' : 'bg-[#9d2d2de0]'}`}>
                    {selectedIndexAi === index ? "Selected" : "Select Model"}
                  </div>
                  {hoveredIndex === index && (
                    <div className="absolute mb-[6rem] ml-[6rem] text-center bottom-0 left-0 w-60 bg-black bg-opacity-70 p-2 text-white text-sm rounded-lg">
                      {item.description}
                    </div>
                  )}
                </div>
              </SwiperSlide>
            ))}

            <div className="slider-controler">
              <div className="swiper-button-prev slider-arrow">
                <span className="hover-text prev-text">Prev</span>
              </div>
              <div className="swiper-button-next slider-arrow">
                <span className="hover-text next-text">Next</span>
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </Swiper>

          {selectedIndexAi !== null && (
            <div className="mt-4 p-4 bg-[#75787e6e] border rounded-lg text-lg text-gray-800">
              <h3 className="font-semibold mb-2">Selected {data[selectedIndexAi].title} Model</h3>
              <p>{data[selectedIndexAi].description}</p>
            </div>
          )}

        </div>


      </div>

      <div className='flex justify-center gap-5 pt-10 md:pt-20'>
        <p className='font-semibold text-xl xl:text-2xl pb-5'>Select Character</p>
        <img src={info} alt="infoBlack" className='h-6 bg-black rounded-full' />
      </div >
      <div className="flex justify-center space-x-6">
        {options['Characters'].map((item, index) => (
          <div
            key={index}
            className={`p-2 rounded-xl items-center flex flex-col justify-center space-y-4 m-4 ${selectedCharacter === item.name ? 'bg-black' : 'bg-white'}`}
            onClick={() => {
              setSelectedCharacter(item.name);
              setSelectedFeature('Hair color');
            }}
          >
            {/* Image of the character */}
            <img
              src={item.src}
              alt={item.name}
              className="w-60 h-60 object-cover rounded-lg"
            />

            {/* Character Name */}
            <p className={`font-semibold hover:text-red-600 hover:cursor-pointer text-2xl ${selectedCharacter === item.name ? 'text-white' : 'text-black'}`}>
              {item.name}
            </p>
          </div>
        ))}
      </div>




      {selectedCharacter && (
        <>
          <div className='rounded-xl py-10 px-5 my-10'>
            <div className='flex justify-center gap-5'>
              <p className='font-semibold text-xl xl:text-2xl pb-5'>Select Features</p>
              <img src={info} alt="infoBlack" className='h-6' />
            </div>
            <div className='bg-[#ffffff80] py-2 text-black rounded-xl justify-around items-center flex flex-wrap'>
              {features.map((item, index) => (
                <p
                  key={index}
                  className={`font-semibold hover:text-white hover:cursor-pointer text-xl py-2 xl:text-2xl px-10 ${selectedFeature === item.name ? 'rounded-xl text-white bg-black' : ''}`}
                  onClick={() => {
                    setSelectedFeature(item.name);
                    setCurrentFeatureIndex(index); // Set the current feature index on click
                  }}
                >
                  {item.name}
                </p>
              ))}
            </div>

            {selectedFeature && (
              <div className='bg-[#ffffff80] text-black rounded-xl p-5 justify-center lg:justify-evenly items-center flex gap-2 flex-wrap mt-1'>
                {options[selectedCharacter][selectedFeature].map((item, index) => (
                  <div key={index}>
                    <div
                      className={`w-[150px] sm:w-[250px] border-2 hover:text-red-600 hover:border hover:border-red-600 rounded-full mt-3 hover:cursor-pointer ${selectedFeatures[selectedCharacter]?.[selectedFeature] === item.name ? 'text-red-600 border-red-600' : 'border-zinc-600'}`}
                      onClick={() => choosefeature(item.name, selectedFeature)}
                    >
                      <p className='font-semibold text-xl py-2 px-4 text-center'>
                        {item.name}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {selectedCharacter && Object.keys(selectedFeatures[selectedCharacter] || {}).length > 0 && (
              <div className="bg-[#ffffff80] rounded-lg">
                <h3 className="font-semibold mt-6 sm:mt-20 p-4 sm:p-6 text-xl sm:text-xl text-center">Selected Features</h3>
                <div className="flex flex-col sm:flex-row sm:flex-wrap justify-center items-center gap-2 lg:gap-[5rem] p-4"> {/* Centering and spacing */}
                  {Object.entries(selectedFeatures[selectedCharacter] || {}).map(([feature, value], index) => (
                    <div
                      key={index}
                      className="flex flex-col w-80 sm:w-[150px] bg-gray-200 rounded-lg shadow p-4 border-l-4 border-[#662020cc]"
                    >
                      <span className="font-semibold text-lg mb-1 text-[#662020cc]">{feature}</span>
                      <span className="text-md text-gray-700 pl-2">
                        {typeof value === 'object' ? value.name : value}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}


          </div>
        </>
      )}




      <div className='flex justify-center gap-5 pt-10 md:pt-20'>
        <p className='font-semibold text-3xl xl:text-4xl pb-5'>Extensions</p>
        <img src={info} alt="infoBlack" className='h-6 bg-black rounded-full' />
      </div>
      <div className='bg-[#ffffff80] rounded-xl py-6 px-6 '>
        <div className='px-5 py-2 flex border border-black w-full items-center rounded-full'>
          <IoSearchOutline color='black' size={23} /> <input type="search" placeholder='Search Extension' className='focus:outline-none w-full bg-[#c6c5c5] text-black text-xl ps-3' />
        </div>
        <div
          style={{
            height: '30rem',
            overflowY: 'scroll',
            scrollbarWidth: 'thin', // For Firefox
            scrollbarColor: '#c0c0c0 #f0f0f0', // For Firefox
            borderRadius: '0.75rem', // Rounded corners
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
            padding: '1rem', // Padding inside the div
          }}
          className="scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 hover:scrollbar-thumb-gray-500"
        >
          <div className='bg-zinc-300 rounded-full w-32 my-5 py-2 font-semibold text-xl text-center text-gray-600'>
            Boobs
          </div>
          <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12'>
            {images.map((item, index) => (
              <div
                key={index}
                className={`relative w-[180px] h-[210px] xl:w-[170px] xl:h-[210px] cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 ${selectedIndex === item.trigerWord + item.Lora ? 'bg-red-600 border-4 border-red-600' : ''}`}
                onClick={() => setSelectedIndex(item.trigerWord + item.Lora)}
              >
                {/* Image element */}
                <img
                  src={item.src}
                  alt={item.title}
                  className='w-full h-full object-cover cursor-pointer rounded-lg'
                />

                {/* Title overlay */}
                <div
                  className={`absolute bottom-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 rounded-b-lg ${selectedIndex === index ? 'bg-red-600' : ''}`}
                  style={{ left: "0.01rem" }}
                >
                  {item.title}
                </div>

              </div>
            ))}

          </div>
          <div className='bg-zinc-300 rounded-full w-32 my-5 py-2 font-semibold text-xl text-center text-gray-600'>
            Ass
          </div>
          <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12'>
            {imagestwo.map((item, index) => (
              <div
                key={index}
                className={`relative w-[180px] h-[210px] xl:w-[170px] xl:h-[210px] cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 ${selectedIndextwo === index ? 'bg-red-600 border-4 border-red-600' : ''}`} onClick={() => setSelectedIndextwo(index)}
              >
                {/* Image element */}
                <img
                  src={item.src}
                  alt={item.title}
                  className='w-full h-full object-cover cursor-pointer rounded-lg'
                />

                {/* Title overlay */}
                <div
                  className={`absolute bottom-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 rounded-b-lg ${selectedIndextwo === index ? 'bg-red-600' : ''}`} style={{ left: "0.01rem" }}
                >
                  {item.title}
                </div>
              </div>
            ))}
          </div>

          <div className='bg-zinc-300 rounded-full w-32 my-5 py-2 font-semibold text-xl text-center text-gray-600'>
            Blowjob
          </div>
          <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12'>
            {imagesthree.map((item, index) => (
              <div
                key={index}
                className={`relative w-[180px] h-[210px] xl:w-[170px] xl:h-[210px] cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 ${selectedIndextwo === index ? 'bg-red-600 border-4 border-red-600' : ''}`} onClick={() => setSelectedIndextwo(index)}
              >
                {/* Image element */}
                <img
                  src={item.src}
                  alt={item.title}
                  className='w-full h-full object-cover cursor-pointer rounded-lg'
                />

                {/* Title overlay */}
                <div
                  className={`absolute bottom-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 rounded-b-lg ${selectedIndextwo === index ? 'bg-red-600' : ''}`} style={{ left: "0.01rem" }}
                >
                  {item.title}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div>
        <div className='flex flex-wrap justify-between pt-20'>
          <div className='flex flex-col gap-5 lg:gap-12 text-black font-josefin-sans'>
            <div className='flex relative items-center gap-5 md:mb-3 lg:mb-0'>
              <p className='text-base md:text-[15px] lg:text-[20px] xl:text-[25px]'>Do you want to Upscale Image (2T)</p>
              
              <input
                type="checkbox"
                className="w-7 h-7"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <div
              className=""
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <img src={info} alt="info" className="h-4 md:h-6" />
            {isHovered && (
                <span className="absolute bg-[#8a8a8a] text-white text-sm top-7 right-0 text-black p-2 shadow-lg rounded max-w-xs w-auto z-10">
                  Elevate your images with AI-powered upscaling. Increase resolution for sharper, more detailed visuals, perfect for larger displays.
                </span>
              )}
            </div>
            </div>
            <div>
              <div className='flex relative items-center gap-5'>
                <p className='text-base md:text-[15px] lg:text-[20px] xl:text-[25px]'>Other <span className='text-lg'>(use prompt words with commas)</span></p>
                <div
                  className=""
                  onMouseEnter={() => setIsHoveredone(true)}
                  onMouseLeave={() => setIsHoveredone(false)}
                >
                  <img src={info} alt="info" className="h-4 md:h-6" />

                  {/* Conditionally render the span based on hover state */}
                  {isHoveredone && (
                    <span className="absolute text-white bg-[#8a8a8a] text-sm bottom-100 right-0 text-black p-2 shadow-lg rounded max-w-xs w-auto">
                      Specify art styles, features, colors, mood, or setting to unlock the full creative potential of the model, generating images that match your unique vision and aesthetic.
                    </span>
                  )}
                </div>
              </div>
              <div className='bg-zinc-300 rounded-xl mt-2 px-5 py-2 text-black text-2xl font-light w-full h-38'>
                <textarea
                  type="text"
                  rows={3}
                  className='focus:outline-none bg-zinc-300 w-full'
                  placeholder='e.g. smiling, sunny weather'
                  value={otherInput} // Bind the input to state
                  onChange={handleOtherInputChange} // Handle input change
                />
              </div>
              {/* <input type="number" className='focus:outline-none bg-zinc-300 rounded-xl px-5 py-1 mt-3 w-52 text-black text-2xl' /> */}
            </div>
            {/* <div className='flex items-center gap-5 md:mb-3 lg:mb-0'>
              <input
                type="radio"
                className='w-7 h-7'
              />
              <p className='text-base md:text-[25px] lg:text-[33px] xl:text-[35px]'>Make Private</p>
            </div> */}
          </div>

          <div className='flex flex-col gap-5 lg:gap-12 text-black font-josefin-sans'>
            {/* <div className='mt-3'>
                            <div className='flex items-center gap-5'>
                                <p className='text-base md:text-[25px] lg:text-[33px] xl:text-[35px]'>Make AI listen</p>
                                <div>
                                    <img src={info} alt="info" className='h-4 md:h-6' />
                                </div>
                            </div>
                            <div className='flex items-center gap-5 lg:pt-3'>
                                 <p
                                    onClick={() => handleaiClick('creative', 'Let it be creative')}
                                    className={`text-base md:text-lg lg:text-xl xl:text-2xl font-semibold cursor-pointer px-4 py-2 rounded border border-transparent transition-colors duration-300 ${aiselected === 'creative' ? 'text-red-500' : 'text-black'}`}
                                  >
                                    Let it be creative
                                  </p>
                                  <p
                                    onClick={() => handleaiClick('normal', 'Normal A lot')}
                                    className={`text-base md:text-lg lg:text-xl xl:text-2xl font-light cursor-pointer px-4 py-2 rounded border border-transparent transition-colors duration-300 ${aiselected === 'normal' ? 'text-red-500' : 'text-black'}`}
                                  >
                                    Normal A lot
                                  </p>
                            </div>
                        </div> */}
            <div>
              <div className='flex items-center gap-5'>
                <p className='text-base md:text-[15px] lg:text-[20px] xl:text-[25px]'>Aspect ratio <span className='text-base md:text-lg lg:text-xl xl:text-2xl'>(square, horizontal, vertical)</span></p>
                {/* <div>
                  <img src={info} alt="info" className='h-4 md:h-6' />
                </div> */}
              </div>
              <div className='bg-[#d4d4d8] w-[75%] justify-center rounded-[20px] flex items-center gap-5 text-center mt-2 p-5'>
                <svg
                  onClick={() => handlesvgClick('first')}
                  className={`cursor-pointer fill-none stroke-white mr-[1rem] transition-opacity duration-300 ${svgselected === 'first' ? 'opacity-[1]' : 'opacity-[0.4]'}`}
                  width="42"
                  height="42"
                  viewBox="0 0 42 42"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="1.5" y="1.5" width="39" height="39" rx="6.5" strokeWidth="2"></rect>
                </svg>
                <svg
                  onClick={() => handlesvgClick('second')}
                  className={`cursor-pointer fill-none stroke-white mr-[1rem] transition-opacity duration-300 ${svgselected === 'second' ? 'opacity-[1]' : 'opacity-[0.4]'}`}
                  width="62"
                  height="42"
                  viewBox="0 0 62 42"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="1.5" y="1.5" width="59" height="39" rx="6.5" strokeWidth="2"></rect>
                </svg>
                <svg
                  onClick={() => handlesvgClick('third')}
                  className={`cursor-pointer fill-none stroke-white mr-[1rem] transition-opacity duration-300 ${svgselected === 'third' ? 'opacity-[1]' : 'opacity-[0.4]'}`}
                  width="29"
                  height="42"
                  viewBox="0 0 29 42"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="1.5" y="1.5" width="26" height="39" rx="6.5" strokeWidth="2"></rect>
                </svg>
                <svg
                  onClick={() => handlesvgClick('fourth')}
                  className={`cursor-pointer fill-none stroke-white mr-[1rem] transition-opacity duration-300 ${svgselected === 'fourth' ? 'opacity-[1]' : 'opacity-[0.4]'}`}
                  width="29"
                  height="42"
                  viewBox="0 0 29 42"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="1.5" y="1.5" width="26" height="39" rx="6.5" strokeWidth="2"></rect>
                  <path d="M5.399 22.194V21.459L9.158 16.65H10.292L6.5645 21.459L6.029 21.291H11.8985V22.194H5.399ZM9.4835 24V22.194L9.515 21.291V19.695H10.502V24H9.4835ZM14.7627 19.821C14.5667 19.821 14.3987 19.7545 14.2587 19.6215C14.1187 19.4815 14.0487 19.3065 14.0487 19.0965C14.0487 18.8865 14.1187 18.715 14.2587 18.582C14.3987 18.449 14.5667 18.3825 14.7627 18.3825C14.9517 18.3825 15.1127 18.449 15.2457 18.582C15.3857 18.715 15.4557 18.8865 15.4557 19.0965C15.4557 19.3065 15.3857 19.4815 15.2457 19.6215C15.1127 19.7545 14.9517 19.821 14.7627 19.821ZM14.7627 24.063C14.5667 24.063 14.3987 23.9965 14.2587 23.8635C14.1187 23.7235 14.0487 23.5485 14.0487 23.3385C14.0487 23.1285 14.1187 22.957 14.2587 22.824C14.3987 22.691 14.5667 22.6245 14.7627 22.6245C14.9517 22.6245 15.1127 22.691 15.2457 22.824C15.3857 22.957 15.4557 23.1285 15.4557 23.3385C15.4557 23.5485 15.3857 23.7235 15.2457 23.8635C15.1127 23.9965 14.9517 24.063 14.7627 24.063ZM20.2916 24.084C19.7736 24.084 19.2696 24.0035 18.7796 23.8425C18.2966 23.6815 17.9011 23.4645 17.5931 23.1915L18.0761 22.362C18.3211 22.593 18.6396 22.782 19.0316 22.929C19.4236 23.076 19.8401 23.1495 20.2811 23.1495C20.8411 23.1495 21.2716 23.0305 21.5726 22.7925C21.8736 22.5545 22.0241 22.236 22.0241 21.837C22.0241 21.564 21.9576 21.326 21.8246 21.123C21.6916 20.92 21.4606 20.766 21.1316 20.661C20.8096 20.549 20.3651 20.493 19.7981 20.493H18.1286L18.5171 16.65H22.6646V17.5635H18.9056L19.4516 17.049L19.1471 20.0835L18.6011 19.5795H20.0186C20.7536 19.5795 21.3451 19.674 21.7931 19.863C22.2411 20.052 22.5666 20.3145 22.7696 20.6505C22.9726 20.9795 23.0741 21.361 23.0741 21.795C23.0741 22.215 22.9726 22.6 22.7696 22.95C22.5666 23.293 22.2586 23.5695 21.8456 23.7795C21.4396 23.9825 20.9216 24.084 20.2916 24.084Z" fill="white"></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <button onClick={generate} className='w-full mt-5 rounded-xl text-xl lg:text-3xl bg-red-600 hover:bg-red-800 py-4 text-center flex items-center gap-5 justify-center'>
          Generate for 1.00
          <img className='w-10' src={goldE} alt="goldE" />
        </button>
      </div>
    </>
  );
}

export default Simplified;
