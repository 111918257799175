import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams from react-router
import { img1 } from '../../../assets';
import { VscHeartFilled } from 'react-icons/vsc';
import DiscoverImagesBox from '../../components/DiscoverImagesBox';
import axios from 'axios';
import '../../../App.css';

const promptDataWithoutdis = [
    { name: 'PROMPT' },
    { name: 'FACE PROMPT' },
    { name: 'EXCLUDE' },
    { name: 'EXTENSIONS' },
    { name: 'OTHER DETAILS' },
];

const ImageView = () => {
    const [selectedName, setSelectedName] = useState('PROMPT');
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false); // Separate loading for image generation
    const [error, setError] = useState(null);
    const [drive, setDrive] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [GeneratedImage, setGeneratedImage] = useState(null);
    const [putResponsedata, setPutResponsedata] = useState(null);
    const [generateduser, setGeneratedUser] = useState("UNKNOWN");
    const [promptData, setPromptData] = useState(promptDataWithoutdis); // Initialize with default data

    const { id } = useParams();

    const selectedDescription = promptData.find(item => item.name === selectedName)?.description;

    useEffect(() => {
        const fetchImageData = async () => {
            try {
                const response = await axios.get(`https://api2.eromantic.ai/api/v1/get_generated_image/${id}/`);
                const updatedPromptData = promptDataWithoutdis.map(item => {
                    setDrive(response.data.generation_prompt);
                    if (response.data.user_name !== null) {
                        setGeneratedUser(response.data.user_name);
                    }
                    setPutResponsedata(response.data);

                    if (item.name === 'PROMPT') {
                        return { ...item, description: response.data.generation_prompt };
                    } else if (item.name === 'EXCLUDE') {
                        return { ...item, description: response.data.variations };
                    } else if (item.name === 'FACE PROMPT') {
                        return { ...item, description: response.data.face_prompt };
                    } else if (item.name === 'EXTENSIONS') {
                        return { ...item, description: response.data.extensions };
                    } else if (item.name === 'OTHER DETAILS') {
                        return { ...item, description: response.data.other };
                    }
                    return item;
                });
                setPromptData(updatedPromptData);
                setLoading(false);

                // Call the generateImage function after fetching the data
                generateImage(response.data); // Pass fetched data as argument
            } catch (error) {
                setError('Failed to fetch data');
                setLoading(false);
            }
        };

        // Only call fetchImageData once when the component mounts
        if (id) {
            fetchImageData();
        }

        // Scroll to top when the page loads
        window.scrollTo(0, 0);
    }, [id]);

    const generateImage = async (data) => {
        setLoading2(true); // Show loader for generating image
        try {
            const payload = {
                Aimodel: data.Aimodel ?? "1",  // Default value if Aimodel is null
                Aititle: data.Aititle,
                generation_prompt: data.generation_prompt,
                image_file_name: data.image_file_name,
                steps: data.steps || "medium",
                make_ai_listen: data.make_ai_listen || "creative",
                magic: data.magic || "none",
                aspect_ratio: data.aspect_ratio || "fourth",
                variations: data.variations || "",
                make_private: data.make_private || false,
                user_name: data.user_name || "Anonymous",
                generation_id: data.generation_id || `ER_GenImg_${Date.now()}`,
                generation_slug: data.generation_slug || `ER_${Date.now()}_slug`,
                extensions: data.extensions || "",
                face_prompt: data.face_prompt || "",
                other: data.other || ""
            };

            const response = await axios.post('https://api2.eromantic.ai/api/v1/LoadImage/', payload, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            // Handle the response
            setGeneratedImage(response.data.image); // Set the generated image URL
            setLoading2(false);
        } catch (error) {
            console.error('Error generating image:', error);
            setError('Failed to generate image. Please try again.');
            setLoading2(false);
        }
    };

    const downloadImage = async () => {
        if (GeneratedImage) {
            setLoading(true); // Start loading
            setError(null); // Reset any previous error
            try {
                const response = await fetch(GeneratedImage);
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.download = `generated-image_${Date.now()}.png`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error downloading image:', error);
                setError('Failed to download image. Please try again.');
            } finally {
                setLoading(false); // Stop loading
            }
        } else {
            setError('No image available for download');
        }
    };

    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className='2xl:px-20 lg:px-10 px-2 mt-5 pb-20 pt-10 text-white font-josefin-sans'>
                <div className='flex flex-wrap justify-center gap-7'>
                    <div className='flex flex-col gap-3'>
                        <div className='flex items-center gap-3'>
                            <p className='lg:text-3xl font-medium text-black'>Created by </p>

                            <p className='lg:text-2xl font-light text-yellow-500'>@{generateduser}</p>
                        </div>
                        {loading2 ?
                            <>
                                <div className="flex items-center justify-center w-[350px] h-[400px] lg:w-[400px] xl:w-[500px] lg:h-[500px] xl:h-[600px] 2xl:w-[570px] 2xl:h-[621px]">
                                    <div className="loader"></div>
                                </div>
                            </>
                            :
                            <img src={GeneratedImage} style={{objectFit: 'cover'}} onClick={toggleModal} alt="Generated Image" className='w-[350px] h-[400px] lg:w-[400px] xl:w-[500px] lg:h-[500px] xl:h-[600px] 2xl:w-[570px] 2xl:h-[621px] rounded-xl' />
                            // Want to enlarge this image when click on it----------------------------------------------------------------
                        }
                        {error && <div className="error-message">{error}</div>} {/* Display error */}
                        {isOpen && (
                            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 pointer-events-auto" style={{zIndex: "9999"}}>
                                <div className="bg-white p-4 rounded-lg">
                            {/* <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" >
                                <div className="bg-white p-4 rounded-lg"> */}
                                    <img
                                        src={GeneratedImage}
                                        alt="Enlarged"
                                        className='w-full h-auto max-w-2xl max-h-full rounded-lg'
                                    />
                                    <button
                                        className="mt-4 p-2 bg-blue-500 text-white rounded"
                                        onClick={toggleModal}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='flex flex-col gap-3'>
                        <div className='flex items-center gap-3'>
                            <VscHeartFilled color='#696969' className='lg:text-2xl' />
                            <p className='w-7 lg:w-14 lg:text-2xl font-medium text-stone-500 text-left'>8</p>
                            <div className="flex" style={{ cursor: 'pointer' }} onClick={downloadImage}>
                                {loading && <div className="loader"></div>}
                                <p className='lg:text-2xl font-medium hover:text-red-600"' >
                                    <i class="fa-solid fa-download"></i>  DOWNLOAD
                                </p>

                                {error && <div className="error-message">{error}</div>} {/* Error message */}
                            </div>
                        </div>
                        <div className="md:w-[700px] lg:w-[900px] lg:h-[500px] xl:w-[800px] xl:h-[600px] 2xl:w-[966px] 2xl:h-[621px] border-2 border-zinc-600 bg-[#36432e4a] rounded-xl py-3 px-5">
                            <div className='flex flex-wrap justify-start gap-2 md:gap-4 lg:gap-7 w-full text-[#42372e]'>
                                {promptData.map(item => (
                                    <p
                                        key={item.name}
                                        className={`cursor-pointer ${selectedName === item.name ? 'text-red-600 overline' : ''} md:text-xl font-semibold`}
                                        onClick={() => setSelectedName(item.name)}
                                    >
                                        {item.name}
                                    </p>
                                ))}
                            </div>
                            <div className="mt-5 text-[#352039] md:text-[23px] font-normal">
                                {selectedDescription ? selectedDescription : 'No description available'}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='border-t border-zinc-600 my-9'></div>

                <div className='flex items-center gap-6'>
                    <div className='bg-red-700 w-5 h-5 rounded-full'></div>
                    <p className='text-lg lg:text-2xl font-light text-yellow-500'>@{generateduser}</p>
                    <p className='text-xl text-stone-600 font-medium'>8 hours ago</p>
                </div>

                <p className='text-lg lg:text-2xl font-medium mt-1 lg:mt-6'>{drive}</p>
                <p className='text-3xl lg:text-4xl font-medium mt-4 lg:mt-[75px]'>Comment</p>
                <div className='bg-zinc-300 rounded-xl mt-1 lg:mt-2 px-5 py-3 text-black text-lg lg:text-2xl font-light w-full h-48'>
                    <input type="text" className='focus:outline-none bg-zinc-300 w-full' placeholder='Leave a comment' />
                </div>
                <button className='bg-red-800 w-40 lg:w-80 text-center py-2 rounded-full text-lg lg:text-2xl mt-3 lg:mt-4'>SUBMIT</button>

                <div className='border-t border-zinc-600 my-4 lg:my-9'></div>

                <p className='text-2xl lg:text-4xl font-medium mt-1 lg:mt-10 text-center'>You Might Also Enjoy</p>
                <DiscoverImagesBox />
            </div>
        </>
    );
}

export default ImageView;
