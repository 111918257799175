import React, { useState, useRef } from 'react';

const Accordion = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const contentRefs = useRef([]);

    const toggleCollapse = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const getMaxHeight = (index) => {
        if (contentRefs.current[index]) {
            return activeIndex === index ? `${contentRefs.current[index].scrollHeight}px` : '0px';
        }
        return '0px';
    };

    return (
        <>
            {/* Add the provided div block */}
            <div className="">
                <p className="font-manrope text-2xl font-bold mb-[5px] text-[#99000b]">
                    FAQ
                </p>
                <h2 className="text-black font-manrope text-4xl font-bold mb-[30px]">
                    You have questions? We have answers.
                </h2>
            </div>
            <div className="accordion-group" data-accordion="default-accordion">
                {/* Accordion 1 */}
                <div
                    className={`accordion pb-4 border-b-[3px] border-solid border-gray-200 ${activeIndex === 0 ? 'active' : ''}`}
                    id="basic-heading-one-with-arrow-always-open"
                >
                    <button
                        className="accordion-toggle group inline-flex items-center justify-between leading-8 text-gray-600 w-full transition duration-500 hover:text-indigo-600 accordion-active:text-indigo-600 always-open"
                        onClick={() => toggleCollapse(0)}
                        aria-controls="basic-collapse-one-with-arrow-always-open"
                    >
                        <h5 className='text-black font-bold text-xl'>How to create an account?</h5>
                        <svg
                            className={`text-gray-900 transition-transform duration-500 group-hover:text-indigo-600 accordion-active:text-indigo-600 ${activeIndex === 0 ? 'rotate-180' : ''}`}
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M16.5 8.25L12.4142 12.3358C11.7475 13.0025 11.4142 13.3358 11 13.3358C10.5858 13.3358 10.2525 13.0025 9.58579 12.3358L5.5 8.25"
                                stroke="currentColor"
                                strokeWidth="1.6"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                    <div
                        id="basic-collapse-one-with-arrow-always-open"
                        className="accordion-content w-full px-0 overflow-hidden pr-4 transition-max-height duration-500 ease-in-out"
                        ref={(el) => (contentRefs.current[0] = el)}
                        style={{ maxHeight: getMaxHeight(0) }}
                        aria-labelledby="basic-heading-one-with-arrow-always-open"
                    >
                        <p className="text-base text-gray-600 leading-6">
                            To create an account, find the 'Sign up' or 'Create account' button, fill out the registration
                            form with your personal information, and click 'Create account' or 'Sign up.' Verify your email
                            address if needed, and then log in to start using the platform.
                        </p>
                    </div>
                </div>

                {/* Accordion 2 */}
                <div
                    className={`accordion py-4 border-b-[3px] border-solid border-gray-200 ${activeIndex === 1 ? 'active' : ''}`}
                    id="basic-heading-two-with-arrow-always-open"
                >
                    <button
                        className="accordion-toggle group inline-flex items-center justify-between leading-8 text-gray-600 w-full transition duration-500 hover:text-indigo-600 accordion-active:text-indigo-600"
                        onClick={() => toggleCollapse(1)}
                        aria-controls="basic-collapse-two-with-arrow-always-open"
                    >
                        <h5 className='text-black font-bold text-xl'>Have any trust issue?</h5>
                        <svg
                            className={`text-gray-900 transition-transform duration-500 group-hover:text-indigo-600 accordion-active:text-indigo-600 ${activeIndex === 1 ? 'rotate-180' : ''}`}
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M16.5 8.25L12.4142 12.3358C11.7475 13.0025 11.4142 13.3358 11 13.3358C10.5858 13.3358 10.2525 13.0025 9.58579 12.3358L5.5 8.25"
                                stroke="currentColor"
                                strokeWidth="1.6"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                    <div
                        id="basic-collapse-two-with-arrow-always-open"
                        className="accordion-content w-full px-0 overflow-hidden pr-4 transition-max-height duration-500 ease-in-out"
                        ref={(el) => (contentRefs.current[1] = el)}
                        style={{ maxHeight: getMaxHeight(1) }}
                        aria-labelledby="basic-heading-two-with-arrow-always-open"
                    >
                        <p className="text-sm text-gray-500 leading-6">
                            Our focus on providing robust and user-friendly content management capabilities ensures that you
                            can manage your content with confidence, and achieve your content marketing goals with ease.
                        </p>
                    </div>
                </div>

                {/* Accordion 3 */}
                <div
                    className={`accordion py-4 border-b-[3px] border-solid border-gray-200 ${activeIndex === 2 ? 'active' : ''}`}
                    id="basic-heading-three-with-arrow-always-open"
                >
                    <button
                        className="accordion-toggle group inline-flex items-center justify-between leading-8 text-gray-600 w-full transition duration-500 hover:text-indigo-600 accordion-active:text-indigo-600"
                        onClick={() => toggleCollapse(2)}
                        aria-controls="basic-collapse-three-with-arrow-always-open"
                    >
                        <h5 className='text-black font-bold text-xl'>How can I reset my password?</h5>
                        <svg
                            className={`text-gray-900 transition-transform duration-500 group-hover:text-indigo-600 accordion-active:text-indigo-600 ${activeIndex === 2 ? 'rotate-180' : ''}`}
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M16.5 8.25L12.4142 12.3358C11.7475 13.0025 11.4142 13.3358 11 13.3358C10.5858 13.3358 10.2525 13.0025 9.58579 12.3358L5.5 8.25"
                                stroke="currentColor"
                                strokeWidth="1.6"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                    <div
                        id="basic-collapse-three-with-arrow-always-open"
                        className="accordion-content w-full px-0 overflow-hidden pr-4 transition-max-height duration-500 ease-in-out"
                        ref={(el) => (contentRefs.current[2] = el)}
                        style={{ maxHeight: getMaxHeight(2) }}
                        aria-labelledby="basic-heading-three-with-arrow-always-open"
                    >
                        <p className="text-sm text-gray-500 leading-6">
                            Our focus on providing robust and user-friendly content management capabilities ensures that you
                            can manage your content with confidence, and achieve your content marketing goals with ease.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Accordion;


{/* <div className='bg-red-800 pb-32'>
            <div>
                <p className='text-center text-[36px] text-white py-20'>Frequently asked questions</p>
                <div className='border border-slate-100 mx-5'>
                    {accordionData.map((item, index) => (
                        <div className="relative " key={index}>
                            <h6 className="mb-0">
                                <button
                                    className={`relative flex items-center w-full p-4 font-semibold text-left transition-all border-b-1 border-t ease-in  cursor-pointer  text-white  group text-dark-500 ${activeIndex === index ? 'active' : ''}`}
                                    onClick={() => toggleCollapse(index)}
                                    style={{
                                        backgroundColor: activeIndex === index ? 'black' : '',
                                        color: activeIndex === index ? 'white' : 'black',
                                    }}
                                >
                                    <span className='text-xl' style={{ color: 'white' }}>{item.title}</span>
                                    <i className={`absolute right-0 pt-1 pr-5 text-xl font-bold fa ${activeIndex === index ? 'fa-minus text-black' : 'fa-plus text-white'}`}></i>
                                </button>
                            </h6>
                            <div
                                className={`overflow-hidden transition-all duration-300 ease-in-out ${activeIndex === index ? 'h-auto' : 'h-0'}`}
                            >
                                <div className="px-8 pb-10 pt-4 text-sm leading-normal text-white text-[20px]">
                                    {item.content}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div> */}
