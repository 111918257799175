import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Togal from './Togal';
import { DeleteImg, rename } from '../../assets';
import DeletePopup from './DeletePopup';
import axios from 'axios';

const BASE_URL = "https://api2.eromantic.ai/api/";

const BlogListCard = ({ img, title, subtext, blogId, blog_publish}) => {
    const navigate = useNavigate();
//    const getID = window.



    const handleBlog = async () => {
        try {
            const authToken = localStorage.getItem('authToken');
            const response = await axios.get(`https://api2.eromantic.ai/api/details_blog/${blogId}/`, {
            headers: {
                'Authorization': `Token ${authToken}`  // or 'Bearer' for JWT tokens
            }
        });
            navigate(`/admin/singleBlog/${blogId}`, { state: { blog: response.data } });
        } catch (error) {
            console.error('Error fetching blog details:', error);
        }
    };

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const handleDelete = async () => {
        try {
            const authToken = localStorage.getItem('authToken');
            await axios.delete(`https://api2.eromantic.ai/api/delete_blog/${blogId}/`, {
            headers: {
                'Authorization': `Token ${authToken}`
                }
            });
            setIsPopupOpen(false);
            window.location.reload(); // Reload the page to update the list
        } catch (error) {
            console.error('Error deleting blog:', error);
        }
    };
    const handleStatusChange = async (event) => {
        try {
            const data = new FormData();
            data.append('blog_publish', event.target.checked);
            const authToken = localStorage.getItem('authToken');
            const response = await fetch(`https://api2.eromantic.ai/api/update_blog_status/${blogId}/`, {
            method: 'PUT',
            body: data,
            headers: {
                'Authorization': `Token ${authToken}`,
            },
        });
            console.log(response.json());
        } catch (error) {
            console.error('Error fetching blog details:', error);
        }
    };

    return (
        <>
            <div className='border-2 border-zinc-300 rounded-lg w-[307px] font-josefin-sans'>
                <div className='flex justify-between p-3 items-center'>
                    <Togal handleChange={handleStatusChange} value={blog_publish}/>
                    <div className='flex justify-center gap-3 items-center'>

                        <img src={rename} alt="editImg" className='hover:cursor-pointer' onClick={() => navigate(`/admin/EditBlog/${blogId}`)} />
                        <img src={DeleteImg} alt="deleteImg" className='hover:cursor-pointer' onClick={togglePopup} />
                    </div>
                </div>
                <div onClick={handleBlog} className='hover:cursor-pointer'>
                    <img src={img} alt="blogListCard" className='w-[307px] h-[173px]' />
                    <div className='text-left p-[14px]'>
                        <p className='text-black text-lg font-semibold'>{title}</p>
                        <p className='text-zinc-600 text-[12px] font-medium pt-[5px] pb-[12px] '>Create at 22/05/2024</p>
                        <p
                            className='text-zinc-600 text-[14px] font-normal leading-tight break-words'
                        >
                         <div
                                dangerouslySetInnerHTML={{ __html: subtext}}
                            />

                        </p>
                    </div>
                </div>
            </div>

            {isPopupOpen && (
                <DeletePopup togglePopup={togglePopup} handleDelete={handleDelete} />
            )}
        </>
    );
};

export default BlogListCard;
