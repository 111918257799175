import React, { useState, useEffect } from 'react';
import BlogListCard from '../../../components/BlogListCard';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PagenameSearchBox from '../../../components/PagenameSearchBox';

const BASE_URL = "https://api2.eromantic.ai/api/";

const BlogList = () => {
    const navigate = useNavigate();
    const [blogs, setBlogs] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const authToken = localStorage.getItem('authToken');
                const response = await axios.get(`${BASE_URL}show_blog_post/`, {
                    headers: {
                        'Authorization': `Token ${authToken}`
                    }
                });
                setBlogs(response.data);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        fetchBlogs();
    }, []);

    const handleBlog = () => {
        const authToken = localStorage.getItem('authToken');
        if (!authToken) {
            console.error('No auth token found. Please log in.');
            return;
        }
        navigate('/admin/createBlog');
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredBlogs = blogs.filter(blog => 
        blog.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className='bg-white px-7 pb-20 font-josefin-sans'>
            <PagenameSearchBox
                title='Blog List'
                placename='Search Blog'
                btnname='CREATE BLOG'
                btnfunction={handleBlog}
                onSearchChange={handleSearchChange} // Ensure this is passed correctly
            />
            <div className='flex flex-wrap justify-start gap-8 pt-8'>
                {filteredBlogs.map((blog) => (
                    <BlogListCard
                        key={blog.blog_id}
                        blogId={blog.blog_id}
                        img={blog.image}
                        title={blog.title}
                        subtext={''}
                        blog_publish={blog.blog_publish}
                    />
                ))}
            </div>
        </div>
    );
};

export default BlogList;
