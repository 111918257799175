import React, { useState } from 'react';
import axios from 'axios';
import Footer from '../../../frontend/components/Footer';

const AffiliateSignupPage = () => {
    const [referralLink, setReferralLink] = useState('');
    const [affiliateId, setAffiliateId] = useState(''); // New state to store affiliate ID
    const [formData, setFormData] = useState({
        website: '',
        referralSource: '',
    });

    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.website) newErrors.website = "Website or social profile is required";
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            const response = await axios.post('https://your-backend-api.com/affiliate-signup', formData);

            if (response.status === 200) {
                const { affiliate_id } = response.data;
                const generatedLink = `https://www.eromantic.ai?affiliate=${affiliate_id}`;
                
                setAffiliateId(affiliate_id); // Set affiliate ID
                setReferralLink(generatedLink); // Set referral link
                setSubmitted(true);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setErrors({ form: 'There was an issue submitting your application. Please try again.' });
        }
    };

    return (
        <>
            <div className="container mx-auto px-6 py-12">
                <h1 className="text-3xl font-semibold text-center mb-8">Affiliate Program Signup</h1>
                <p className="text-center text-lg mb-12">
                    Join our affiliate program and start earning by promoting our platform. Please fill out the form below to apply.
                </p>

                {!submitted ? (
                    <form onSubmit={handleSubmit} className="max-w-lg mx-auto bg-white shadow-md rounded-lg p-8">
                        <div className="mb-6">
                            <label htmlFor="website" className="block text-gray-700 font-medium mb-2">Website/Social Profile</label>
                            <input
                                type="text"
                                id="website"
                                name="website"
                                value={formData.website}
                                onChange={handleChange}
                                className={`w-full px-4 py-2 border rounded-md ${errors.website ? 'border-red-500' : 'border-gray-300'}`}
                                placeholder="Link to your website or social profile"
                            />
                            {errors.website && <p className="text-red-500 text-sm mt-1">{errors.website}</p>}
                        </div>

                        <div className="mb-6">
                            <label htmlFor="referralSource" className="block text-gray-700 font-medium mb-2">How did you hear about us?</label>
                            <input
                                type="text"
                                id="referralSource"
                                name="referralSource"
                                value={formData.referralSource}
                                onChange={handleChange}
                                className="w-full px-4 py-2 border border-gray-300 rounded-md"
                                placeholder="Optional"
                            />
                        </div>

                        {errors.form && <p className="text-red-500 text-sm mt-4">{errors.form}</p>}

                        <button
                            type="submit"
                            className="w-full bg-[#ea3333d1] text-white py-3 rounded-md text-lg font-semibold hover:bg-purple-700 transition duration-200"
                        >
                            Submit Application
                        </button>
                    </form>
                ) : (
                    <div className="text-center mt-8">
                        <h2 className="text-2xl font-semibold mb-4">Thank You for Joining Our Affiliate Program!</h2>
                        <p className="text-lg mb-4">Your Affiliate ID:</p>
                        <p className="text-lg font-semibold text-blue-500 break-all">{affiliateId}</p>
                        <p className="text-lg mt-4">Share your referral link to start earning:</p>
                        <p className="text-lg font-semibold text-blue-500 break-all">{referralLink}</p>
                    </div>
                )}

                <div className="text-center mt-8">
                    <p className="text-lg">
                        For questions or assistance, contact our support team at 
                        <a href="mailto:support@eromantic.ai" className="text-blue-500 ml-1">support@eromantic.ai</a>.
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default AffiliateSignupPage;
