import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoThumbsDownOutline, IoThumbsUpOutline, IoVolumeHighSharp } from 'react-icons/io5';
import axios from 'axios';
import { PiUserSoundFill } from "react-icons/pi";
import { FiAlignJustify, } from "react-icons/fi";
import { IoCloseSharp } from "react-icons/io5";
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import Swal from 'sweetalert2';
import {
    img1, img3, img4, img7, pg12, pg22, pg23, pg24, pg25, pg26, brown1, AURORA1, AURORA2, ELORA1, ELORA2, LISA1, LISA2, MYRA1, MYRA2, NOLA1, NOLA2, RHEA1, RHEA2, YESENIA1, YESENIA2, ZEMIRA1, ZEMIRA2, ZULEIKA1, ZULEIKA2, GIANINA1, GIANINA2, whitebg, Bodyicon,
    Bodyback, Relationshipicon, Location, age, brest, country, eyes, hair, occupation, personality, abrafo1, abrafo2, akshat1, akshat2, brian1, brian2,
    charles1, charles2, daniel1, daniel2, joseph1, joseph2, joshua1, joshua2, matthew1, matthew2, thomas1, thomas2, william1, william2, ashley1,
    ashley2, avni1, avni2, emma1, emma2, grace1, grace2, hannah1, hannah2, izumi1, izumi2, nailah1, nailah2, olivia1, olivia2, sarah1, sarah2,
    sophia1, sophia2, CONNOR1, CONNOR2, JACK1, JACK2, LUKE1, LUKE2, ETHAN1, ETHAN2, OZAN1, OZAN2, JUNG1, JUNG2, HORO1, HORO2, ANDREW1, ANDREW2, JACOB1, JACOB2,
    HENRY1, HENRY2,
} from '../../../assets';

const BASE_URL = "https://api2.eromantic.ai/assistant_api/";

const Aidreamchat = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [assistants, setAssistants] = useState([]);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [message, setMessage] = useState('');
    const [assistant_id, setActiveAssistantId] = useState(null);
    const [characterid, setCharacterId] = useState(null);
    const [isLoadingAIResponse, setIsLoadingAIResponse] = useState(false);
    const [activeAssistantName, setActiveAssistantName] = useState('');
    const [activeAssistantdetails, setActiveAssistantdetails] = useState('');
    const [messagesHistory, setMessagesHistory] = useState([]);
    const [messages, setMessages] = useState([]);
    const [audio, setAudio] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [voicemessage, setVoiceMessage] = useState('');
    const [userimg, setUserimg] = useState('');
    const u_id = localStorage.getItem('u_id');
    const scrollbarRef = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('photo');
    const [speakingIndex, setSpeakingIndex] = useState(null);
    const [refreshChats, setRefreshChats] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [ismsgVisible, setIsmsgVisible] = useState(true);

    const NewGenerate = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${BASE_URL}bot_fetch/?user_id=${u_id}`);
            if (response.status === 200) {
                // setUserimg(response.data?.data[response.data.data.length-1].Image)
                console.log(response.data, "shfdsF")
                setUserimg(response.data?.data[0].Image)
                setAssistants(response.data.data);
                setActiveAssistantId(response.data.data[0].assistant_id);
                setActiveAssistantName(response.data.data[0].name);
                setActiveAssistantdetails(response.data.data[0]);
                setCharacterId(response.data.data[0].thread_id);
            } else {
                setError('Unexpected response from the server.');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Failed to fetch data. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        NewGenerate();
    }, []);


    useEffect(() => {
        scrollToBottom();
    }, [messagesHistory, messages]); // This will trigger scrolling every time 'mymessages' changes

    const scrollToBottom = () => {
        setTimeout(() => {
            if (scrollbarRef.current) {
                scrollbarRef.current.scrollTop = scrollbarRef.current.scrollHeight;
            }
        }, 100); // Delay might be needed to wait for the DOM to update
    };

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };
    const toggleVisibilitymsg = () => {
        setIsmsgVisible(!ismsgVisible);
    };

    useEffect(() => {
        // Fetch old messages when character_id changes
        const fetchOldMessages = async () => {
            if (characterid) {
                try {
                    const response = await axios.get(`${BASE_URL}fetch_history/?thread_id=${characterid}`);
                    if (response.status === 200 || response.status === 201) {
                        const oldMessages = response.data || [];
                        const formattedMessages = [];

                        // Create an array of message objects
                        oldMessages.forEach(msg => {
                            if (msg.user_message) {
                                formattedMessages.push({
                                    sender: 'user',
                                    text: msg.user_message || 'No message',
                                    time: new Date(msg.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                                });
                            }
                            if (msg.ai_response) {
                                formattedMessages.push({
                                    sender: 'ai',
                                    text: msg.ai_response || 'No response',
                                    time: new Date(msg.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                                });
                            }
                        });

                        // Sort messages by timestamp to ensure proper order
                        formattedMessages.sort((a, b) => new Date(a.time) - new Date(b.time));

                        // Update messages history
                        setMessagesHistory(formattedMessages);
                    } else {
                        console.error("Unexpected response status:", response.status);
                    }
                } catch (error) {
                    console.error('Error fetching message history:', error);
                }
            }
        };

        fetchOldMessages();
    }, [characterid]);

    const getCurrentTime = () => {
        const now = new Date();
        return now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const handleClick = async (assistantId, assistantName, thread_id, assistantImage, assistent) => {
        console.log(thread_id, "gagsasgas")
        setActiveAssistantId(assistantId);
        setActiveAssistantName(assistantName);
        setActiveAssistantdetails(assistent)
        setUserimg(assistantImage || "/static/media/img1.9322f9a528d87450122a.png")
        const effectiveId = thread_id !== undefined ? thread_id : characterid;
        setCharacterId(effectiveId);
    };
    console.log("checkkkkkkk", assistants)

    const SendMsg = async () => {
        if (!assistant_id || !message.trim()) {
            console.warn("Assistant ID or message is not valid");
            return;
        }
        const userMessage = message;
        setMessages((prevMessages) => [
            ...prevMessages,
            {
                text: message,
                sender: "user",
                time: getCurrentTime()
            },
        ]);
        setMessage('');
        setIsLoadingAIResponse(true); // Set loading state

        const payload = { assistant_id, message: message };
        try {
            const authToken = localStorage.getItem('token');
            const response = await axios.post(`${BASE_URL}bot_chat/`, payload, {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });
            if (response.status === 200 || response.status === 201) {
                const aiResponseMessage = response.data.response_message;
                const ImgMsg = response.data.img_url;
                const ChatTime = response.data.chat_time;
                setMessages((prev) => [
                    ...prev,
                    {
                        text: aiResponseMessage,
                        chatimg: ImgMsg,
                        msgtime: ChatTime,
                        sender: "bot",
                        time: getCurrentTime()
                    },
                ]);
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error) {
            console.error('Error sending message:', error);
        } finally {
            setIsLoadingAIResponse(false); // Reset loading state
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            const trimmedMessage = message.trim();
            if (trimmedMessage) {
                SendMsg(trimmedMessage);
                setMessage(''); // Clear the input field after sending
            }
        }
    };
    useEffect(() => {
        const fetchOldMessages = async () => {
            if (characterid) {
                try {
                    const response = await axios.get(`${BASE_URL}fetch_history/?thread_id=${characterid}`);
                    if (response.status === 200 || response.status === 201) {
                        setMessagesHistory(response.data);
                    } else {
                        console.error("Unexpected response status:", response.status);
                    }
                } catch (error) {
                    console.error('Error fetching message history:', error);
                }
            }
        };

        fetchOldMessages();
        console.log(characterid, "[]]]]]]]]]]]]]]]]]]");
        
    }, [characterid, refreshChats]); //

    const startRecording = () => {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        const recognition = new SpeechRecognition();

        recognition.onstart = () => {
            setIsRecording(true);
        };

        recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            setMessage(transcript);
        };

        recognition.onerror = (event) => {
            console.error('Error occurred in recognition: ' + event.error);
        };

        recognition.onend = () => {
            setIsRecording(false);
            const enterEvent = new KeyboardEvent('keydown', { key: 'Enter' });
            handleKeyPress(enterEvent);
        };

        recognition.start();
    };


    const speak = (audioUrl) => {
        const audio = new Audio(audioUrl);
        audio.play();
    };
    
    const handlePlayAudiomsg = async (msg, index) => {
        try {
            setSpeakingIndex((prevIndex) => (prevIndex === index ? null : index));
            const response = await axios.post(`${BASE_URL}text_audio/`, { msg: msg.ai_response, assistant_id: msg.assistant_id });
            if (response.data && response.data.audio_url) {
                const audioUrl = response.data.audio_url;
                speak(audioUrl);
            } else {
                console.error("Audio URL not found in the response.");
            }
        } catch (error) {
            console.error("Error fetching audio:", error);
        }
    };

    const toggleChat = () => {
        setIsOpen(prev => !prev);
    };
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const deletechat = (thread_id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const authToken = localStorage.getItem('token');
                    const payload = { thread_id }; // Define the payload with the thread_id
                    // const response = await axios.delete(`${BASE_URL}bot_delete/`, payload);
                    const response = await axios.delete(`${BASE_URL}chat_delete/`, { data: payload }, {
                        headers: {
                            'Authorization': `Bearer ${authToken}`
                        }
                    });

                    if (response.status === 200 || response.status === 201) {
                        // Show success message after API call
                        // setRefreshChats(prev => !prev);
                        setMessagesHistory([]);
                        setMessages([]);
                        Swal.fire({
                            title: "Deleted!",
                            text: "Your chat has been deleted.",
                            icon: "success"
                        });
                    } else {
                        console.error("Unexpected response status:", response.status);
                    }
                } catch (error) {
                    console.error('Error sending message:', error);
                } finally {
                    setIsLoadingAIResponse(false); // Reset loading state
                }
            }
        });
    };

    const deletecharacter = (assistant_id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const authToken = localStorage.getItem('token');
                    const payload = { assistant_id };
                    const response = await axios.delete(`${BASE_URL}bot_delete/`, { data: payload}, {
                        headers: {
                            'Authorization': `Bearer ${authToken}`
                        }
                     });

                    if (response.status === 200 || response.status === 201) {
                        setMessagesHistory([]);
                        setMessages([]);
                        Swal.fire({
                            title: "Deleted!",
                            text: "Your Character has been deleted.",
                            icon: "success"
                        });
                    } else {
                        console.error("Unexpected response status:", response.status);
                    }
                } catch (error) {
                    console.error('Error sending message:', error);
                } finally {
                    setIsLoadingAIResponse(false);
                }
            }
        });
    };

    const handleImageClick = (imgUrl) => {
        setSelectedImage(imgUrl);
        setIsModalOpen(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedImage(null);
    };


    console.log('aaaaaaaaaaa========', assistants)

    return (
        <div className="relative flex h-full flex-1 shrink-0 flex-col items-stretch overflow-hidden xl:pb-0">
            <div className="flex-1 shrink-0 overflow-auto">
                <div className="relative z-0 flex h-full w-full  3xl:max-w-full justify-center">
                    <div className="absolute left-0 top-0 px-2 py-4 3xl:p-4  z-bg cursor-default opacity-0  -translate-x-1/2 space-x-2 3xl:translate-x-0   flex transform items-center  ">
                        <div className="  h-10 w-10 hidden 3xl:flex">
                            <img
                                src="/image/logo-white.svg"
                                className=" w-full  cursor-pointer"
                            />
                        </div>
                        <button className="flex justify-center items-center border text-sm h-11 w-11 rounded-2xl border-none bg-black-6/50">
                            <span className="iconfont-custom icon-pickupLiftIcon text-5xl font-light text-white/70" />
                        </button>
                    </div>
                    <div className="absolute right-0  top-0 z-subtitle py-4 pr-2 lg:pr-4 z-bg opacity-0 z-subtitle opacity-100 xl:flex  3xl:hidden">
                        <button className="flex justify-center items-center border text-sm h-11 w-11 rounded-2xl border-none bg-black-6/50">
                            <span className="iconfont-custom icon-packupIcon text-5xl font-light text-white/70" />
                        </button>
                    </div>
                    <div className=" absolute right-0  top-0 z-subtitle py-4 pr-2 lg:pr-4   hidden">
                        <button className="justify-center items-center border text-sm hidden h-11 w-11 rounded-2xl border-none bg-black-6/50 2xl:flex">
                            <span className="iconfont-custom icon-packupIcon text-5xl font-light text-white/70" />
                        </button>
                    </div>
                    <div
                        className={`w-full basis-2/5 transform transition-all duration-200 ${ismsgVisible ? '3xl:flex' : 'hidden'
                            } min-h-screen`}
                    >
                        <div className="relative flex flex-col h-full w-full overflow-hidden duration-1000 animate-in fade-in">
                            <div className="flex w-full items-center justify-between py-4 pl-6 pr-2 font-semibold text-white/70">
                                <div className="flex items-center">
                                    <span className="text-xl text-[#484848]">Messages</span>
                                </div>
                                <button onClick={toggleVisibilitymsg} class="flex justify-center items-center border text-sm h-11 w-11 rounded-2xl border-none bg-[#0000009c]">
                                    <span class="iconfont-custom icon-pickuprightIcon text-5xl font-light text-white/70"></span>
                                </button>
                            </div>
                            <div className="flex-grow flex items-center justify-center overflow-auto">
                                {assistants.length === 0 ? (
                                    <>
                                        <img
                                            alt="chat-placeholder"
                                            loading="lazy"
                                            width={92}
                                            height={104}
                                            decoding="async"
                                            data-nimg={1}
                                            className="h-auto mt-30 w-1/2"
                                            src="/image/nochat.svg"
                                            style={{ color: "transparent" }}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <ul className='w-full'>
                                            {assistants.map((assistant) => (
                                                <li
                                                    key={assistant.assistant_id}
                                                    className="flex items-center justify-evenly  border-[#000] text-black rounded-xl bg-[#0e0e0e3d] min-h-[5rem] my-[10px] mx-[10px] p-2 cursor-pointer"
                                                    onClick={() =>
                                                        handleClick(
                                                            assistant.assistant_id,
                                                            assistant.name,
                                                            assistant.thread_id,
                                                            assistant?.Image,
                                                            assistant
                                                        )
                                                    }
                                                >
                                                    <div>
                                                        <img
                                                            src={
                                                                assistant?.Image ||
                                                                "/static/media/img1.9322f9a528d87450122a.png"
                                                            }
                                                            alt="userProfile"
                                                            className="w-14 h-14 rounded-full"
                                                        />
                                                    </div>
                                                    <div className="px-2">
                                                        <div className="flex items-center justify-between">
                                                            <p className="text-xl">
                                                                {assistant.name || "Unnamed Assistant"}
                                                                <span className="inline-block w-3 h-3 bg-green-600 rounded-full mx-3 mb-[2px] border-2 border-green-600 shadow-md shadow-green-800"></span>
                                                            </p>
                                                            <p className="text-xl">{getCurrentTime()}</p>
                                                        </div>
                                                        <div style={{ width: "10rem" }}>
                                                            <span className="text-sm">
                                                                {assistant.message || "No message available."}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                )}

                            </div>
                        </div>
                    </div>

                    <div className="relative flex w-full grow justify-center ">
                        <img
                            alt="background"
                            fetchpriority="high"
                            width={1000}
                            height={1200}
                            decoding="async"
                            data-nimg={1}
                            className="absolute z-bg h-full w-full  object-cover object-center  "
                            src={userimg ? userimg : '/static/media/img1.9322f9a528d87450122a.png'}
                            style={{ color: "transparent", filter: 'blur(10px)' }}
                        />
                        <div className="absolute inset-0 z-overlay hidden bg-black-11/20 backdrop-blur-xl sxl:flex" />
                        <div className="h-100 absolute top-0 z-overlay w-full bg-gradient-row-black-2" />
                        <div className="absolute top-0 z-overlay h-full w-full  bg-black/[0.26]" />
                        <div
                            className={`absolute left-0 chatback2 top-0 z-subtitle py-4 pl-2 lg:pl-4 ${ismsgVisible ? 'hidden' : '3xl:flex'
                                }`}>
                            <button onClick={toggleVisibilitymsg} className="flex justify-center items-center border text-sm h-11 w-11 rounded-2xl border-none bg-[#0000009c]">
                                <span className="iconfont-custom icon-pickupLiftIcon text-5xl font-light text-white/70" />
                            </button>
                        </div>
                        <div className="h-200 absolute bottom-0 z-overlay w-full bg-gradient-row-black-3" />
                        <div className=" flex w-full max-w-2xl flex-col">
                            <div className="relative flex h-16 w-full items-center">
                                <div className="relative flex h-full w-full items-center justify-between bg-transparent p-3 pb-1 mr-[30px] lg:mx-0">
                                    <div className="flex items-center gap-5">
                                        <button className="xl:hidden ml-[30px] ">
                                            <span className="iconfont-custom icon-hanburgerIcon text-5xl" />
                                        </button>
                                        <div className="flex items-center gap-2">
                                            <div
                                                className={`absolute left-0  top-0 z-subtitle py-4 pl-2 lg:pl-4 chatback mr-[30px] ${ismsgVisible ? 'hidden' : '3xl:flex'
                                                    }`}>
                                                <button onClick={toggleVisibilitymsg} className="flex justify-center items-center border text-sm h-11 w-11 rounded-2xl border-none bg-[#0000009c]">
                                                    <span className="iconfont-custom icon-pickupLiftIcon text-5xl font-light text-white/70" />
                                                </button>
                                            </div>
                                            <div
                                                className="relative shrink-0 grow-0 rounded-full h-10 w-10"
                                                style={{ padding: 0 }}
                                            >
                                                <img
                                                    alt="avatarPlaceholder"
                                                    width={10}
                                                    height={10}
                                                    className="absolute inset-0 z-overlay h-full w-full rounded-full"
                                                    src={userimg}
                                                    style={{ color: "transparent" }}
                                                />
                                            </div>
                                            <div className="flex flex-col text-base">
                                                <span className="line-clamp-1 font-medium text-lg text-white">
                                                    {activeAssistantName}
                                                </span>
                                                {/* <div className="flex items-center">
                                                    <span className="mr-1 text-xs text-white/[0.98]">
                                                        Lv1
                                                    </span>
                                                    <div className="relative h-2 w-14 rounded-full bg-black/30">
                                                        <div
                                                            className="absolute h-2 transform rounded-full bg-white/80 duration-200"
                                                            style={{ width: "0%" }}
                                                        />
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <div
                                            className="relative flex h-fit w-fit focus-visible:border-none"
                                            data-headlessui-state=""
                                        >
                                            <Menu as="div" className="relative inline-block text-left">
                                                <div>
                                                    <Menu.Button className="focus-visible:transparent focus:outline-none">
                                                        <div className="flex h-7 w-7 items-center justify-center rounded-full bg-white hover:bg-gray-200 focus-visible:shadow-none">
                                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                                        </div>
                                                    </Menu.Button>
                                                </div>

                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-[115px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        <div className="py-1">
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <button
                                                                        onClick={() => deletechat(characterid)}
                                                                        className={`${active ? 'bg-red-600 text-white' : 'text-gray-900'
                                                                            } block px-4 py-2 text-sm`}
                                                                    >
                                                                        Reset Chat
                                                                    </button>
                                                                )}
                                                            </Menu.Item>
                                                        </div>
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>
                                        <div
                                            hidden=""
                                            style={{
                                                position: "fixed",
                                                top: 1,
                                                left: 1,
                                                width: 1,
                                                height: 0,
                                                padding: 0,
                                                margin: "-1px",
                                                overflow: "hidden",
                                                clip: "rect(0px, 0px, 0px, 0px)",
                                                whiteSpace: "nowrap",
                                                borderWidth: 0,
                                                display: "none"
                                            }}
                                        />
                                        <h2
                                            id="radix-:r5g:"
                                            className="text-lg font-semibold leading-none tracking-tight"
                                        />
                                        <h2
                                            id="radix-:r5j:"
                                            className="text-lg font-semibold leading-none tracking-tight"
                                        />
                                        <h2
                                            id="radix-:r5m:"
                                            className="text-lg font-semibold leading-none tracking-tight"
                                        />
                                        <h2
                                            id="radix-:r5p:"
                                            className="text-lg font-semibold leading-none tracking-tight"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="relative z-0 flex h-full w-full flex-1 overflow-hidden">
                                <div className='h-[20rem] lg:h-full w-full py-[20px] px-5 overflow-auto' ref={scrollbarRef} style={{ scrollbarWidth: "none" }}>
                                    {messagesHistory.length === 0 && <p>{message}</p>}
                                    {messagesHistory.map((msg, index) => (
                                        <>
                                            <div key={index} className={`flex justify-end m-6`}>
                                                {
                                                    msg.user_message ?
                                                        <div className={`userchat max-w-[60%] border-[#88ceff] flex p-2.5 pl-4`}>
                                                            {msg.user_message}
                                                        </div>
                                                        :
                                                        ""
                                                }

                                            </div>
                                            <div className={`flex flex-col justify-start m-6`}>
                                                <div className={`aichat justify-between max-w-[60%] border-[#88ceff] flex p-2.5 pl-4`}>
                                                    {msg.ai_response}
                                                    <div className=''>
                                                        {/* <div
                                                className='text-red-500 bg-[#ffffff] p-[5px] rounded-full shadow-lg'
                                                onClick={() => handlePlayAudiomsg(msg)}
                                            >
                                                {isSpeaking ? <PiUserSoundFill /> : <IoVolumeHighSharp />}
                                            </div> */}
                                                        <div className='text-red-500 bg-[#ffffff] p-[5px] rounded-full shadow-lg' onClick={() => handlePlayAudiomsg(msg, index)}>
                                                            {speakingIndex === index ? <PiUserSoundFill /> : <IoVolumeHighSharp />}
                                                        </div>
                                                        {/* <div>{msg.timestamp}</div> */}
                                                    </div>
                                                </div>
                                                {
                                                    msg.img_url ?
                                                        <div>
                                                            <img src={msg.img_url} alt="img" className="m-2 rounded-xl" style={{ width: "25%" }} />
                                                        </div>
                                                        :
                                                        ""
                                                }
                                            </div>
                                        </>
                                    ))}
                                    {messages.map((msg, index) => (
                                        <>
                                            {msg.sender === "user" ? (
                                                <div key={index} className={`flex justify-end m-6`}>
                                                    <div className={`userchat max-w-[60%] border-[#88ceff] flex p-2.5 pl-4`}>
                                                        {msg.text}
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    <div className={`flex flex-col justify-start m-6`}>
                                                        <div className={`aichat justify-between max-w-[60%] border-[#88ceff] flex p-2.5 pl-4`}>
                                                            {msg.text}
                                                            <div className=''>
                                                                <div className='text-red-500 bg-[#ffffff] p-[5px] rounded-full shadow-lg'>
                                                                    {speakingIndex === index ? <PiUserSoundFill /> : <IoVolumeHighSharp />}
                                                                </div>
                                                            </div>
                                                            {/* <div>{msg.msgtime}</div> */}
                                                        </div>
                                                        {
                                                            msg.chatimg ?
                                                                <div>
                                                                    <img src={msg.chatimg} alt="img" className="m-2 rounded-xl" style={{ width: "25%" }} />
                                                                </div>
                                                                :
                                                                ""
                                                        }

                                                    </div>
                                                </>
                                            )}
                                        </>
                                    ))}

                                    {/* Show loader dots when waiting for AI response */}
                                    {isLoadingAIResponse && (
                                        <div className={`aichat justify-between max-w-[20%] m-6 border-[#88ceff] flex p-2.5 pl-6`}>
                                            <div className='loaderdots'></div>
                                        </div>
                                    )}
                                </div>
                                {/* <div className="relative flex h-[40.4rem] w-full flex-col overflow-auto pt-20">
                                    <div className="mx-auto mb-5 mt-auto flex w-9/10 items-end gap-2 break-words rounded-2xl bg-[#39373E] px-4 text-lg text-gray-1 opacity-70 backdrop-blur-[10px] md:text-base py-3">
                                        <span className="w-full line-clamp-4 overflow-hidden whitespace-normal">
                                            You are appreciating Colin playing bass for his own band at a
                                            bar tonight. He seems to enjoy your staring during the
                                            performance.{" "}
                                        </span>
                                    </div>
                                    <div className="bg-red m-3 mb-8 flex items-end" />
                                </div> */}

                            </div>
                            <div className="w-full px-2 pb-2 mb-[6rem]">
                                <div className=" w-full  pt-1">
                                    <div className="overflow-hidden pt-2">
                                        <div className="mb-2 flex h-12 gap-2">
                                            <div className="relative mx-auto flex flex-1 items-center overflow-hidden rounded-full px-4 p-1 bg-[#bdb1b1]">
                                                <div className="absolute bottom-0 right-0 top-0  flex  items-center justify-center z-bg opacity-0 pr-3 text-gray-3">
                                                    Long-press recording
                                                </div>
                                                <input
                                                    type="text"
                                                    className="placeholder: flex-1 border-0 bg-transparent pr-1 text-white caret-purple-1 placeholder:text-white placeholder:opacity-40 focus:outline-none focus:ring-0"
                                                    placeholder={isRecording ? 'Recording...' : 'Start typing...'}
                                                    value={message}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                    onKeyPress={handleKeyPress}
                                                />
                                                <div className="flex items-center relative z-subtitle  h-10 w-10">
                                                    <i class="fa-solid fa-paper-plane" style={{ color: '#fff', fontSize: "22px" }}></i>
                                                </div>
                                            </div>
                                            <div
                                                className=""
                                                style={{ touchAction: "none", zIndex: 10, cursor: "pointer" }}
                                            >
                                                <div style={{ justifySelf: "end", transform: "scale(0.5)" }} />
                                                <div style={{ transform: "none" }}>
                                                    <div className="relative  flex h-12 w-12 items-center justify-center rounded-full bg-[#bdb1b1] ">
                                                        <span
                                                            className="absolute left-[-140px] z-subtitle  text-gray-3 sm:left-[-120px] md:left-[-140px] lg:left-[-160px] xl:left-[-180px] "
                                                            style={{ display: "none" }}
                                                        >
                                                            &lt; Slide to cancel
                                                        </span>
                                                        <div className="hover:animate-fadePulse" onClick={startRecording}>
                                                            {isRecording ? (<div className="recordloader"></div>) : (<i className="fa-solid fa-microphone  text-2xl text-white"></i>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex h-14 items-center space-x-2 justify-center">

                                            <div className="ka-wrapper">
                                                <div className="ka-content">
                                                    <div className="relative">
                                                        <button className="rounded flex px-4 py-1 rounded-full justify-center bg-[#c588bab0] items-center border text-lg border-none p-0">
                                                            {/* <img src="/icon/selfie-button.svg" className="h-12" /> */}
                                                            <i class="fa-solid fa-gift mr-2 "></i>Send a Gift
                                                        </button>
                                                        {/* <h2
                                                            id="radix-:r6s:"
                                                            className="text-lg font-semibold leading-none tracking-tight"
                                                        >Send a Gift</h2> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div _nk="UVSV21" style={{ display: "none" }}>
                                <div _nk="bNyU21" />
                            </div>
                        </div>
                    </div>
                    <div
                        className={`absolute right-0  top-0 z-subtitle py-4 pr-2 lg:pr-4 ${isVisible ? 'hidden' : '3xl:flex'
                            }`}>
                        <button onClick={toggleVisibility} className="flex justify-center items-center border text-sm h-11 w-11 rounded-2xl border-none bg-[#0000009c]">
                            <span className="iconfont-custom icon-pickuprightIcon text-5xl font-light text-white/70" />
                        </button>
                    </div>
                    <div
                        className={`max-w-[500px] shrink transform transition-all duration-200 w-full basis-2/5 ${isVisible ? '3xl:flex' : 'hidden'
                            }`}>
                        <div className="relative  flex h-full w-full flex-col">
                            <img
                                alt="background"
                                loading="lazy"
                                width={7}
                                height={7}
                                decoding="async"
                                data-nimg={1}
                                className="-z-5 absolute h-fit w-full -translate-y-16 object-cover object-center"
                                src={userimg ? userimg : '/static/media/img1.9322f9a528d87450122a.png'}
                                style={{ color: "transparent" }}
                            />
                            <div className="sticky left-0 top-0 w-full px-5 py-3">
                                <button onClick={toggleVisibility} className="flex justify-center items-center border text-sm h-11 w-11 rounded-2xl border-none bg-[#0000009c]">
                                    <span className="iconfont-custom icon-pickupLiftIcon text-5xl font-light text-white/70" />
                                </button>
                            </div>
                            <div className="h-full w-full overflow-auto pt-48">
                                <div className="relative flex min-h-full w-full flex-col overflow-hidden rounded-t-3xl bg-[#272e34]">
                                    <div className="w-full bg-[#bfb4b480] px-4 pb-2 pt-4  border-0 border-b-2 border-b-white">
                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center">
                                                <div className=" h-24 w-24  rounded-full  ">
                                                    <div
                                                        className="relative shrink-0 grow-0 rounded-full h-24 w-24"
                                                        style={{ padding: 0 }}
                                                    >
                                                        <img
                                                            alt="avatar"
                                                            loading="lazy"
                                                            width={24}
                                                            height={24}
                                                            decoding="async"
                                                            data-nimg={1}
                                                            className="relative h-full w-full rounded-full"
                                                            src="/static/media/img1.9322f9a528d87450122a.png"
                                                            style={{ color: "transparent" }}
                                                        />
                                                        <img
                                                            alt="avatarPlaceholder"
                                                            width={24}
                                                            height={24}
                                                            decoding="async"
                                                            data-nimg={1}
                                                            className="absolute inset-0 z-overlay h-full w-full rounded-full"
                                                            src={userimg}
                                                            style={{ color: "transparent" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className=" ml-3 text-white">
                                                    <span className="text-3xl font-medium">{activeAssistantName}</span>
                                                    <div className="flex items-center whitespace-nowrap font-light">
                                                        <div className="mb-1 h-5 w-5">
                                                            {/* <img
                                                                src="/icon/messageIcon/favorabilityIcon.svg"
                                                                className=" w-full  cursor-pointer"
                                                            /> */}
                                                        </div>
                                                        <span className="mr-2 whitespace-nowrap">Age: {activeAssistantdetails.age}</span>
                                                        <span className="iconfont-custom icon-message-fill mr-1 mt-1 text-base" />
                                                        <span>589k</span>
                                                    </div>
                                                    <span className="text-xs text-white">
                                                        Created by Eromantic AI
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="m-2 mt-6 font-robotoLight text-sm text-white">
                                            <div className=" inline-block">
                                                <button className="rounded flex justify-center items-center border text-sm border-none">
                                                    <span className="iconfont-custom icon-voiceFree mr-1 rounded-full bg-gray-7 px-[4px] text-sm font-semibold text-white" />
                                                </button>
                                                <audio src="https://cdn.iher.ai/196009864237981440/830ec8653b0341a88bd2d921b21bd9ff.mp3" />
                                            </div>
                                            {activeAssistantdetails.Occupation}
                                        </div>
                                        <div className="m-2 flex flex-wrap">
                                            <span className="mb-2 mr-4 rounded-full bg-gray-100/20 px-2 py-1 text-sm font-light text-white">
                                                {activeAssistantdetails.Relationship}
                                            </span>
                                            <span className="mb-2 mr-4 rounded-full  bg-gray-100/20 px-2 py-1   text-sm font-light text-white">
                                                {activeAssistantdetails.Personality}
                                            </span>
                                            <span className="mb-2 mr-4 rounded-full  bg-gray-100/20 px-2 py-1   text-sm font-light text-white">
                                                {activeAssistantdetails.Ethnicity}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="grow bg-[#bfb4b480] px-4 pb-8 pt-4 h-[22rem] overflow-scroll" style={{ scrollbarWidth: 'none' }}>
                                        <div>
                                            <div className="flex gap-14" role="tablist" aria-orientation="horizontal">
                                                {/* Photo Tab Button */}
                                                <button
                                                    className={`mb-3 border-b-[3px] py-1 text-lg font-semibold text-white focus:outline-none ${activeTab === 'photo' ? 'border-purple-8' : 'border-black-7'}`}
                                                    onClick={() => handleTabClick('photo')}
                                                    aria-selected={activeTab === 'photo'}
                                                    role="tab"
                                                    type="button"
                                                >
                                                    Photo
                                                </button>

                                                {/* About Tab Button */}
                                                <button
                                                    className={`mb-3 border-b-[3px] py-1 text-lg font-semibold text-white focus:outline-none ${activeTab === 'about' ? 'border-purple-8' : 'border-black-7'}`}
                                                    onClick={() => handleTabClick('about')}
                                                    aria-selected={activeTab === 'about'}
                                                    role="tab"
                                                    type="button"
                                                >
                                                    About
                                                </button>
                                            </div>


                                            <div>
                                                {/* Show Photo content when activeTab is 'photo' */}
                                                {activeTab === 'photo' && (
                                                    <div>
                                                        <div
                                                            id="headlessui-tabs-panel-:r6e:"
                                                            role="tabpanel"
                                                            tabIndex={0}
                                                            data-headlessui-state="selected"
                                                            data-selected=""
                                                            aria-labelledby="headlessui-tabs-tab-:r6a:"
                                                        >
                                                            <div className="min-h-32 grid h-auto w-full grid-cols-3">
                                                                {messagesHistory.map((message, index) => (
                                                                    message.img_url && (
                                                                        <div key={index} className="relative h-0 w-full pb-[100%]">
                                                                            <button
                                                                                onClick={() => handleImageClick(message.img_url)}
                                                                                className="rounded flex justify-center items-center border text-sm absolute left-0 top-0 h-full w-full border-none p-1"
                                                                            >
                                                                                <div className="relative flex items-center justify-center overflow-hidden rounded-md h-full w-full bg-white/10">
                                                                                    <div className="relative h-full w-full">
                                                                                        <img
                                                                                            className="absolute inset-0 z-overlay aspect-[2/3] h-full w-full object-cover object-center"
                                                                                            src={message.img_url}
                                                                                            alt="Dynamic content"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </button>
                                                                        </div>
                                                                    )
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <span
                                                            aria-hidden="true"
                                                            id="headlessui-tabs-panel-:r6g:"
                                                            role="tabpanel"
                                                            tabIndex={-1}
                                                            aria-labelledby="headlessui-tabs-tab-:r6c:"
                                                            style={{
                                                                position: "fixed",
                                                                top: 1,
                                                                left: 1,
                                                                width: 1,
                                                                height: 0,
                                                                padding: 0,
                                                                margin: "-1px",
                                                                overflow: "hidden",
                                                                clip: "rect(0px, 0px, 0px, 0px)",
                                                                whiteSpace: "nowrap",
                                                                borderWidth: 0
                                                            }}
                                                        />
                                                    </div>
                                                )}

                                                {/* Show About content when activeTab is 'about' */}
                                               
                                                {activeTab === 'about' && (
                                                    <div id="about-content" className="text-black space-y-6">
                                                        {/* Ethnicity Section */}
                                                        <div className="bg-gradient-to-r from-white via-gray-100 to-gray-200 p-4 rounded-xl shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out" style={{ marginTop: "2px" }}>
                                                            <div className="flex items-center w-full">
                                                                <img className="w-10 h-10 rounded-full border-2 border-gray-300 shadow-md" src={Location} alt="Location icon" />
                                                                <div className="flex w-[80%] justify-between">
                                                                    <h3 className="text-gray-800 font-semibold text-base">Ethnicity:</h3>
                                                                    <p className="text-gray-700 text-sm">{activeAssistantdetails.Ethnicity}</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Age Section */}
                                                        <div className="bg-gradient-to-r from-white via-gray-100 to-gray-200 p-4 rounded-xl shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out" style={{ marginTop: "2px" }}>
                                                            <div className="flex items-center w-full">
                                                                <img className="w-10 h-10 rounded-full border-2 border-gray-300 shadow-md" src={age} alt="Age icon" />
                                                                <div className="flex w-[80%] justify-between">
                                                                    <h3 className="text-gray-800 font-semibold text-base">Age:</h3>
                                                                    <p className="text-gray-700 text-sm">{activeAssistantdetails.age}</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Eyes Section */}
                                                        <div className="bg-gradient-to-r from-white via-gray-100 to-gray-200 p-4 rounded-xl shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out" style={{ marginTop: "2px" }}>
                                                            <div className="flex items-center w-full">
                                                                <img className="w-10 h-10 rounded-full border-2 border-gray-300 shadow-md" src={eyes} alt="Eyes icon" />
                                                                <div className="flex w-[80%] justify-between">
                                                                    <h3 className="text-gray-800 font-semibold text-base">Eyes:</h3>
                                                                    <p className="text-gray-700 text-sm">{activeAssistantdetails.Eyes}</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Hair Section */}
                                                        <div className="bg-gradient-to-r from-white via-gray-100 to-gray-200 p-4 rounded-xl shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out" style={{ marginTop: "2px" }}>
                                                            <div className="flex items-center w-full">
                                                                <img className="w-10 h-10 rounded-full border-2 border-gray-300 shadow-md" src={hair} alt="Hair icon" />
                                                                <div className="flex w-[80%] justify-between">
                                                                    <h3 className="text-gray-800 font-semibold text-base">Hair:</h3>
                                                                    <p className="text-gray-700 text-sm">{activeAssistantdetails.hair_color} - {activeAssistantdetails.hair_Style}</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Body Type Section */}
                                                        <div className="bg-gradient-to-r from-white via-gray-100 to-gray-200 p-4 rounded-xl shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out" style={{ marginTop: "2px" }}>
                                                            <div className="flex items-center w-full">
                                                                <img className="w-10 h-10 rounded-full border-2 border-gray-300 shadow-md" src={Bodyicon} alt="Body type icon" />
                                                                <div className="flex w-[80%] justify-between">
                                                                    <h3 className="text-gray-800 font-semibold text-base">Body Type:</h3>
                                                                    <p className="text-gray-700 text-sm">{activeAssistantdetails.Body_Type}</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Personality Section */}
                                                        <div className="bg-gradient-to-r from-white via-gray-100 to-gray-200 p-4 rounded-xl shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out" style={{ marginTop: "2px" }}>
                                                            <div className="flex items-center w-full">
                                                                <img className="w-10 h-10 rounded-full border-2 border-gray-300 shadow-md" src={personality} alt="Personality icon" />
                                                                <div className="flex w-[80%] justify-between">
                                                                    <h3 className="text-gray-800 font-semibold text-base">Personality:</h3>
                                                                    <p className="text-gray-700 text-sm">{activeAssistantdetails.Personality}</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Voice Section */}
                                                        <div className="bg-gradient-to-r from-white via-gray-100 to-gray-200 p-4 rounded-xl shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out" style={{ marginTop: "2px" }}>
                                                            <div className="flex items-center w-full">
                                                                <img className="w-10 h-10 rounded-full border-2 border-gray-300 shadow-md" src={eyes} alt="Voice icon" />
                                                                <div className="flex w-[80%] justify-between">
                                                                    <h3 className="text-gray-800 font-semibold text-base">Voice:</h3>
                                                                    <p className="text-gray-700 text-sm">{activeAssistantdetails.Voice}</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Occupation Section */}
                                                        <div className="bg-gradient-to-r from-white via-gray-100 to-gray-200 p-4 rounded-xl shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out" style={{ marginTop: "2px" }}>
                                                            <div className="flex items-center w-full">
                                                                <img className="w-10 h-10 rounded-full border-2 border-gray-300 shadow-md" src={occupation} alt="Occupation icon" />
                                                                <div className="flex w-[80%] justify-between">
                                                                    <h3 className="text-gray-800 font-semibold text-base">Occupation:</h3>
                                                                    <p className="text-gray-700 text-sm">{activeAssistantdetails.Occupation}</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Relationship Section */}
                                                        <div className="bg-gradient-to-r from-white via-gray-100 to-gray-200 p-4 rounded-xl shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out" style={{ marginTop: "2px" }}>
                                                            <div className="flex items-center w-full">
                                                                <img className="w-10 h-10 rounded-full border-2 border-gray-300 shadow-md" src={Relationshipicon} alt="Relationship icon" />
                                                                <div className="flex w-[80%] justify-between">
                                                                    <h3 className="text-gray-800 font-semibold text-base">Relationship:</h3>
                                                                    <p className="text-gray-700 text-sm">{activeAssistantdetails.Relationship}</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Hobbies Section */}
                                                        <div className="bg-gradient-to-r from-white via-gray-100 to-gray-200 p-4 rounded-xl shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out" style={{ marginTop: "2px" }}>
                                                            <div className="flex items-center w-full">
                                                                <p className="text-gray-800 font-semibold text-base">Hobbies:</p>
                                                                <div className="flex w-[80%] justify-between">
                                                                    <p className="text-gray-700 text-sm">
                                                                        {activeAssistantdetails.Hobbies ? Object.values(activeAssistantdetails.Hobbies).join(", ") : 'N/A'}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                )}

                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2
                                id="radix-:r6j:"
                                className="text-lg font-semibold leading-none tracking-tight"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal (fullscreen image view) */}
            {isModalOpen && selectedImage && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
                    <div className="relative max-w-full max-h-full p-4">
                        <button
                            onClick={closeModal}
                            className="absolute top-4 right-4 text-white text-xl font-bold"
                        >
                            X
                        </button>
                        <img
                            className="max-w-full max-h-screen object-contain"
                            src={selectedImage}
                            alt="Fullscreen View"
                        />
                    </div>
                </div>
            )}
        </div>

    );
};

export default Aidreamchat;