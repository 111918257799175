import { useState, useEffect } from "react";
import { RiCompassDiscoverLine, RiAiGenerate } from "react-icons/ri";
import { TbGenderDemigirl, TbPremiumRights, TbArrowGuide, TbGenderDemiboy } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";

export const Sidebar = ({ isOpen,setActiveTab }) => {
    const [active, setActive] = useState(1);
    const [Userlogger, setUserlogger] = useState(null);
    const [usercheck, setUsercheck] = useState(null);
    const goto = (index) => setActive(index);
    const navigate = useNavigate();

    useEffect(() => {
        setUsercheck(localStorage.getItem('hasVisited'));
        setUserlogger(localStorage.getItem('login'));
    }, []); // Add dependency array to avoid infinite loop

    const switchToBoyFriend = () => {
        setActiveTab('Boyfriend')
        navigate("/");
    };
    const switchToGirlFriend = () => {
        setActiveTab('Girlfriend')
        navigate("/");
    };

    const navItems = [
        { label: "AI GIRLFRIEND", icon: <i className="fa-solid fa-venus"></i>,  onClick: Userlogger ? switchToGirlFriend : "/login"},
        { label: "AI BOYFRIEND",  icon: <i className="fa-solid fa-mars"></i>, onClick: Userlogger ? switchToBoyFriend : "/login"},
        { label: "Generate", icon: <i className="fa-solid fa-heart-circle-plus"></i>, link: Userlogger ? "/dashboard" : "/login" },
        { label: "CHAT", icon: <i className="fa-solid fa-comments"></i>, link: Userlogger ? "/AidreamGFchat" : "/login" },
        { label: "DISCOVER", icon: <i className="fa-solid fa-compass"></i>, link: Userlogger ? "/dashboard/discover" : "/login" },
        { label: "Your Gallery", icon: <i className="fa-solid fa-file-circle-plus"></i>, link: Userlogger ? "/dashboard/generations" : "/login" },
        { label: "Pricing", icon: <i className="fa-solid fa-square-poll-vertical"></i>, link: Userlogger ? "/priceplan" : "/login" },
        { label: "Affiliates", icon: <i className="fa-solid fa-repeat"></i>, link: Userlogger ? "/dashboard/Affiliate" : "/login" },
        { label: "Referral", icon: <i className="fa-solid fa-user-plus"></i>, link: Userlogger ? "/dashboard/referrals" : "/login" },
        { label: "Support", icon: <i className="fa-solid fa-headset"></i>, link: Userlogger ? "/dashboard/support" : "/login" },
    ];

    return (
        <>
            {usercheck ? (
                <>
                <div className="">
                <aside style={{background: 'linear-gradient(135deg, rgb(255 255 255 / 81%), rgb(161 145 145) 40%, rgb(109 109 109 / 80%) 70%) center center / cover'}} className={`sidebar ${isOpen ? 'sidewidth' : ''}`}>
                    <div className="inner">
                        <nav className="menu mt-3">
                            {navItems.map((item, index) => (
                                <div key={item.label} style={{ textDecoration: 'none' }}>
                                    <button
                                        className={active === index ? "active" : ""}
                                        onClick={() => {
                                            goto(index);
                                            if (item.onClick) {
                                                item.onClick();
                                            } else {
                                                navigate(item.link);
                                            }
                                        }}
                                    >
                                        <span style={{ fontSize: '25px', color: '#000' }}>{item.icon}</span>
                                        <p style={{ fontSize: '20px', color: '#000' }}>{item.label}</p>
                                    </button>
                                </div>
                            ))}
                        </nav>
                    </div>
                </aside>
                </div>
                <div className="">
                <aside className={`sidebarmob ${isOpen ? 'sidewidthmob' : ''}`}>
                    <div className="inner">
                        <nav className="menu mt-3">
                            {navItems.map((item, index) => (
                                <div key={item.label} style={{ textDecoration: 'none' }}>
                                    <button
                                        className={active === index ? "active" : ""}
                                        onClick={() => {
                                            goto(index);
                                            if (item.onClick) {
                                                item.onClick();
                                            } else {
                                                navigate(item.link);
                                            }
                                        }}
                                    >
                                        <span style={{ fontSize: '25px', color: '#000' }}>{item.icon}</span>
                                        <p style={{ fontSize: '20px', color: '#000' }}>{item.label}</p>
                                    </button>
                                </div>
                            ))}
                        </nav>
                    </div>
                </aside>
                </div>
                </>
            ) : (
                ""
            )}
        </>
    );
};

export default Sidebar;
