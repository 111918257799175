const loaders = (loading, loaderType) => {
  var outerLoader = document.getElementById('outerLoaders');
  if(loaderType == "heart"){
    outerLoader = document.getElementById('outerCustomLoader');
  }
  if (loading) {
    outerLoader.classList.remove('hiddenloader');
  } else {
    outerLoader.classList.add('hiddenloader');
  }
};

export default loaders;
