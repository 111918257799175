import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Blog, Dashbord, Dote, help, Logo, setting, staff } from '../../assets';

const menuItems = [
    {
        icons: <img src={Dashbord} alt="dashboard" />, label: 'Dashboard', path: '/admin/dashboard'
    },
    {
        icons: <img src={staff} alt="staff" />, label: 'Our Staff', path: '/admin/ourStaff'
    },
    {
        icons: <img src={Blog} alt="blog" />, label: 'Blogs', submenu: [
            { icons: <img src={Dote} alt="dot" />, menu: 'Blogs List', subpath: '/admin/blogList' },
            { icons: <img src={Dote} alt="dot" />, menu: 'Category', subpath: '/admin/category' },
        ]
    },
    {
        icons: <img src={help} alt="help" />, label: 'Help Desk', submenu: [
            { icons: <img src={Dote} alt="dot" />, menu: 'Help Blog', subpath: '/admin/helpBlog' },
            { icons: <img src={Dote} alt="dot" />, menu: 'Main Category', subpath: '/admin/mainCategory' },
            { icons: <img src={Dote} alt="dot" />, menu: 'Sub Category', subpath: '/admin/subCategory' },
        ]
    },
    {
        icons: <img src={setting} alt="Support" />, label: 'Support', path: '/admin/support'
    },
    {
        icons: <img src={setting} alt="setting" />, label: 'FAQs', path: '/admin/faqs'
    },
];

export default function Sidebar() {
    const [openMenu, setOpenMenu] = useState(null);
    const [sidebarOpen, setSidebarOpen] = useState(true); // Sidebar visibility

    const toggleMenu = (index) => {
        setOpenMenu(openMenu === index ? null : index);
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const handleResize = () => {
        if (window.innerWidth >= 768) {
            setSidebarOpen(true); // Auto-open sidebar on larger screens
        } else {
            setSidebarOpen(false); // Auto-close sidebar on smaller screens
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div>
            <button 
                onClick={toggleSidebar} 
                className="fixed top-4 left-4 z-10 p-2 bg-blue-500 text-white rounded-md md:hidden"
            >
                {sidebarOpen ? <i class="fa-solid fa-xmark"></i> : <i class="fa-solid fa-bars"></i>} 
            </button>
            <nav
                className={`fixed top-0 h-full z-50 border border-r-gray-300 flex flex-col text-black bg-white duration-300 md:w-48 lg:w-80 ${sidebarOpen ? 'block' : 'hidden'}`}
            >
                <ul className='flex-1 md:px-3 lg:px-10'>
                    <img src={Logo} alt="logo" className='max-h-12 my-10' />
                    {menuItems.map((item, index) => (
                        <li key={index}>
                            {item.submenu ? (
                                <button
                                    onClick={() => toggleMenu(index)}
                                    type="button"
                                    className={`flex items-center w-full px-3 py-2 my-2 lg:text-xl font-medium rounded-md duration-300 group hover:bg-gray-300`}
                                >
                                    {item.icons}
                                    <span className="flex-1 ms-3 text-left">{item.label}</span>
                                    <svg
                                        className={`w-3 h-3 transition-transform duration-300 ${openMenu === index ? 'rotate-180' : ''}`}
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 10 6"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="m1 1 4 4 4-4"
                                        />
                                    </svg>
                                </button>
                            ) : (
                                <NavLink
                                    to={item.path}
                                    className={({ isActive }) => `flex items-center w-full px-3 py-2 my-2 lg:text-xl font-medium rounded-md duration-300 group hover:bg-gray-300 ${isActive ? 'bg-gray-300' : ''}`}
                                >
                                    {item.icons}
                                    <span className="flex-1 ms-3 text-left">{item.label}</span>
                                </NavLink>
                            )}
                            {openMenu === index && item.submenu && (
                                <ul className="py-2 space-y-2 ps-5">
                                    {item.submenu.map((subItem, subIndex) => (
                                        <li key={subIndex} className='list-disc text-gray-600 flex gap-3 items-center'>
                                            <NavLink
                                                to={subItem.subpath}
                                                className={({ isActive }) => `flex items-center w-full px-5 py-2 rounded-md gap-3 text-gray-500 lg:text-xl font-normal ${isActive ? 'text-gray-950 bg-gray-300' : ''}`}
                                            >
                                                {subItem.icons}
                                                {subItem.menu}
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
}
