import React, { useState } from 'react'
import { infoBlack, selectImgIcon } from '../../assets';

const Recreateimage = () => {

    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (event) => {
        const reader = new FileReader();
        reader.onload = () => {
            setSelectedImage(reader.result);
        };
        reader.readAsDataURL(event.target.files[0]);
    };

    return (
        <>
            <div className='bg-white xl:flex justify-between gap-3 text-black rounded-xl mt-10 lg:mt-14'>
                <div className='ps-5 w-[100%] xl:w-[35%] xl:border-black flex justify-center items-center flex-col gap-10 pt-5 md:pb-10'>
                    <div className=''>
                        <div className='flex justify-center gap-3 pb-2'>
                            <p className='font-semibold text-lg md:text-xl 2xl:text-2xl md:pb-5'>Recreate image(optional)</p>
                            <img src={infoBlack} alt="infoBlack" className='h-6' />
                        </div>
                        <div className='flex items-center justify-center  rounded-xl md:w-[300px] xl:h-[600px] bg-zinc-300'>
                            <div>
                                <label
                                    htmlFor="dropzone-file"
                                    className="flex flex-col items-center justify-center w-full md: rounded-lg cursor-pointer px-2 py-3"
                                >
                                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                        {selectedImage ? (
                                            <img
                                                src={selectedImage}
                                                alt="Selected"
                                                className="w-full object-cover mb-4"
                                            />
                                        ) : (
                                            <img src={selectImgIcon} alt="imgSelector" className='h-14 md:h-full' />
                                        )}
                                        <input
                                            id="dropzone-file"
                                            type="file"
                                            className="hidden"
                                            onChange={handleImageChange}
                                        />
                                        <p className="mt-6 text-2xl">
                                            {selectedImage ? 'Change Featured Image' : 'Add new image'}
                                        </p>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-[100%] xL:w-[65%] flex flex-col items-center pb-10'>
                    <div className='flex justify-center gap-3 pt-5'>
                        <p className='font-semibold text-lg md:text-xl 2xl:text-2xl md:pb-5'>Select girl (optional)</p>
                        <img src={infoBlack} alt="infoBlack" className='h-6' />
                    </div>
                    <div className='flex flex-wrap justify-center items-center w-full gap-3 md:gap-5 2xl:gap-10 pt-3 md:pt-5 2xl:pt-10'>
                        <div className='w-[110px] md:w-[180px] lg:w-[230px] xl:w-[270px] lg:h-[230px] h-[110px] md:h-[180px] xl:h-[270px] rounded-xl bg-zinc-300'>

                        </div>
                        <div className='w-[110px] md:w-[180px] lg:w-[230px] xl:w-[270px] lg:h-[230px] h-[110px] md:h-[180px] xl:h-[270px] rounded-xl bg-zinc-300'>

                        </div>
                        <div className='w-[110px] md:w-[180px] lg:w-[230px] xl:w-[270px] lg:h-[230px] h-[110px] md:h-[180px] xl:h-[270px] rounded-xl bg-zinc-300'>

                        </div>
                    </div>
                    <div className='flex flex-wrap justify-center items-center w-full gap-3 md:gap-5 2xl:gap-10 pt-3 md:pt-5 2xl:pt-10'>
                        <div className='w-[110px] md:w-[180px] lg:w-[230px] xl:w-[270px] lg:h-[230px] h-[110px] md:h-[180px] xl:h-[270px] rounded-xl bg-zinc-300'>

                        </div>
                        <div className='w-[110px] md:w-[180px] lg:w-[230px] xl:w-[270px] lg:h-[230px] h-[110px] md:h-[180px] xl:h-[270px] rounded-xl bg-zinc-300'>

                        </div>
                        <div className='w-[110px] md:w-[180px] lg:w-[230px] xl:w-[270px] lg:h-[230px] h-[110px] md:h-[180px] xl:h-[270px] rounded-xl bg-zinc-300'>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Recreateimage