import React, { useState, useEffect } from 'react';
import { CgMenuRightAlt } from 'react-icons/cg';
import { IoClose } from 'react-icons/io5';
import { Link, useLocation } from 'react-router-dom';
import { Logo } from '../../assets';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();
    const [hasVisited, setHasVisited] = useState(true);

    useEffect(() => {
        const visited = localStorage.getItem('hasVisited');
        setHasVisited(visited === 'true');
    }, []);

    const navData = [
        // { name: 'Pricing Plans', link: '/priceplan' },
        { name: 'Blogs', link: 'https://eromantic.ai/blogs' },
        { name: 'Log In', link: '/login' },
        { name: 'Join Now', link: '/join' }
    ];

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <>
            {/* <div className='flex justify-between px-1 md:px-10 xl:px-28 py-4 items-center'>
                <Link to={'/'}>
                    <img src={Logo} alt="logo" className='w-56 md:w-72' />
                </Link>
                <div className='hidden lg:flex'>
                    <nav>
                        <ul className='flex gap-10 font-semibold'>
                            {navData.map((item, index) => (
                                <li key={index} className={`hover:cursor-pointer text-lg font-medium hover:text-gray-500 ${location.pathname === item.link ? 'text-red-500' : ''}`}>
                                    <Link to={hasVisited ? item.link : '#'} onClick={hasVisited ? undefined : (e) => e.preventDefault()}>
                                        {item.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
                <div className='lg:hidden hover:cursor-pointer'>
                    {isMenuOpen ? <IoClose size={28} onClick={handleMenuToggle} /> : <CgMenuRightAlt size={28} onClick={handleMenuToggle} />}
                </div>
            </div> */}
            {isMenuOpen && (
                <div className='lg:hidden pb-5'>
                    <nav>
                        <ul className='flex flex-col ms-16 gap-4 font-semibold'>
                            {navData.map((item, index) => (
                                <li key={index} className={`hover:cursor-pointer hover:text-gray-500 ${location.pathname === item.link ? 'text-red-500' : ''}`}>
                                    <Link to={hasVisited ? item.link : '#'} onClick={hasVisited ? handleMenuToggle : (e) => e.preventDefault()}>
                                        {item.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
            )}
        </>
    );
};

export default Header;
