// import React, { useEffect, useState } from 'react'
// import { Google, Rectangle47, Rectangle48 } from '../../assets'
// import { Link, NavLink } from 'react-router-dom';
//
// const Login = () => {
//
//     const [isLoggedIn, setIsLoggedIn] = useState(false);
//
//     useEffect(() => {
//         const data = localStorage.getItem("login");
//         if (data === 'loginKey') {
//             setIsLoggedIn(true);
//         }
//     }, []);
//
//     const handleLogin = () => {
//         localStorage.setItem("login", "loginKey");
//         setIsLoggedIn(true);
//     }
//
//     const LoginPage = () => {
//         localStorage.setItem("login", "");
//         setIsLoggedIn(false);
//     }
//
//     return (
//         <div>
//             <div>
//                 {!isLoggedIn ? (
//                     <div className='bg-black w-full flex justify-between font-josefin-sans h-[700px] md:h-[965px] lg:h-[100vh]'>
//                         <div className='hidden md:block md:w-[45%]'><img src={Rectangle47} alt='Rectangle47' className=' w-full object-cover  md:h-[965px] lg:h-[100vh]' /></div>
//                         <div className='w-full md:w-[55%] flex justify-center items-center'>
//                             <div className="text-black flex flex-col items-center">
//                                 <h4 className="font-sans text-[20px] md:text-[22px] lg:text-[26px] xl:text-[33px] font-semibold leading-snug tracking-normal text-blue-gray-900">
//                                     LOGIN TO EROMANTIC<span className='text-red-700'>.AI</span>
//                                 </h4>
//
//                                 <form className="mt-5 lg:mt-8 mb-2 w-[278px] md:w-[330px] lg:w-[400px] xl:w-[500px]">
//                                     <div className="flex flex-col gap-6 mb-1">
//                                         <div className="relative h-11 md:h-12 lg:h-14 w-full min-w-[278px] md:min-w-[300px]">
//                                             <input placeholder="Enter your email address" type="email"
//                                                 className="peer h-full w-full rounded-md border-2 border-blue-gray-200 bg-transparent px-5 py-3 font-sans text-lg font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 focus:border-2 focus:border-gray-500 focus:outline-0" />
//                                         </div>
//                                         <div className="relative h-11 md:h-12 lg:h-14 w-full min-w-[278px] md:min-w-[300px]">
//                                             <input type="password" placeholder="Enter your password"
//                                                 className="peer h-full w-full rounded-md border-2 border-blue-gray-200 bg-transparent px-5 py-3 font-sans text-lg font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 focus:border-2 focus:border-gray-500 focus:outline-0" />
//                                         </div>
//                                     </div>
//                                     <p className="text-base text-black border-b-2 border-gray-500 w-56 cursor-pointer" onClick={handleLogin} >Forgot your password? Reset it</p>
//                                     <NavLink to='/dashboard'>
//                                         <button
//                                             className="mt-6 block w-full select-none rounded-lg bg-[#FF0013] py-2.5 lg:py-3 px-6 text-center align-middle font-sans text-[20px] lg:text-[23px] xl:text-[25px] font-semibold text-black shadow-md transition-all hover:shadow-lg focus:opacity-85 active:opacity-85"
//                                             type="button" >
//                                             Log In
//                                         </button>
//                                     </NavLink>
//                                     <div className='flex items-center justify-center text-center my-4 gap-3'>
//                                         <hr className='border-1 w-60 ' />
//                                         <p className=" text-[20px] lg:text-[22px] font-light text-black">OR</p>
//                                         <hr className='border-1 w-60' />
//                                     </div>
//                                     <NavLink to='/dashboard'>
//                                         <button
//                                             className="w-full select-none rounded-lg bg-transparent py-2.5 lg:py-3 text-center align-middle font-sans text-[18px] lg:text-[20px] xl:text-[22px] font-normal text-black shadow-md transition-all hover:shadow-lg focus:opacity-85 active:opacity-85 flex items-center justify-center border-2 border-[#6b6b6b] gap-4"
//                                             type="button">
//                                             <img src={Google} alt='Google' />
//                                             Continue with Google
//                                         </button>
//                                     </NavLink>
//                                     <div>
//                                         <div className='text-[17px] lg:text-[19px] text-center mt-5'>Don't have an account yet? <Link to={'/join'}><span className='text-[#FF0013] cursor-pointer'>Sign Up</span></Link></div>
//                                     </div>
//                                 </form>
//                             </div>
//                         </div>
//                     </div>
//                 ) : (
//                     <div className='bg-black w-full flex justify-between font-josefin-sans h-[700px] md:h-[965px] lg:h-[100vh]'>
//                         <div className='hidden md:block md:w-[45%]'><img src={Rectangle48} alt='Rectangle47' className=' w-full object-cover md:h-[965px] lg:h-[100vh]' /></div>
//                         <div className='w-full md:w-[55%] flex justify-center items-center'>
//                             <div className="text-black flex flex-col items-center">
//                                 <h4 className="font-sans text-[30px] md:text-[26px] xl:text-[33px] font-semibold leading-snug tracking-normal text-blue-gray-900">
//                                     Reset password
//                                 </h4>
//                                 <p className='text-[18px] lg:text-[20px] text-center w-[300px] lg:w-[467px] mt-5'>Enter your e-mail and we will send you the instructions to reset password</p>
//                                 <form className="mt-5 lg:mt-8 mb-2 w-[288px] md:w-[330px] lg:w-[400px] xl:w-[500px]">
//                                     <div className="flex flex-col gap-6 mb-1">
//                                         <div className="relative h-11 md:h-12 lg:h-14 w-full min-w-[288px] md:min-w-[300px]">
//                                             <input placeholder="Enter your email address" type="email"
//                                                 className="peer h-full w-full rounded-md border-2 border-blue-gray-200 bg-transparent px-5 py-3 font-sans text-lg font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 focus:border-2 focus:border-gray-500 focus:outline-0" />
//                                         </div>
//                                     </div>
//
//                                     <button
//                                         className="mt-6 block w-full select-none rounded-lg bg-[#FF0013] py-2.5 lg:py-3 px-6 text-center align-middle font-sans text-[20px] lg:text-[23px] xl:text-[25px] font-semibold text-black shadow-md transition-all hover:shadow-lg focus:opacity-85 active:opacity-85"
//                                         type="button" onClick={LoginPage}>
//                                         Log In
//                                     </button>
//
//                                     <div>
//                                         <div className='text-[17px] lg:text-[19px] text-center mt-5'>Already have an account? <span className='text-[#FF0013] cursor-pointer' onClick={LoginPage}>Login </span></div>
//                                         <div className='text-[17px] lg:text-[19px] text-center mt-2 2xl:mt-5'>Don't have an account yet? <Link to={'/join'}><span className='text-[#FF0013] cursor-pointer'>Sign Up</span></Link></div>
//                                     </div>
//                                 </form>
//                             </div>
//                         </div>
//
//                     </div>
//                 )}
//
//             </div>
//         </div>
//     )
// }
//
// export default Login




import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Google, Rectangle47, Rectangle48 } from '../../assets';
import { Link, NavLink, useNavigate } from 'react-router-dom';

const ResetPassword = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const data = localStorage.getItem("login");
        if (data === 'loginKey') {
            setIsLoggedIn(true);
        }
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://api2.eromantic.ai/api/v1/login/', {
                'email':email,
                "password":password,
            });

            // Assuming the API returns a token on successful login
            if (response.status === 200) {
                localStorage.setItem("token", response.data.access); // Store JWT token
                localStorage.setItem("refreshToken", response.data.refresh); // Store refresh token if provided
                localStorage.setItem("login", "loginKey"); // Set login status
                setIsLoggedIn(true);
                navigate('/dashboard'); // Navigate to the dashboard
            }
        } catch (error) {
            console.error(error);
            setError('Invalid email or password. Please try again.');
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("login");
        setIsLoggedIn(false);
    };

    return (
        <div>
            <div>
                    <div className='bg-white w-full flex justify-between font-josefin-sans h-[700px] md:h-[965px] lg:h-[100vh]'>
                        <div className='hidden md:block md:w-[45%]'><img src={Rectangle48} alt='Rectangle47' className=' w-full object-cover md:h-[965px] lg:h-[100vh]' /></div>
                        <div className='relative w-full md:w-[55%] flex justify-center items-center'>
                        <button
                            type="button"
                            className="btn absolute top-3 left-3 text-lg text-[#ff0022] cursor-pointer flex items-center"
                            onClick={() => navigate('/')}
                        >
                            <i className="fa-solid fa-circle-arrow-left mr-2"></i>Back
                        </button>
                            <div className="text-black flex flex-col items-center">
                            <img src="/assets/img/logowh.png" alt="Icon" class="w-[20rem] rounded-xl mb-5" />
                                <h4 className="font-sans text-black text-[30px] md:text-[26px] xl:text-[33px] font-semibold leading-snug tracking-normal text-blue-gray-900">
                                    Reset password
                                </h4>
                                <p className='text-[18px] lg:text-[20px] text-center w-[300px] lg:w-[467px] mt-5'>Enter your e-mail and we will send you the instructions to reset password</p>
                                <form className="mt-5 lg:mt-8 mb-2 w-[288px] md:w-[330px] lg:w-[400px] xl:w-[500px]">
                                    <div className="flex flex-col gap-6 mb-1">
                                        <div className="relative h-11 md:h-12 lg:h-14 w-full min-w-[288px] md:min-w-[300px]">
                                            <input placeholder="Enter your email address" type="email"
                                                className="peer h-full w-full rounded-md border-2 border-blue-gray-200 bg-transparent px-5 py-3 font-sans text-lg font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 focus:border-2 focus:border-gray-500 focus:outline-0" />
                                        </div>
                                    </div>

                                    <button
                                        className="mt-6 block w-full select-none rounded-lg bg-[#FF0013] py-2.5 lg:py-3 px-6 text-center align-middle font-sans text-[20px] lg:text-[23px] xl:text-[25px] font-semibold text-white shadow-md transition-all hover:shadow-lg focus:opacity-85 active:opacity-85"
                                        type="button" onClick={handleLogout}>
                                        Log In
                                    </button>

                                    <div>
                                        <div className='text-[17px] lg:text-[19px] text-center mt-5'>Already have an account? <span className='text-[#FF0013] cursor-pointer' onClick={handleLogout}>Login </span></div>
                                        <div className='text-[17px] lg:text-[19px] text-center mt-2 2xl:mt-5'>Don't have an account yet? <Link to={'/join'}><span className='text-[#FF0013] cursor-pointer'>Sign Up</span></Link></div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>

            </div>
        </div>
    );
}

export default ResetPassword;
