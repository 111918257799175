import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import AIGirlfriend from './AIGirlfriend';
import AIBoyfriend from './AIBoyfriend';

const CreateDreamGF = () => {
    const [activeComponent, setActiveComponent] = useState('');
    const navigate = useNavigate();
    const { location } = window;
    
    useEffect(() => {
        if (location.pathname === '/CreateDreamGF') {
            setActiveComponent('AIGirlfriend');
        } else if (location.pathname === '/BoyFriend') {
            setActiveComponent('AIBoyfriend');
        }
    }, [location.pathname]);

    const handleComponentChange = (component) => {
        setActiveComponent(component);
        navigate(component === 'AIGirlfriend' ? '/CreateDreamGF' : '/BoyFriend');
    };

    return (
        <div className='px-5 xl:px-20 pb-20 pt-10 text-white font-josefin-sans' style={{ minHeight: '852px' }}>
            <p className='text-black text-[35px] py-2'>Generate New</p>
            <div className='md:rounded-tr-xl md:rounded-br-xl md:rounded-tl-xl md:rounded-bl-xl md:flex justify-between lg:text-xl bg-red-600'>
                <button
                    className={`w-full flex md:rounded-tl-xl md:rounded-bl-xl items-center gap-10 md:gap-5 px-3 xl:px-10 py-3 ${activeComponent === 'AIGirlfriend' ? 'bg-red-600 hover:border-red-800' : 'bg-red-800'} md:justify-center ps-12 md:ps-0`}
                    onClick={() => handleComponentChange('AIGirlfriend')}
                >
                    <p>AI Girlfriend</p>
                </button>
                <button
                    className={`w-full flex items-center gap-10 md:gap-5 px-3 xl:px-10 py-3 ${activeComponent === 'AIBoyfriend' ? 'bg-red-600 hover:border-red-800' : 'bg-red-800'} md:justify-center ps-12 md:ps-0`}
                    onClick={() => handleComponentChange('AIBoyfriend')}
                >
                    <p>AI Boyfriend</p>
                </button>
            </div>

            {activeComponent === 'AIGirlfriend' ? <AIGirlfriend /> : <AIBoyfriend />}
        </div>
    );
};

export default CreateDreamGF;
