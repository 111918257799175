import React from 'react'
import { img1, img3, img4, img7 } from '../../../assets'

const Saved = () => {
    const imgdata = [
        { images: img1, }, { images: img4, }, { images: img3, }, { images: img7, }, { images: img1, }, { images: img4, }, { images: img3, }, { images: img7, }, { images: img1, }, { images: img4, }, { images: img3, }, { images: img7, },
    ]

    return (
        <>
            <div className='flex flex-wrap justify-center gap-4'>
                {
                    imgdata.map((data, index) => {
                        return (
                            <div key={index} className='relative w-72 h-80 xl:w-[383px] xl:h-[500px] lg:mt-7'>
                                <img src={data.images} alt="img1" className='w-72 h-80 xl:w-[383px] xl:h-[500px] rounded-xl  object-cover' />
                                <div className='absolute inset-0 flex flex-col justify-between opacity-0 opacity-100 transition-opacity duration-700'>
                                    <div className='flex justify-end p-4'>
                                        <button class="text-white font-medium rounded-2xl px-3 py-2">
                                            <img src="/assets/img/Group-icon.png" alt="Icon" class="w-16 h-16"/>
                                        </button>
                                    </div>
                                    <div className='absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-4 opacity-0 opacity-100 transition-opacity duration-700 '>
                                        <div className='flex justify-between py-4'>
                                        <span className="text-2xl font-medium">Cataline</span>
                                        <span className="text-2xl font-medium">29 Year</span>
                                        </div>
                                        <p>Weather report anchor in Vienna.<br /> Full of herself, she loves the attention she gets ...</p>
                                    </div>
                                </div>
                                {/* <button className='bg-red-800 text-white font-medium rounded-full absolute px-5 py-2 bottom-10  ml-[100px] xl:ml-[140px]'>
                                    Unsave
                                </button> */}
                            </div>
                        )
                    })
                }


            </div>
        </>
    )
}

export default Saved
